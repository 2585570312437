import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductStorageInfoDialogComponent } from './product-storage-info-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { Entity } from '@proman/services/entity.service';
import { CurrUser, TableConfig } from '@proman/interfaces/object-interfaces';
import { OrderProduct, ProductionOperation } from '@proman/interfaces/entity-interfaces';
import { FilterService } from '@proman/services/filter.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { getDecimalPlaces } from '@proman/store/system-options';

@Component({
    selector: 'pm-event-order-products-table',
    template: `
        <pro-table [config]="tableConfig" [timeStamp]="timeStamp"></pro-table>
    `
})

export class EventOrderProductsTableComponent implements OnInit {
    @Input() event: ProductionOperation;
    @Input() timeStamp: number;
    orderProductEntity: any;
    tableConfig: TableConfig;
    currUser: CurrUser;
    decimalProductQuantity: number;

    constructor(
        private Dialog: Dialog,
        private Entity: Entity,
        private store: Store,
        private Filter: FilterService,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);

        this.store.select(getDecimalPlaces, 'product_quantity')
            .subscribe((value) => this.decimalProductQuantity = value);

    }

    showInfo = (orderProduct: any) => this.Dialog.open2(ProductStorageInfoDialogComponent, { product: orderProduct.product });

    updateReadyToShipQuantity = (orderProduct: any, quantity: number) => {
        this.orderProductEntity.update({ id: orderProduct.id, readyForShipmentQuantity: quantity });
    };

    ngOnInit() {
        this.orderProductEntity = this.Entity.get('order_product');
        if (this.event.order) this.setTableConfig();
    };

    setTableConfig = () => {
        this.tableConfig = {
            entity: 'order_product',
            preventFixedHeader: true,
            aclRoot: null,
            hideSettings: true,
            rowButtons: [
                {
                    icon: 'question-circle',
                    tooltip: 'show_all_orders',
                    acl: null,
                    callback: this.showInfo
                }
            ],
            fields: [
                {
                    name: 'name',
                    key: 'product.name',
                    filter: null,
                    state: {
                        name: 'Product',
                        key: 'productId',
                        id: 'product.id',
                        acl: 'product.edit'
                    }
                },
                {
                    name: 'parameters',
                    key: 'product.parameters',
                    formatter: 'parameters',
                    filter: {
                        type: 'search',
                        keys: ['product.parameters.parameter.name', 'product.parameters.parameter.alias', 'product.parameters.value'],
                    },
                },
                {
                    name: 'ordered_quantity',
                    key: 'quantity',
                    filter: null,
                    formatter: 'numeric',
                    stats: ['sum']
                },
                {
                    name: 'stored_quantity',
                    key: 'product.storedQuantity',
                    filter: null,
                    formatter: 'numeric',
                    stats: ['sum']
                },
                {
                    name: 'ready_for_shipment_quantity',
                    key: 'readyForShipmentQuantity',
                    formatter: 'input',
                    callback: this.updateReadyToShipQuantity,
                    filter: null,
                    stats: ['sum']
                }
            ],
            extraParameters: {
                'join': ['product', 'product.parameters', 'product.parameters.parameter'],
                'order.id': this.event.order.id
            },
            header: 'order_products',
            headerState: {
                name: 'OrderProducts',
                key: 'orderId',
                id: this.event.order.id.toString(),
                acl: 'order.display'
            },
            transform: (data: OrderProduct[]) => {

                if (this.currUser.isCustomer) {
                    data = data.filter((item) => !item.parentOrderProduct);
                }

                data.forEach((product) => {
                    product.quantity = this.Filter.decimal(product.quantity, this.decimalProductQuantity);
                    product.readyForShipmentQuantity = this.Filter.decimal(product.readyForShipmentQuantity, this.decimalProductQuantity);
                });

                return data;
            }
        };
    };

}
