import { Component, OnDestroy, ElementRef, ViewChild, OnInit, Input, HostListener } from '@angular/core';
import { ObservablesService } from '@proman/services/observables.service';

const SIDENAV_CLASS = '.mat-sidenav.mat-drawer-side';

@Component({
    selector: 'pm-bottom-toolbar',
    template: `
        <div #elementRef
             [ngClass]="positionStatic ? 'BottomToolbarStatic' : 'BottomToolbar'"
             fxLayout="column">
            <ng-content></ng-content>
        </div>
    `
})
export class BottomToolbarComponent implements OnDestroy, OnInit {
    @ViewChild('elementRef', { static: true }) elementRef: ElementRef;
    @Input() positionStatic: boolean = false;
    wrapperEl: Node;
    element: HTMLElement;

    constructor(
        private Observables: ObservablesService,
    ) {
        this.Observables.layout.subject.subscribe(() => this.setPosition());
    }

    ngOnInit() {
        this.element = this.elementRef.nativeElement;
        this.wrapperEl = this.element.parentNode;

        this.render();
        this.setPosition();
    }

    ngOnDestroy() {
        this.element.remove();
    }

    render() {
        let parentElement = document.querySelector('.Copyrights');

        setTimeout(() => {
            this.wrapperEl.parentElement.style.marginBottom = this.element.offsetHeight + 'px';
        }, 1000);

        parentElement?.parentNode.insertBefore(this.element, parentElement);
    }

    setPosition() {
        let sidenavElement = <HTMLElement>document.querySelector(SIDENAV_CLASS);

        if (sidenavElement) {
            setTimeout(() => {
                if (window.innerWidth > 425) {
                    this.element.style.left = sidenavElement.offsetWidth + 'px';
                } else {
                    this.element.style.left = '0';
                }
            });

        } else {
            setTimeout(() => this.setPosition(), 50);
        }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setPosition();
    }
}
