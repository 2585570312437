import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';

@Component({
    selector: 'pm-parameter-list',
    template: `
        <pm-list [value]="items"
                 [disabled]="disabled"
                 [config]="config"
                 (onAdd)="handleChanges($event)"
                 (onUpdate)="handleChanges($event)"
                 (onRemove)="handleChanges($event)"></pm-list>
    `
})

export class ParameterListComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any;
    @Input() disabled: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    items: any = [];

    ngOnChanges(changes: SimpleChanges) {

        if (changes.value) {
            this.setItems(changes.value.currentValue);

        }

    }

    ngOnInit() {
        this.setItems(this.value);
    }

    setItems = (items: any) => {

        if (items) {

            try {
                this.items = JSON.parse(items).map((item: any) => {
                    return { value: item };
                });

            } catch (err) {

            }

        }

    };

    handleChanges($event: any) {
        this.onChange.emit(JSON.stringify($event.items.map((item: any) => {
            return item.value;
        })));
    }
}
