import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'pm-user-contacts-card',
    template: `
        <div>

            <div class="UserContactsCard">
                <div fxLayout="row"
                     fxLayoutAlign="start start">
                    <pm-user-thumb [thumb]="user.photo"></pm-user-thumb>
                    <div fxLayout="column">
                        <h3>{{ user.name }}</h3>
                        <div class="UserContactsCard-Details">
                            <h4>{{ user.email }}</h4>
                            <p><a href="tel:{{ user.phone }}">{{ user.phone }}</a></p>
                            <div fxLayout="column">
                                <pro-editable-container class="UserContactsCard-Skype"
                                     *ngIf="user.skype"
                                     label="skype">
                                    <div fxLayout="row">
                                        <a [href]="'skype:' + user.skype + '?call' | safeURL"
                                           title="Call"><img src="../../../../assets/images/call.png"></a>
                                        <a [href]="'skype:' + user.skype + '?chat' | safeURL"
                                           title="Chat"><img src="../../../../assets/images/chat.png"></a>
                                    </div>
                                </pro-editable-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserContactsCardComponent {
    @Input() user: any;

    constructor() {

    }

}
