import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-event-parameters',
    template: `
        <div *ngIf="canShow" class="Padding-16">
            <pro-label *ngIf=" config.label && (orderParameters?.length || productionParameters?.length || productionGroups?.length)">
                {{ config.label | translate }}</pro-label>
            <pm-parameter-view *ngFor="let orderParameter of orderParameters"
                               [parameter]="orderParameter"></pm-parameter-view>
            <pm-parameter-view *ngFor="let productionParameter of productionParameters"
                               [parameter]="productionParameter"
                               [config]="{ label: productionParameter.parameter.name }"></pm-parameter-view>

            <div *ngFor="let group of productionGroups"
                 class="EventParametersTable">
                <table class="Table"
                       *ngFor="let group of productionGroups">
                    <thead>
                    <tr>
                        <th>{{ 'layer' | translate }}</th>
                        <th *ngFor="let child of group.metaParameters[0].children">{{ child.parameter.name }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let layer of group.parameters | proSort: 'position'"
                        [ngClass]="{ 'marked': layer.id == event.segmentParameter?.id || layer.id == event.event?.segmentParameter?.id }">
                        <td>{{ layer.name }}</td>
                        <td *ngFor="let child of layer.children">
                            <pm-parameter-view [parameter]="child"
                                               [config]="{ noLabel: true }"></pm-parameter-view>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    `
})

export class EventParametersComponent implements OnChanges {
    @Input() event: any;
    @Input() parameters: any;
    @Input() config: any = {};

    orderParameters: any;
    productionParameters: any;
    productionGroups: any;
    productionMaterialCategories: any;
    canShow: boolean;

    constructor(public ACL: ACL) {
        this.canShow = ACL.check('parameter.display');
    }

    ngOnChanges(changes: SimpleChanges) {
        let parameters = changes.parameters;

        // console.log('changes', changes);
        // console.log('parameters', parameters);

        if (parameters && parameters.currentValue) {

            if (this.parameters.orderParameters) {
                this.orderParameters = this.parameters.orderParameters;

            }

            if (this.parameters.productionParameters) {
                this.productionParameters = this.parameters.productionParameters;

            }

            if (this.parameters.productionGroups) {
                this.productionGroups = [];

                for (let key in this.parameters.productionGroups) {
                    this.productionGroups.push(this.parameters.productionGroups[key]);

                }

            }

            if (this.parameters.productionMaterialCategories) {
                this.productionMaterialCategories = this.parameters.productionMaterialCategories;

            }

        }

    }

}
