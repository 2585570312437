export const FaIcons = [
    'lock',
    'unlock',
    'cog',
    'anchor',
    'link',
    'unlink',
    'arrow-down',
    'arrow-up',
    'arrow-left',
    'arrow-right',
    'check',
    'times',
    'bars',
    'bolt',
    'bug',
    'code',
    'filter',
    'flag',
    'image',
    'paper-plane',
    'power-off',
    'question',
    'redo',
    'undo',
    'smile',
    'play',
    'stop',
    'pause',
    'tag',
    'asterisk',
    'arrows-h',
    'alicorn',
    'adjust',
    'bell',
    'chart-bar',
    'clipboard',
    'clock',
    'ban'
];
