import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';
import { CommonModule } from "@angular/common";
import { DialogTitleComponent } from "@proman/common-components/standalone/dialog-title.component";
import { DialogActionsComponent } from "@proman/common-components/standalone/dialog-actions.component";
import { PromanButtonComponent } from "@proman/button";
import { PromanTextSimpleComponent } from "@proman/text-simple";
import { PromanDateTimeModule } from "@proman/datepicker/proman-date-time.module";
import { PromanFile } from "@proman/interfaces/entity-interfaces";
import { FlexLayoutModule } from "ngx-flexible-layout";

@Component({
    selector: 'pro-files-manager-edit-name-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DialogTitleComponent,
        DialogActionsComponent,
        PromanButtonComponent,
        PromanTextSimpleComponent,
        PromanDateTimeModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
    ],
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="confirm()">
            <pro-dialog-title [title]="'edit'"></pro-dialog-title>
            <div mat-dialog-content fxLayout="column">
                <pro-text-simple [value]="file.name"
                                 [config]="{ label: 'name', required: true }"
                                 (onChange)="file.name = $event"
                                 ></pro-text-simple>
                <pro-text-simple [value]="file.id"
                                 [config]="{ label: 'id' }"
                                 [disabled]="true"></pro-text-simple>
                <pro-text-simple [value]="file.extension"
                                 [config]="{ label: 'type' }"
                                 [disabled]="true"></pro-text-simple>
                <pro-datepicker [value]="file.createdAt"
                                [config]="{ label: 'date' }"
                                [disabled]="true"></pro-datepicker>
            </div>
            <pro-dialog-actions [variant]="'confirm'" [isCallbackDisabled]="!form.valid">
            </pro-dialog-actions>
        </form>
    `
})

export class PromanFilesManagerEditNameDialogComponent {
    parameters: EntityItemFieldConfig[];
    form: UntypedFormGroup = new UntypedFormGroup({});

    file: PromanFile;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: PromanFile,
        public dialogRef: MatLegacyDialogRef<PromanFilesManagerEditNameDialogComponent>
    ) {
        this.file = data;
    }

    confirm() {
        this.dialogRef.close(this.file.name);
    }

}
