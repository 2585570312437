import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pm-article-summary-dialog',
    template: `
        <pro-dialog-title [title]="data.item.name"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-article-summary [article]="data.item"></pm-article-summary>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ArticleSummaryDialogComponent {
    constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any, public dialogRef: MatLegacyDialogRef<ArticleSummaryDialogComponent>) {}
}
