import { Component, Input, OnInit } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { deepCopy, prepareRequest, pushArrays, utcFormat } from '@proman/utils';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { Tag } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-inline-comments-component',
    template: `
        <div class="List" fxLayout="column">
            <div class="List-header"
                 fxLayout="row"
                 fxLayoutAlign="start center">
                <pro-label>{{ label | translate }}</pro-label>
                @if (!isReadOnly && !isAddCommentMode) {
                    <pro-btn (click)="enableAddMode()"
                             icon="plus"
                             theme="accent"></pro-btn>
                }
            </div>
            @if (isAddCommentMode) {
                <div class="InlineForm custom-animation"
                     fxLayout="column">
                    <pm-txt [value]="newComment.text"
                            [config]="{ label: 'operations_comment', type: 'textarea' }"
                            (onChange)="set('text', $event)"></pm-txt>
                    <pro-select [value]="newComment.type"
                                [config]="{ label: 'type', disableSearch: true }"
                                [options]="types"
                                (onChange)="set('type', $event)"></pro-select>
                    <pro-tags [item]="newComment"
                              [config]="{ entity: entityName }"></pro-tags>
                    <div fxLayout="row"
                         fxLayoutAlign="start center">
                        <div class="Btn-actions"
                             fxLayout="row">
                            <pro-btn
                              (click)="addComment()"
                              pattern="add"></pro-btn>
                            <pro-btn
                              (click)="isAddCommentMode = false"
                              pattern="cancel"></pro-btn>
                        </div>
                        <div fxFlex></div>
                        <pro-checkbox [value]="newComment.isImportant"
                                      [config]="{ label: 'is_important' }"
                                      (onChange)="toggleImportant($event)"></pro-checkbox>
                    </div>
                </div>
            }
            
            @if (!comments?.length) {
                <div fxLayout="column">
                    <hr>
                    <pro-no-records></pro-no-records>
                </div>
            }
            @for (comment of comments; track $index) {
                <div class="List-row"
                     fxLayout="row" fxFlex>
                    <div fxLayout="column" class="Width100">
                        <div class="List-row--extra">
                            {{ comment.author?.name }}
                        </div>
                        <div class="List-row--extra small BottomMargin">
                            {{ comment.date | proDateTime }}
                        </div>
                        @if (comment.isEdit) {
                            <pro-tags [item]="comment" [config]="{ entity: entityName}"></pro-tags>
                            <div fxLayout="row" fxFlex>
                                <pm-txt [value]="comment.text"
                                        [config]="{ label: 'edit', debounce: 5000, autofocus: true, type: 'textarea' }"
                                        (onChange)="editCommentValue(comment, $event)"
                                        [disabled]="isReadOnly"
                                ></pm-txt>
                            </div>
                        } @else {
                            <div fxLayout="column" fxLayoutGap="16px" fxFlex>
                                <pro-tags [item]="comment"></pro-tags>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex>
                                    <div [innerHTML]="comment.text"></div>
                                    @if (comment.author?.id == currUser.person?.id && !isReadOnly) {
                                        <pro-btn icon="edit"
                                                 theme="accent"
                                                 size="1x"
                                                 (onClick)="editComment(comment)"
                                        ></pro-btn>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    `
})

export class InlineCommentsComponent implements OnInit {
    @Input() entityName: EntityNameType;
    @Input() entity: any;
    @Input() label: string;
    @Input() comments: any[];
    @Input() isReadOnly: boolean;
    currUser: CurrUser;

    Entity: any;
    newComment: any = {};
    isAddCommentMode: boolean = false;
    types: any;
    additionalTypes: any[];

    constructor(
        private EntityService: Entity,
        private Filter: FilterService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);

        this.EntityService.get('article_operation_production_comment_type').search().then((res) => {
            this.additionalTypes = res;
            this.types = pushArrays(this.additionalTypes, resourcesConfig.article_operation_production_comment.params.types
              .map((item: string) => {
                  return {
                      id: item,
                      name: this.Filter.translate(item)
                  }
              }));

            this.newComment.type = this.types[0];
        });
    }

    ngOnInit() {
        if (this.entityName) {
            this.Entity = this.EntityService.get({ name: this.entityName });

        }

    }

    addComment() {
        const tags = deepCopy(this.newComment.tags).map((tag: Tag) => tag.id);
        delete this.newComment.tags;
        const data: any = prepareRequest(this.newComment);

        data.date = utcFormat(moment());
        data.event = this.entity.id;
        data.tags = tags;

        this.Entity
            .create(data)
            .then((response: any) => {
                this.Entity.get({
                    id: response,
                    join: ['author', 'tags']
                }).then((response: any) => {
                    this.isAddCommentMode = false;

                    this.comments.unshift(response);
                });
        });
    };

    set(property: string, value: any) {
        this.newComment[property] = value;
    };

    enableAddMode = () => {
        this.newComment = { text: '', type: this.types[0], tags: [] };

        this.isAddCommentMode = true;
    }

    toggleImportant(value: boolean) {
        this.newComment.isImportant = value;
    }

    editComment(comment: any) {
        comment.isEdit = true;
    }

    editCommentValue(comment: any, value: string) {
        comment.text = value;

        this.Entity.update(prepareRequest(comment));

        comment.isEdit = false;
    }

}
