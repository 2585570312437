import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CONFIG } from '@proman/config';
import { PreferencesService } from '@proman/services/preferences.service';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { PublicSystemOptions } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { getCurrUser } from '@proman/store/curr-user';

@Component({
    selector: 'pm-language-switch',
    template: `
        <mat-menu #languagesMenu="matMenu">
            <button mat-menu-item
                    *ngFor="let language of languages"
                    (click)="setLanguage(language)">
                {{ language | uppercase }}
            </button>
        </mat-menu>
        <button mat-button
                [matMenuTriggerFor]="languagesMenu">

            <fa name="angle-down" size=" " class="RightMargin"></fa>
            <span *ngIf="!currLang">{{ 'language' | translate }}</span>
            <span *ngIf="currLang">{{ currLang }}</span>
        </button>
    `
})

export class LanguageSwitchComponent implements OnInit {
    @Input() isUser: any;
    @Input() preventReload: boolean = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    currLang: string;
    languages: string[];
    currUser: CurrUser;
    publicSystemOptions: PublicSystemOptions;
    personEntity: EntityInterface;

    constructor(
        private Entity: Entity,
        private store: Store,
        private Preferences: PreferencesService,
    ) {
        this.personEntity = this.Entity.get('person');
        this.store.select(getPublicSystemOptions)
            .subscribe((value) => this.publicSystemOptions = value);

        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        if (!this.publicSystemOptions) return;

        if (typeof this.isUser === 'undefined') {
            this.languages = CONFIG.languages;
            this.currLang = this.Preferences.language() || this.publicSystemOptions.defaultLanguage;

        } else {
            this.languages = this.currUser?.languages;
            this.currLang = this.currUser?.language;

        }

    }

    handleLanguageChange(lang: any) {
        this.Preferences.language(lang);
        this.onChange.emit(lang);

        if (!this.preventReload) window.location.reload();
    }

    setLanguage(lang: any) {

        if (typeof this.isUser === 'undefined') {
            this.handleLanguageChange(lang);

        } else {
            this.Entity
                .get(this.currUser.isCustomer ? 'customer_employee' : this.currUser.type)
                .update({ id: this.currUser.person.id, language: lang })
                .then(() => this.handleLanguageChange(lang));

        }

    }
}
