
import { Component, Input, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { MaterialQuant, Order, Production } from '@proman/interfaces/entity-interfaces';
import { MaterialQuantEntityInterface } from '@proman/resources/material_quant';
import { MaterialEntityInterface } from '@proman/resources/material';
import { MaterialQuantSearchDialogComponent } from '../../materials/components/material-quant-search-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-credit-materials',
    template: `
        <div fxLayout="column" pmPadding style="padding-bottom: 32px;">
            <div fxLayout="column"
                 *ngIf="isAddMaterialButton">
                <pro-btn
                        (onClick)="isAddMaterial = true;isAddMaterialButton = false;"
                        icon="plus"
                        theme="accent"
                        [tooltip]="'receive_material' | translate"
                        [tooltipPosition]="'bottom'"
                ></pro-btn>
            </div>
            <div fxLayout="row" fxFlex *ngIf="isAddMaterial">
                <pro-autoc [value]="searchEntity"
                          [config]="{ label: 'material' }"
                          [getOptions]="searchMaterial"
                          (onChange)="addMaterial($event)"
                          fxFlex></pro-autoc>

                <pro-btn [icon]="'barcode'"
                        theme="accent"
                        (onClick)="searchQuant()"
                ></pro-btn>
            </div>
            <div fxLayout="column"
                 *ngFor="let material of materials; let $index = index;">
                <pro-label>{{ material.name }}</pro-label>
                <pro-autoc [value]="material.quant"
                          [config]="{ label: 'remnant', autoSelectSingleOption: true }"
                          [options]="materialQuantOptions"
                          (onSearch)="searchSource($event, $index)"
                          (onChange)="set(material, 'quant', $event)"></pro-autoc>
                <pm-txt [value]="material.quantity"
                        [config]="{ label: 'quantity', parseNumber: true, validators: { number: true } }"
                        (onChange)="set(material, 'quantity', $event)"></pm-txt>
                <p *ngIf="material.error"
                   class="Alert">
                    {{ material.error | translate }}
                </p>
            </div>
            <pro-btn *ngIf="materials[0]?.quantity"
                    label="credit"
                    theme="accent"
                    [disabled]="isCreditPending"
                    (onClick)="credit()"></pro-btn>
            <pro-btn *ngIf="materials.length"
                    label="cancel"
                    theme="warn"
                    (onClick)="cancel()"></pro-btn>
            <mat-list *ngIf="accountedMaterials ?.length">
                <pro-label>{{ 'accounted_materials' | translate }}</pro-label>
                <mat-list-item *ngFor="let quant of accountedMaterials">
                    <h4 mat-line><a [routerLink]="'MaterialOptions' | pmSref: quant.material.id:'material.edit'">
                        {{ quant.material.name }}</a></h4>
                    <p mat-line> {{ quant.quantity }} {{ quant.material.materialType.unit }} {{ Filter.quantFormat(quant) }}</p>
                </mat-list-item>
            </mat-list>
        </div>
    `
})

export class CreditMaterialsComponent implements OnInit {
    @Input() order: Order;
    @Input() production: Production;

    accountedMaterials: any;
    isAddMaterial: any;
    isAddMaterialButton: boolean;
    materials: any = [];
    materialQuantOptions: Promise<MaterialQuant[]>;
    searchEntity: any;

    materialEntity: MaterialEntityInterface;
    materialQuantEntity: MaterialQuantEntityInterface;
    isCreditPending: boolean = false;

    constructor(
        Entity: Entity,
        private QueryExpression: QueryExpressionService,
        public Filter: FilterService,
        private Dialog: Dialog,
        public ACL: ACL,
    ) {
        this.materialEntity = Entity.get('material') as MaterialEntityInterface;
        this.materialQuantEntity = Entity.get('material_quant') as MaterialQuantEntityInterface;
        this.isAddMaterialButton = this.ACL.checkOne(['material.edit', 'material.update_status']);
    }

    ngOnInit() {
        this.getCreditedMaterials();
    }

    addMaterial(value: any) {
        this.searchEntity = value;

        if (value) {
            this.setUpMaterial(value);
            this.materials.unshift(value);
            this.searchEntity = null;

            setTimeout(() => this.isAddMaterial = false);
        }
    };

    setUpMaterial(material: any) {

        if (material.materialFormats.length) {
            material.isFormated = true;

        }

        return material;
    };

    searchMaterial = (value: string) => {
        return this.materialEntity
            .search({
                search: { name: value, alias: value },
                join: ['materialFormats', 'materialType'],
                limit: 20
            });
    };

    searchSource(value: string, index: number) {
        let request = {
            'material.id': this.materials[index].id,
            'join': ['quantFormats'],
            'type': this.materialQuantEntity.TYPE_STOCK,
            'search': {
                'quantity': value,
                'name': value,
                'quantFormats.value': value
            }
        };

        this.materialQuantOptions = this.materialQuantEntity
            .search(request)
            .then((quants: MaterialQuant[]) => {
                 return quants.map((quant) => {
                    quant.name = this.Filter.quantName(quant, this.materials[0]);

                    return quant;
                });

            });
    }

    credit() {
        this.isCreditPending = true;

        let requests = this.materials
            .filter((material: any) => {
                return material.quantity && !material.credited;
            }).map((material: any) => {
                return this.materialQuantEntity.moveToProduction({
                        order: this.order?.id,
                        production: this.production?.id,
                        material: material.id,
                        quantity: material.quantity,
                        source: material.quant?.id
                    }).then(() => {
                        material.credited = true;
                        material.error = null;
                    })
                    .catch((response: any) => {
                        material.quantity = null;
                        material.error = response.data && response.data.errors && response.data.errors[0].message || 'error';
                        return Promise.reject(null)
                    });
            });

        Promise.all(requests)
            .then(() => {
                this.materials = [];
                this.getCreditedMaterials();
                this.isAddMaterialButton = true;
                this.materialQuantOptions = undefined;
                this.isCreditPending = false;
            });

    };

    set(item: any, property: string, value: any) {
        item[property] = value;
    };

    getCreditedMaterials() {

        if (this.order || this.production) {
            const request: any = {
                type: this.materialQuantEntity.TYPE_PRODUCTION,
                join: ['material', 'material.materialType', 'quantFormats']
            };
            if (this.order) request['order.id'] = this.order.id;
            if (this.production) request['production.id'] = this.production.id;

            this.materialQuantEntity
                .search(request)
                .then((data: MaterialQuant[]) => this.accountedMaterials = data);

        } else {
            this.accountedMaterials = [];
        }

    }

    searchQuant() {
        this.Dialog
            .open(MaterialQuantSearchDialogComponent, {
                params: { stock: [this.materialQuantEntity.TYPE_STOCK, this.materialQuantEntity.TYPE_PRODUCTION].join('|') }
            }, { width: '600px' })
            .then((result: MaterialQuant) => {
                this.set(result.material, 'quant', result);
                this.set(result.material, 'quantity', result.quantity);

                this.addMaterial(result.material);
                this.isAddMaterial = false;
            });
    }

    cancel() {
        this.isAddMaterial = false;
        this.isAddMaterialButton = true;
        this.materials = [];
    }

}
