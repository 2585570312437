import {
    Component,
    Output,
    Input,
    EventEmitter,
    ViewChild,
    ElementRef,
    NgZone,
    OnInit,
    OnDestroy
} from '@angular/core';
import { UploaderService } from '@proman/services/uploader.service';
import { UiPreferencesService, UI_CLIPBOARD_PASTE_TARGET } from '@proman/services/ui-preferences.service';
import $ from 'jquery';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import { CropperDialogComponent } from '@proman/cropper/cropper-dialog.component';
import { getScreenWidthPercent } from '@proman/utils';
import { Dialog } from '@frontend/shared/services/dialog.service';

@Component({
    selector: 'pm-upload',
    template: `
        <div class="Upload" #element [ngClass]="{ 'show-overlay': isOverlay }" (click)="upload()">
            <div *ngIf="!file" fxLayout="row" fxLayoutAlign="center center">
                <div>
                    <fa name="upload" [tooltip]="'upload' | translate"></fa>
                </div>
                <div class="Upload-description">
                    {{ 'click_/_drop' | translate }}
                </div>
            </div>
            <div *ngIf="file" fxLayout="row" fxLayoutAlign="start center">
                <pro-thumbnail [file]="file"></pro-thumbnail>
                <div fxFlex></div>
                <pro-btn *ngIf="onRemove.observers.length"
                        (onClick)="deleteFile($event)"
                        icon="trash"
                        theme="warn"
                        [tooltip]="'delete' | translate"
                ></pro-btn>
            </div>
        </div>
    `,
    styles: [
        ':host { width: 100%; }',
        '.Upload { padding: 20px; margin-bottom: 16px; border: 1px dashed #ebedf2; border-radius: 4px; cursor: pointer; }',
        '.Upload-description { padding-left: 8px; color: rgba(0, 0, 0, 0.5) }',
        '.show-overlay { border: 2px dashed #448aff; background-color: rgba(0, 126, 255, 0.1); }'
    ]
})

export class UploadComponent implements OnInit, OnDestroy {
    @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Input() file: PromanFile;
    @Input() config: {
        data?: any;
        resizeImageRatio?: number;
        resizeImage?: boolean;
    } = {};

    @ViewChild('element', { static: true }) element: ElementRef;
    progress: any;
    isOverlay: any;
    isPasteEnabled: boolean;

    constructor(
        private Uploader: UploaderService,
        private UiPrefs: UiPreferencesService,
        private Dialog: Dialog,
        private zone: NgZone,
    ) {
        this.isPasteEnabled = this.UiPrefs.get(UI_CLIPBOARD_PASTE_TARGET);
    }

    ngOnInit() {
        let element = this.element.nativeElement;

        new window.Dragster(element);

        $(element).on('drop', this.uploadDrop);
        $(element).on('dragenter', this.handleDragEnter);
        $(element).on('dragover', this.handleDragOver);
        $(element).on('dragster:enter', this.showOverlay);
        $(element).on('dragster:leave', this.hideOverlay);

        if (this.isPasteEnabled) document.addEventListener('paste', this.handlePaste);
    }

    ngOnDestroy() {
        let element = this.element.nativeElement;

        $(element).off('drop', this.uploadDrop);
        $(element).off('dragenter', this.handleDragEnter);
        $(element).off('dragover', this.handleDragOver);
        $(element).off('dragster:enter', this.showOverlay);
        $(element).off('dragster:leave', this.hideOverlay);

        if (this.isPasteEnabled) document.removeEventListener('paste', this.handlePaste);
    }

    deleteFile($event: any) {
        $event.stopPropagation();
        this.onRemove.emit();
    }

    handleDragEnter(event: any) {
        event.preventDefault();
    }

    handleDragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();

        return false;
    }

    uploadDrop = (event: any) => {
        this.isOverlay = false;

        event.stopPropagation();
        event.preventDefault();

        this.tryResizingUpload(event.originalEvent.dataTransfer.files);
    };

    showOverlay = () => {
        this.zone.run(() => {
            this.isOverlay = true;
        });
    };

    hideOverlay = () => {
        this.zone.run(() => {
            this.isOverlay = false;
        });
    };

    callback(event: any) {
        this.progress = ((event.loaded / event.total) * 100);
    }

    upload() {
        let uploaderSettings = {};

        this.Uploader
            .init(this.callback, this.config?.data || {})
            .show(uploaderSettings)
            .then(this.handleFile);
    }

    handleFile = (response: any) => {
        this.onUpload.emit(response);
        this.file = response[0];
    };

    handlePaste = (event: any) => {
        if (event.clipboardData.files.length) {
            this.Uploader
                .init(this.callback)
                .upload(event.clipboardData.files, {})
                .then(this.handleFile);
        }

    };

    tryResizingUpload = (files: FileList) => {
        if (this.canResizeFiles(files)) {
            this.Dialog.open(CropperDialogComponent,
                { file: files[0], aspectRatio: this.config.resizeImageRatio },
                { width: `${getScreenWidthPercent(80)}px`, disableClose: true })
                .then((result: File) => {
                    this.uploadFiles([result] as unknown as FileList);
                });
        } else {
            this.uploadFiles(files)
        }
    };

    uploadFiles = (files: FileList) => {
        this.Uploader
            .init(this.callback, this.config?.data || {})
            .upload(files as unknown as any[], {})
            .then(this.handleFile);
    };

    canResizeFiles = (files: FileList) => {
        return this.config.resizeImage && files.length === 1 && files[0].type?.includes('image');
    };

}
