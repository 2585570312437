import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { PromanStateService } from '../services/proman-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { hasSubstring, isDefined, cloneDeep, isArray, isSmarton } from "@proman/utils";
import { ACL } from '@proman/services/acl.service';
import { CurrUser, TabState, UserType } from '@proman/interfaces/object-interfaces';
import { ToolbarService } from '../services/toolbar.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { PublicSystemOptions } from '@proman/interfaces/entity-interfaces';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { MatTabChangeEvent } from '@angular/material/tabs';

const tabStates: {
    [key: string]: {
        keys: string[];
        states: TabState[];
    };
} = {
    Order: {
        keys: ['orderId'],
        states: [
            { name: 'options', params: ['orders', 'orderId'], acl: 'order.display', },
            { name: 'products', params: ['orders', 'orderId', 'products'], acl: 'order.display', },
            { name: 'additional_operations', params: ['orders', 'orderId', 'operations'], acl: 'event.display', },
            { name: 'productions', params: ['orders', 'orderId', 'production'], acl: 'production.view', },
            { name: 'history', params: ['orders', 'orderId', 'history'], acl: 'order.display', },
            { name: 'documents', params: ['orders', 'orderId', 'documents'], acl: 'order.edit', },
            { name: 'payments', params: ['orders', 'orderId', 'payments'], acl: 'invoice.edit', },
            { name: 'shipments', params: ['orders', 'orderId', 'shipments'] },
            { name: 'materials_used', params: ['orders', 'orderId', 'costs'], acl: 'purchase.display' },
            { name: 'article_tests', params: ['orders', 'orderId', 'articles-tests'], acl: 'article_test.view' },
            { name: 'issues', params: ['orders', 'orderId', 'issues'], acl: 'customer_claim.display' },
            { name: 'log', params: ['orders', 'orderId', 'log'] },
        ]
    },
    Production: {
        keys: ['productionId'],
        states: [
            { name: 'options', params: ['production', 'productionId', ''] },
            { name: 'files', params: ['production', 'productionId', 'files'] },
            { name: 'parameters', params: ['production', 'productionId', 'technology'] },
            { name: 'operations', params: ['production', 'productionId', 'operations'] },
            { name: 'materials', params: ['production', 'productionId', 'materials'], acl: 'purchase.display' },
            { name: 'log', params: ['production', 'productionId', 'log'] },
        ]
    },
    ShipmentsTabs: {
        keys: [],
        states: [
            { name: 'shipments', params: ['shipments', ''] },
            { name: 'overdue_shipments', params: ['shipments', 'overdue'] },
            { name: 'date_changes', params: ['shipments', 'logs'], acl: 'shipment.master' },
            { name: 'products', params: ['shipments', 'products'], acl: 'shipment.master' },
            { name: 'containers', params: ['shipments', 'containers'] },
        ],
    },
    Employee: {
        keys: ['employeeId'],
        states: [
            { name: 'information', params: ['employees', 'employeeId'] },
            { name: 'specialisation', params: ['employees', 'employeeId', 'roles'], acl: 'specialisation.view_all' },
            { name: 'permissions', params: ['employees', 'employeeId', 'permissions'], acl: 'permission.edit' },
            { name: 'menu', params: ['employees', 'employeeId', 'menu'], acl: 'permission.edit' },
            { name: 'workplaces', params: ['employees', 'employeeId', 'workplaces'], acl: 'workplace.view' },
            { name: 'schedule', params: ['employees', 'employeeId', 'schedule'] },
            { name: 'attendance', params: ['employees', 'employeeId', 'attendance']  },
            { name: 'operations', params: ['employees', 'employeeId', 'operations'] },
            { name: 'payments', params: ['employees', 'employeeId', 'payments'] },
            { name: 'salary', params: ['employees', 'employeeId', 'salary'] },
            { name: 'documents', params: ['employees', 'employeeId', 'documents'] },
            { name: 'vacations', params: ['employees', 'employeeId', 'vacations'], acl: 'employee.edit' },
            { name: 'customers', params: ['employees', 'employeeId', 'customers'], acl: 'customer.view' },
            { name: 'agents', params: ['employees', 'employeeId', 'agents'], acl: 'agent.view' },
            { name: 'consumers', params: ['employees', 'employeeId', 'consumers'], acl: 'consumer.edit' },
            { name: 'assets', params: ['employees', 'employeeId', 'assets'] },
        ]
    },
    EmployeesDocuments: {
        keys: [],
        states: [
            { name: 'documents', params: ['employees', 'documents']},
            { name: 'defect_acts', params: ['employees', 'documents', 'defect-acts']},
            { name: 'instructions', params: ['employees', 'documents', 'instructions']},
        ]
    },
    Product: {
        keys: ['productId'],
        states: [
            { name: 'options', params: ['products', 'productId'] },
            { name: 'orders', params: ['products', 'productId', 'orders'] },
            { name: 'orders_products', params: ['products', 'productId', 'order-products'] },
            { name: 'purchase', params: ['products', 'productId', 'purchase'] },
            { name: 'containers', params: ['products', 'productId', 'containers'] },
            { name: 'events', params: ['products', 'productId', 'events'] },
            { name: 'logs', params: ['products', 'productId', 'logs'] },
            { name: 'tests', params: ['products', 'productId', 'tests'] },
        ]
    },
    Article: {
        keys: ['articleId'],
        states: [
            { name: 'options', params: ['articles', 'articleId'] },
            { name: 'operations', params: ['articles', 'articleId', 'operations'] },
            { name: 'parameters', params: ['articles', 'articleId', 'parameters'] },
            { name: 'features', params: ['articles', 'articleId', 'features'] },
            { name: 'materials', params: ['articles', 'articleId', 'materials'], acl: 'material.display' },
            { name: 'technical_information', params: ['articles', 'articleId', 'summary'] },
            { name: 'productions', params: ['articles', 'articleId', 'productions'] },
            { name: 'article_categories', params: ['articles', 'articleId', 'categories'] },
            { name: 'orders', params: ['articles', 'articleId', 'orders'] },
            { name: 'tests', params: ['articles', 'articleId', 'tests'] },
            { name: 'development', params: ['articles', 'articleId', 'development'] },
            { name: 'article_children', params: ['articles', 'articleId', 'children'] },
            { name: 'time_restrictions', params: ['articles', 'articleId', 'time-restrictions'] },
            { name: 'discounts', params: ['articles', 'articleId', 'discounts'] },
            { name: 'log', params: ['articles', 'articleId', 'log'] },
            { name: 'pricing', params: ['articles', 'articleId', 'pricing'] },
        ]
    },
    ArticleParameters: {
        keys: ['articleId'],
        states: [
            { name: 'order_parameters', params: ['articles', 'articleId', 'parameters'] },
            { name: 'product_parameters', params: ['articles', 'articleId', 'parameters', 'product'] },
            { name: 'production_parameters', params: ['articles', 'articleId', 'parameters', 'production'] },
            { name: 'test_parameters', params: ['articles', 'articleId', 'parameters', 'test'] },
        ]
    },
    ArticleOperation: {
        keys: ['articleId', 'articleOperationId'],
        states: [
            { name: 'options', params: ['articles', 'articleId', 'operations', 'articleOperationId'] },
            { name: 'resources', params: ['articles', 'articleId', 'operations', 'articleOperationId', 'resources'] },
            { name: 'comments', params: ['articles', 'articleId', 'operations', 'articleOperationId', 'history'] },
            { name: 'materials', params: ['articles', 'articleId', 'operations', 'articleOperationId', 'materials'] },
            { name: 'inventory', params: ['articles', 'articleId', 'operations', 'articleOperationId', 'inventory'] },
            { name: 'parameters', params: ['articles', 'articleId', 'operations', 'articleOperationId', 'parameters'] },

        ]
    },
    Material: {
        keys: ['materialId'],
        states: [
            { name: 'overview', params: ['materials', 'materialId', ''] },
            { name: 'material_movement', params: ['materials', 'materialId', 'remnants-movement'] },
            { name: 'material_categories', params: ['materials', 'materialId', 'categories'] },
            { name: 'status', params: ['materials', 'materialId', 'status'] },
            { name: 'materials_reservations', params: ['materials', 'materialId', 'reservations'] },
            { name: 'articles', params: ['materials', 'materialId', 'articles'] },
            { name: 'formulas', params: ['materials', 'materialId', 'formulas'] },
            { name: 'materials_tests', params: ['materials', 'materialId', 'tests'] },
            { name: 'log', params: ['materials', 'materialId', 'log'] },
        ]
    },
    MaterialsFormulas: {
        keys: [],
        states: [
            { name: 'formulas', params: ['formulas', ''] },
            { name: 'scales_wizard', params: ['formulas', 'mix'] },
            { name: 'log', params: ['formulas', 'log'] },
        ]
    },
    MaterialsReservations: {
        keys: [],
        states: [
            { name: 'materials_reservations', params: ['materials-reservations', ''] },
            { name: 'orders', params: ['materials-reservations', 'unconfirmed'] },
            { name: 'materials_out_of_stock', params: ['materials-reservations', 'out-of-stock'] },
        ]
    },
    Inventory: {
        keys: ['materialId'],
        states: [
            { name: 'overview', params: ['inventory', 'materialId', ''] },
            { name: 'material_movement', params: ['inventory', 'materialId', 'remnants-movement'] },
            { name: 'material_categories', params: ['inventory', 'materialId', 'categories'] },
            { name: 'status', params: ['inventory', 'materialId', 'status'] },
            { name: 'materials_reservations', params: ['inventory', 'materialId', 'reservations'] },
            { name: 'materials_tests', params: ['inventory', 'materialId', 'tests'] },
        ]
    },
    Suppliers: {
        keys: [],
        states: [
            { name: 'suppliers', params: ['suppliers', ''] },
            { name: 'evaluation', params: ['suppliers', 'evaluation'] },
            { name: 'delays', params: ['suppliers', 'delayed-arrivals'] },
            { name: 'debts', params: ['suppliers', 'debts'] },
            { name: 'documents', params: ['suppliers', 'documents' ]},
            { name: 'discounts', params: ['suppliers', 'all-discounts'] },
        ]
    },
    Supplier: {
        keys: ['supplierId'],
        states: [
            { name: 'options', params: ['suppliers', 'supplierId', ''] },
            { name: 'contacts', params: ['suppliers', 'supplierId', 'contacts'] },
            { name: 'materials', params: ['suppliers', 'supplierId', 'materials'], acl: 'material.view' },
            { name: 'purchase', params: ['suppliers', 'supplierId', 'purchase'], acl: 'purchase.view' },
            { name: 'payments', params: ['suppliers', 'supplierId', 'supplier-payments'] },
            { name: 'documents', params: ['suppliers', 'supplierId', 'documents'] },
            { name: 'discounts', params: ['suppliers', 'supplierId', 'discounts'] },
        ]
    },
    Agent: {
        keys: ['agentId'],
        states: [
            { name: 'options', params: ['agents', 'agentId', ''] },
            { name: 'customers', params: ['agents', 'agentId', 'customers'] },
            { name: 'managers', params: ['agents', 'agentId', 'managers'] },
            { name: 'menu', params: ['agents', 'agentId', 'menu'] },
            { name: 'orders', params: ['agents', 'agentId', 'orders'] },
            { name: 'invoices', params: ['agents', 'agentId', 'invoices'] },
        ]
    },
    Carrier: {
        keys: ['carrierId'],
        states: [
            { name: 'options', params: ['carriers', 'carrierId', ''] },
            { name: 'employees', params: ['carriers', 'carrierId', 'employees'] },
        ]
    },
    Customer: {
        keys: ['customerId'],
        states: [
            { name: 'options', params: ['customers', 'customerId', ''] },
            { name: 'employees', params: ['customers', 'customerId', 'employees'], acl: 'customer_employee.display' },
            { name: 'supervisors', params: ['customers', 'customerId', 'managers'] },
            { name: 'confirmations', params: ['customers', 'customerId', 'confirmations'] },
            { name: 'sales_events', params: ['customers', 'customerId', 'meetings'] },
            { name: 'orders', params: ['customers', 'customerId', 'orders'], acl: 'order.view' },
            { name: 'order_proposals', params: ['customers', 'customerId', 'order-proposals'] },
            { name: 'documents', params: ['customers', 'customerId', 'documents'] },
            { name: 'payments', params: ['customers', 'customerId', 'payments'] },
            { name: 'projects', params: ['customers', 'customerId', 'sales-opportunities'] },
            { name: 'invoices', params: ['customers', 'customerId', 'invoices'] },
            { name: 'logins', params: ['customers', 'customerId', 'logins'] },
            { name: 'claims', params: ['customers', 'customerId', 'claims'], acl: 'customer_claim.display' },
            { name: 'tests', params: ['customers', 'customerId', 'tests'] },
            { name: 'presentations', params: ['customers', 'customerId', 'presentations'] },
            // { name: 'assets', params: ['customers', 'customerId', 'assets'] },
        ]
    },
    Customers: {
        keys: [],
        states: [
            { name: 'companies', params: ['customers', ''], types: ['employee'] },
            { name: 'persons', params: ['customers', 'customer-employees'], types: ['employee'] },
            { name: 'users', params: ['customers', 'employees-users'], types: ['employee'] },
            { name: 'types', params: ['customers', 'customer-types'], types: ['employee'] },
            { name: 'departments', params: ['customers', 'departments'], types: ['employee'] },
            { name: 'debts', params: ['customers', 'debts'], types: ['employee', 'agent'] },
            { name: 'cards', params: ['customers', 'cards'], types: ['employee'] },
            { name: 'permissions', params: ['customers', 'customers-permissions'], types: ['employee'] },
            { name: 'clients_portal', params: ['customers', 'clients-portal'], types: ['employee'] },
            { name: 'shop_options', params: ['customers', 'shop-options'], types: ['employee'] },
            { name: 'shop_templates', params: ['customers', 'shop-templates'], types: ['employee'], acl: 'system_options.edit' },
            { name: 'customer_claims', params: ['accounting', 'claims'], acl: 'customer_claim.display' },
            { name: 'certificates', params: ['customers', 'certificates'] },
        ]
    },
    DevelopmentProjects: {
        keys: [],
        states: [
            { name: 'development', params: ['dev-projects', ''] },
            { name: 'projects', params: ['dev-projects', 'all'] },
            // { name: 'stages', params: ['dev-projects', 'stages'] },
            { name: 'tasks', params: ['dev-projects', 'tasks'] },

        ]
    },
    DevelopmentProjectView: {
        keys: ['developmentProjectId'],
        states: [
            { name: 'options', params: ['dev-projects', 'developmentProjectId', ''] },
            { name: 'articles', params: ['dev-projects', 'developmentProjectId', 'articles'], acl: 'article.view' },
            { name: 'order_proposals', params: ['dev-projects', 'developmentProjectId', 'proposals'], acl: 'order.view' },
        ]
    },
    Role: {
        keys: ['roleId'],
        states: [
            { name: 'options', params: ['roles', 'roleId', ''] },
            { name: 'permissions', params: ['roles', 'roleId', 'permissions'], acl: 'permission.edit' },
            { name: 'specialisations', params: ['roles', 'roleId', 'specialisations'], acl: 'specialisation.display' },

        ]
    },
    RoleSpecialisation: {
        keys: ['roleId', 'specialisationId'],
        states: [
            { name: 'employees', params: ['roles', 'roleId', 'specialisations', 'specialisationId', ''] },
            { name: 'options', params: ['roles', 'roleId', 'specialisations', 'specialisationId', 'options'] },
            { name: 'permissions', params: ['roles', 'roleId', 'specialisations', 'specialisationId', 'permissions'], acl: 'permission.edit' },
            { name: 'table_filters', params: ['roles', 'roleId', 'specialisations', 'specialisationId', 'table-filters'], acl: 'filter.edit' },
            { name: 'menu', params: ['roles', 'roleId', 'specialisations', 'specialisationId', 'menu'] },
        ]
    },
    MaintenanceList: {
        keys: [],
        states: [
            { name: 'maintenance', params: ['maintenance', ''] },
            { name: 'periodic_maintenance', params: ['maintenance', 'periodic'] },
            { name: 'maintenance_costs', params: ['maintenance', 'purchases'] },
            { name: 'unoperable_workplaces', params: ['maintenance', 'unoperable-workplaces'] },
        ]
    },
    MaintenanceRecord: {
        keys: ['maintenanceId'],
        states: [
            { name: 'options', params: ['maintenance', 'maintenanceId', ''] },
            { name: 'purchases', params: ['maintenance', 'maintenanceId', 'purchases'] },
        ]
    },
    ResourcesList: {
        keys: [],
        states: [
            { name: 'resources', params: ['resources', ''] },
            { name: 'workplaces', params: ['resources', 'workplaces'], acl: 'workplace.display' },
            { name: 'workplaces_operators', params: ['resources', 'workplaces-operators'] },
            { name: 'workgroups', params: ['resources', 'workgroups'], acl: 'workgroup.display' },
            { name: 'operations', params: ['resources', 'operations'] },
            { name: 'warehouse', params: ['resources', 'warehouse'] },
            { name: 'subcontractors', params: ['resources', 'subcontractors'], acl: 'subcontractor.display' },
        ]
    },
    Workplace: {
        keys: ['workplaceId'],
        states: [
            { name: 'options', params: ['resources', 'workplaces', 'workplaceId', ''] },
            { name: 'employees', params: ['resources', 'workplaces', 'workplaceId', 'employees'] },
            { name: 'maintenance', params: ['resources', 'workplaces', 'workplaceId', 'maintenance'] },
            { name: 'expressions', params: ['resources', 'workplaces', 'workplaceId', 'expressions'] },
            { name: 'parameters', params: ['resources', 'workplaces', 'workplaceId', 'parameters'], acl: 'workplace.edit' },
            { name: 'tools', params: ['resources', 'workplaces', 'workplaceId', 'tools'] },
            { name: 'accounting', params: ['resources', 'workplaces', 'workplaceId', 'accounting'] },
            { name: 'articles', params: ['resources', 'workplaces', 'workplaceId', 'articles'] },
            { name: 'defect_acts', params: ['resources', 'workplaces', 'workplaceId', 'defect_acts'] },
        ]
    },
    Operation: {
        keys: ['operationId'],
        states: [
            { name: 'options', params: ['resources', 'operations', 'operationId', ''],  },
            { name: 'resources', params: ['resources', 'operations', 'operationId', 'workgroups'] },
            { name: 'operation_visible_parameters', params: ['resources', 'operations', 'operationId', 'parameters'] },
            { name: 'relations', params: ['resources', 'operations', 'operationId', 'relations'] },
            { name: 'articles', params: ['resources', 'operations', 'operationId', 'articles'] },
            { name: 'materials', params: ['resources', 'operations', 'operationId', 'materials'] },
        ]
    },
    WorkplacesInfo: {
        keys: [],
        states: [
            { name: 'map', params: ['workplaces', ''] },
            { name: 'sensors', params: ['workplaces', 'sensors'], acl: 'sensor.display' },
            { name: 'graph', params: ['workplaces', 'sensor-graph'], acl: 'sensor.display' },
        ]
    },
    Workgroup: {
        keys: ['workgroupId'],
        states: [
            { name: 'options', params: ['resources', 'workgroups', 'workgroupId', ''] },
            { name: 'operations', params: ['resources', 'workgroups', 'workgroupId', 'operations'] },
            { name: 'specialisations', params: ['resources', 'workgroups', 'workgroupId', 'specialisations'] },
        ]
    },
    Subcontractor: {
        keys: ['subcontractorId'],
        states: [
            { name: 'options', params: ['subcontractors', 'subcontractorId', ''] },
            { name: 'employees', params: ['subcontractors', 'subcontractorId', 'employees'] },
            { name: 'supervisors', params: ['subcontractors', 'subcontractorId', 'supervisors'] },
            { name: 'parameters', params: ['subcontractors', 'subcontractorId', 'parameters'] },
            { name: 'operations', params: ['subcontractors', 'subcontractorId', 'operations'] },
        ]
    },
    SystemSettingsDevices: {
        keys: [],
        states: [
            { name: 'entry_sensor', params: ['system-settings', 'devices', ''] },
            { name: 'payment_terminals', params: ['system-settings', 'devices', 'payment-terminals'] },
            { name: 'mqtt_devices', params: ['system-settings', 'devices', 'mqtt'] },
            { name: 'devices', params: ['system-settings', 'devices', 'devices'] },
            { name: 'cameras', params: ['system-settings', 'devices', 'cameras'], acl: 'camera.view' },
            { name: 'tokens', params: ['system-settings', 'devices', 'tokens'], acl: 'system_options.edit' },
            { name: 'e_shops', params: ['system-settings', 'devices', 'e-shops'], acl: 'system_options.edit' },
            { name: 'g_tags', params: ['system-settings', 'devices', 'g-tags'], acl: 'system_options.edit' },
        ]
    },
    SalesReports: {
        keys: [],
        states: [
            { name: 'order_reports', params: ['reports', 'sales', ''], acl: 'order.view' },
            { name: 'income_report', params: ['reports', 'sales', 'income-report'] },
            { name: 'customer_activity_report', params: ['reports', 'sales', 'customer-activity'] },
            { name: 'cash_registers', params: ['reports', 'sales', 'cash-operations'] },
            { name: 'order_products', params: ['reports', 'sales', 'order-products'] },
            { name: 'product_difference', params: ['reports', 'sales', 'product-difference'] },
            { name: 'costs', params: ['reports', 'sales', 'costs'] },
            { name: 'invoices', params: ['reports', 'sales', 'invoices'] },
            { name: 'hourly_orders', params: ['reports', 'sales', 'orders-hourly'] },
            { name: 'orders_sales', params: ['reports', 'sales', 'orders-sales'] },
            { name: 'sales_articles', params: ['reports', 'sales', 'sales-articles'] },
            { name: 'consumer_orders', params: ['reports', 'sales', 'consumer-orders'] },
            { name: 'invoices_and_orders', params: ['reports', 'sales', 'invoices-and-orders'] },
            { name: 'by_payment_type', params: ['reports', 'sales', 'payment-types'] },
        ]
    },
    LoadingReports: {
        keys: [],
        states: [
            { name: 'packed_production_value_report', params: ['reports', 'loading', ''] },
            { name: 'delivery_report', params: ['reports', 'loading', 'delivery'] },
            { name: 'shipments', params: ['reports', 'loading', 'shipment'] },
            { name: 'shipment_delays', params: ['reports', 'loading', 'shipment-delay'] },
        ]
    },
    MaterialsReports: {
        keys: [],
        states: [
            { name: 'material_difference', params: ['reports', 'materials', 'material-difference'] },
            { name: 'material_consumption', params: ['reports', 'materials', 'material-consumption'] },
            { name: 'material_movement', params: ['reports', 'materials', 'material-movement'] },
        ]
    },
    ProductionReports: {
        keys: [],
        states: [
            { name: 'workplaces_load_report', params: ['reports', 'production', ''] },
            { name: 'employees_load_report', params: ['reports', 'production', 'employees-load'] },
            { name: 'specialisation_load_report', params: ['reports', 'production', 'specialisations-load'] },
            { name: 'daily_report', params: ['reports', 'production', 'daily'] },
            { name: 'daily_report_income', params: ['reports', 'production', 'daily-income'] },
            { name: 'production_income', params: ['reports', 'production', 'production-income'] },
            { name: 'operations_estimation', params: ['reports', 'production', 'operations-estimation'] },
            { name: 'articles_reports', params: ['reports', 'production', 'articles'] },
            { name: 'types', params: ['reports', 'production', 'types'] },
        ]
    },
    EntranceReport: {
        keys: [],
        states: [
            { name: 'entrance_report', params: ['reports', 'entrance', ''] },
            { name: 'attendance', params: ['reports', 'entrance', 'truancy'] },
            { name: 'congestion', params: ['reports', 'entrance', 'efficiency'] },
            { name: 'operations', params: ['reports', 'entrance', 'operations'] },
            { name: 'salary_changes', params: ['reports', 'entrance', 'salary-changes'] },
        ]
    },
    UserOptions: {
        keys: [],
        states: [
            { name: 'information', params: ['options', ''] },
            { name: 'settings', params: ['options', 'system-settings'] },
            { name: 'notifications', params: ['options', 'notifications'] },
            { name: 'errors', params: ['options', 'errors'] },
            { name: 'menu', params: ['options', 'menu'] },
        ]
    },
    SaleOpportunitiesList: {
        keys: [],
        states: [
            { name: 'sales_opportunities_active', params: ['sales-opportunities', ''] },
            { name: 'sales_opportunities_all', params: ['sales-opportunities', 'all'] },
            { name: 'chart', params: ['sales-opportunities', 'chart'] },
        ]
    },
    TimeOptions: {
        keys: [],
        states: [
            { name: 'holidays', params: ['time-options', ''], acl: 'time_options.display' },
            { name: 'workshifts', params: ['time-options', 'shifts'], acl: 'time_options.display' },
            { name: 'workday_exception_reasons', params: ['time-options', 'reasons'], acl: 'time_options.display' },
        ]
    },
    Tabel: {
        keys: [],
        states: [
            { name: 'monthly_tabel', params: ['tabel', 'month'] },
            { name: 'weekly', params: ['tabel', 'weekly'], acl: 'employee.view_all' },
            { name: 'daily', params: ['tabel', 'daily'], acl: 'employee.view_all' },
            { name: 'yearly', params: ['tabel', 'yearly'], acl: 'employee.view_all' },
            { name: 'tabel', params: ['tabel', 'tabel'], acl: 'employee.view_all' },
            { name: 'salary', params: ['tabel', 'salary'], acl: 'employee.show_price' },
            { name: 'additives', params: ['tabel', 'additives'], acl: 'employee.view_all' },
            { name: 'remnants', params: ['tabel', 'remnants'], acl: 'employee.view_all' },
            { name: 'salary_taxes', params: ['tabel', 'salary-taxes'], acl: 'employee.view_all' },
            { name: 'vacations_requests', params: ['tabel', 'vacations_requests'], acl: 'employee.view_all' },
        ]
    },
    Company: {
        keys: ['companyId'],
        states: [
            { name: 'options', params: ['companies', 'companyId', ''] },
            { name: 'company_account', params: ['companies', 'companyId', 'account'] },
            { name: 'employees', params: ['companies', 'companyId', 'employees'] },
        ]
    },
    SystemSettingsMenu: {
        keys: [''],
        states: [
            { name: 'employee', params: ['system-settings', 'menu', ''] },
            { name: 'agent', params: ['system-settings', 'menu', 'agent'] },
            { name: 'customer', params: ['system-settings', 'menu', 'customer'] },
        ]
    },
    ActionsList: {
        keys: [''],
        states: [
            { name: 'objects', params: ['notifications', 'objects'] },
            { name: 'periodic_listeners', params: ['notifications', 'periodic'] },
            { name: 'mail_notifications', params: ['notifications', 'mail'] },
            { name: 'button_listeners', params: ['notifications', 'button'] },
        ]
    }
};

@Component({
    selector: 'pm-tabs',
    template: `
        <mat-tab-group (selectedTabChange)="handleTabChange($event)"
                       [selectedIndex]="activeItemIndex"
                       class="Tabs">
            @for (item of items; track $index) {
                <mat-tab [label]="item.name | translate"
                         [attr.data-tab]="item.name"></mat-tab>
            }

            @if (additionalState) {
                @if (isArray(additionalState)) {
                    @for (aState of additionalState; track $index) {
                        <mat-tab [label]="aState.translation | translate"
                                 [attr.data-tab]="aState.translation"></mat-tab>
                    }
                } @else {
                    <mat-tab [label]="additionalState.translation | translate"
                             [attr.data-tab]="additionalState.translation"></mat-tab>
                }
            }
        </mat-tab-group>
    `
})

export class TabsComponent implements OnInit, OnChanges {
    @Input() parentState: any;
    @Input() additionalState: any;
    @Input() filter: any;
    items: TabState[] = [];
    activeItemIndex: any = 0;
    activeItem: any;
    isSmarton: boolean = isSmarton();
    _preventNavigation: boolean;
    currUser: CurrUser;
    publicSystemOptions: PublicSystemOptions;


    constructor(
        platformLocation: PlatformLocation,
        private route: ActivatedRoute,
        private Router: Router,
        private PromanState: PromanStateService,
        private Location: Location,
        private ACL: ACL,
        private Toolbar: ToolbarService,
        private store: Store,
    ) {
        platformLocation.onPopState(() => this.setActiveItem());
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
        this.store.select(getPublicSystemOptions).subscribe((value) => this.publicSystemOptions = value);
    }

    ngOnChanges(changes: SimpleChanges) {
        const currUser = this.currUser;

        if (this.parentState) {
            this.items = tabStates[this.parentState] ? cloneDeep(tabStates[this.parentState].states) : [];

            if (!(this.items.length)) console.warn(`${this.parentState} has no parent states`);

            this.adjustParams();

            this.items.forEach((item: any) => {
                item.path = this.getRouterLink(item);
            });

            this.items = this.items.filter((item) => item.chef ? this.isSmarton : ( !isDefined(item.acl) || this.ACL.check(item.acl)) );

            this.items = this.items.filter((item) => item.types ? item.types.indexOf(currUser.type as UserType) > -1 : true);

            if (this.publicSystemOptions.corporateChild) {
                this.items = this.items.filter((item) => !item.corporateRestrict);
            }

            if (this.filter) this.items = this.items.filter(this.filter);

            if (this.items.length) this.activeItemIndex = 0;

            this._preventNavigation = true;

            setTimeout(() => this._preventNavigation = false, 300);

            this.setActiveItem();

        }

    }

    ngOnInit() {
        this.Toolbar.timeStampSubject.subscribe(() => this.ngOnChanges({}));
    }

    adjustParams() {
        if (!tabStates[this.parentState]) return;

        for (let key of tabStates[this.parentState].keys) {

            let route = this.route.root;

            let keyVal: any;

            if (key) {
                while (route.firstChild) {

                    keyVal = +route.snapshot.params[key];

                    if (keyVal) {
                        break;
                    } else {
                        route = route.firstChild;
                    }

                }

                if (!keyVal) console.warn(`TabsComponent adjustParams(): parentState ${this.parentState} could not get keyValue`);

                this.items.forEach((item: any) => {
                    let params = item.params;

                    item.params = params.map((item: any) => {
                        if (item === key) item = keyVal;

                        return item;
                    });
                });
            }

        }

    }

    setActiveItem() {
            if (!this.items) return;

            let path = this.Location.path();

            if (this.additionalState) {
              if (isArray(this.additionalState)) {
                this.additionalState.forEach((aState: any) => {
                  if (hasSubstring(path, aState.path)) {
                    this.activeItem = aState;
                    this.activeItemIndex = this.items.length + (this.additionalState as any[]).indexOf(aState);
                  }
                })
              } else {
                if (hasSubstring(path, this.additionalState.path)) {
                  this.activeItem = this.additionalState;
                  this.activeItemIndex = this.items.length;
                }
              }
            }

            if (this.items.length) {
                for (let iter = this.items.length - 1 ; iter !== 0; iter--) {
                    let item = this.items[iter];

                    if (hasSubstring(path, item.path)) {
                        this.activeItem = this.items[iter];
                        this.activeItemIndex = iter;
                        break;
                    }

                }

            }

    }

    getRouterLink(item: any) {
        return `/${(item.params || []).join('/')}`;
    }

    handleTabChange = (event: MatTabChangeEvent) => {
        if (this._preventNavigation || event.index === this.activeItemIndex) return;

        if (event.index >= this.items.length && this.additionalState) {
            const additionalState = this.additionalState;
            if (isArray(additionalState)) {
              const index = event.index - this.items.length;
              this.PromanState.to(additionalState[index].name, additionalState[index].params);
            } else {
              this.PromanState.to(additionalState.name, additionalState.params);
            }

        } else {
            this.PromanState.to(this.items[event.index].path);

        }

    };
    isArray = (value: any) => isArray(value);

}
