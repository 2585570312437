import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { isDefined } from '@proman/utils';
import { FaIcons } from '../../core/icons';

@Component({
    selector: 'pm-icon-select',
    template: `
        <div (click)="toggled = true" class="Editable">
            <span class="Editable-Label">
                {{ (config.label || 'icon') | translate }}
            </span>

            &nbsp;&nbsp;

            <fa *ngIf="value" [name]="value"></fa>

            <span *ngIf="!value">{{ 'no_value_selected' | translate }}</span>

        </div>

       <mat-expansion-panel class="IconPicker" [expanded]="toggled">
           <div class="IconList" fxLayout="row wrap">
               <fa *ngFor="let icon of icons"
                      [name]="icon"
                      class="Clickable"
                      (click)="selectIcon(icon)"></fa>
           </div>

       </mat-expansion-panel>
    `,
    styles: [`
        mat-expansion-panel.IconPicker { box-shadow: none; }

        .IconList {
            padding: 8px;
        }

        .IconList fa {
            padding: 4px;
        }

    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class IconSelectComponent implements OnInit {
    @Input() value: string;
    @Input() config: any = {};
    @Input() toggled: boolean = false;
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

    icons: any = FaIcons;

    constructor(
        private cd: ChangeDetectorRef
    ) {

    }

    ngOnInit() {

    }

    toggle(value?: boolean) {
        this.toggled = isDefined(value) ? value : !this.toggled;

        this.cd.markForCheck();
    }

    selectIcon(icon: string) {
        this.value = icon;
        this.toggled = false;

        this.onChange.emit(icon);
        this.cd.markForCheck();
    }

}
