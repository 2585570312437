
        .Relation {
            padding: 4px;
        }

        .warn {
            color: #F44336;
        }

        .dark-mode .warn {
            color: #ff6c6c;
        }

        .relationContent {
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
        }
    