import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pm-simple-form-field',
    template: `        
        <ng-container [ngSwitch]="config.type">
            <pm-txt *ngSwitchCase="'string'"
                    [value]="value"
                    [config]="{ label: config.description, required: config.required }"
                    (onChange)="handleChange($event)"></pm-txt>
            
            <pm-txt *ngSwitchCase="'password'"
                    [value]="value"
                    [config]="{ label: config.description, type: 'password', required: config.required, debounce: 200 }"
                    (onChange)="handleChange($event)"></pm-txt>
            
            <pm-txt *ngSwitchCase="'email'"
                    [value]="value"
                    [config]="{ label: config.description, required: config.required, validators: { email: true } }"
                    (onChange)="handleChange($event)"></pm-txt>
            
        </ng-container>
    `
})

export class SimpleFormFieldComponent implements OnInit {
    @Input() value: any;
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(

    ) {

    }

    ngOnInit() {
        if (!this.value && this.config.default) {
            this.handleChange(this.config.default);
        }
        
    }

    handleChange(value: any) {
        this.value = value;

        this.onChange.emit(value);
    }

}
