export const ENTITY_TO_STATE = {
    sale_opportunity: 'SaleOpportunity',
    development_project: 'DevelopmentProject',
    order: 'OrderInfo',
    employee: 'Employee',
    production: 'Production',
    workplace: 'Workplace',
    purchase: 'Purchase',
    sale_event: 'SaleEvent',
};

export const ENTITY_TABLE_STATE = [
    {
        entity: 'sale_opportunity',
        table: 'sale_opportunity_projects',
        state: 'SaleOpportunity'
    },
    {
        entity: 'development_project',
        table: 'development_project',
        state: 'DevelopmentProject'
    },
    {
        entity: 'order',
        table: 'orders',
        state: 'OrderInfo'
    },
    {
        entity: 'production_operation',
        table: 'events',
        state: 'Event'
    },
    {
        entity: 'production',
        table: 'production',
        state: 'Production'
    },
    {
        entity: 'article',
        table: 'articles',
        state: 'ArticleInfo'
    },
    {
        entity: 'material',
        table: 'materials',
        state: 'Material'
    },
    {
        entity: 'product',
        table: 'products',
        state: 'Product'
    },
    {
        entity: 'shipment',
        table: 'shipments',
        state: 'Shipment'
    },
];
