import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Entity } from '@proman/services/entity.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'pm-bug-tracker-dialog',
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="confirm()">
            <pro-dialog-title title="system_bug_message"></pro-dialog-title>
            <div mat-dialog-content>
                <img [src]="message.screenshot"
                     style="max-width: 600px;">
                <p>
                    <b>{{ 'location' | translate }}</b> {{ message.url }}
                </p>
                <br>
                <pm-txt [config]="{ label: 'comments', type: 'textarea' }"
                        [value]="message.comment"
                        (onChange)="set('comment', $event)"></pm-txt>
            </div>
            <pro-dialog-actions variant="create"></pro-dialog-actions>
        </form>
    `
})

export class BugTrackerDialogComponent {
    form: UntypedFormGroup = new UntypedFormGroup({});
    bugTrackerEntity: any;
    message: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) private data: any,
        private Entity: Entity,
        private store: Store,
        private dialogRef: MatLegacyDialogRef<BugTrackerDialogComponent>
    ) {
        this.bugTrackerEntity = this.Entity.get('bug_tracker');
        this.store.select(getCurrUser).pipe(filter(value => !!value))
            .subscribe((currUser) => {
                this.message = {
                    person: currUser.person.id,
                    screenshot: this.data.screenshot,
                    url: this.data.url,
                    comment: ''
                };
            });
    }

    set(property: any, value: any) {
        this.message[property] = value;
    };

    confirm() {
        this.bugTrackerEntity
            .postRequest('post', this.message)
            .then(() => {
                this.dialogRef.close(1);
            });
    };
}
