import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { Entity, EntityInterface, EntityNameType } from "@proman/services/entity.service";
import { AccountCategory } from "@proman/interfaces/entity-interfaces";
import { FilterService } from "@proman/services/filter.service";
import { LedgerEntityInterface } from "@proman/resources/ledger";
import { findById } from "@proman/utils";

@Component({
    selector: 'pm-ledger-type-create-dialog',
    template: `
        <pro-dialog-title [title]="'Create_Ledger_Type'"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-txt [value]="name"
                    [config]="{ label: 'name', required: true, validators: { unique: { resource: 'ledger_types' } }  }"
                    (onChange)="set('name', $event)"
                    [disabled]="name === 'default'"></pm-txt>
            <br>
            <pro-select [value]="type"
                        [config]="{ label: 'type', isNone: true  }"
                        [options]="ledgerTypesOptions"
                        [disabled]="type === 'default' || type?.id === 'default'"
                        (onChange)="set('type', $event)">
            </pro-select>
            <div fxLayout="row" fxFlex>
                <pm-account-category-type-btn
                        (onChange)="set('typeCrdt', $event)"
                        [value]="typeCrdt">
                </pm-account-category-type-btn>
                <pro-autoc
                  [value]="accountCategoryCrdt?.name"
                  (onChange)="set('accountCategoryCrdt', $event)"
                  [config]="{ label: 'account_category_credit', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }">
                </pro-autoc>
                <pm-account-category-type-btn
                        (onChange)="set('typeDbit', $event)"
                        [value]="typeDbit">
                </pm-account-category-type-btn>
                <pro-autoc
                  [value]="accountCategoryDbit?.name"
                  (onChange)="set('accountCategoryDbit', $event)"
                  [config]="{ label: 'account_category_debit', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }">
                </pro-autoc>
            </div>

        </div>

        <pro-dialog-actions
                (callback)="mode === 'create' ? confirm() : save()"
                [variant]="mode === 'create' ? 'create' : 'update'"
                [isCallbackDisabled]="!name">
        </pro-dialog-actions>
    `
})

export class CreateLedgerTypeDialogComponent {
    entityName: EntityNameType;
    ledgerEntityInterface: LedgerEntityInterface
    LedgerTypesEntityInterface: EntityInterface
    typeDbit: string = 'D';
    typeCrdt: string = 'C';
    ledgerType: any;
    ledgerTypesOptions: any[] = [];
    type: any;
    accountCategoryCrdt: any;
    accountCategoryDbit: any;
    name: string = '';
    mode: string = '';
    ready: boolean = false;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<CreateLedgerTypeDialogComponent>
    ) {
        this.mode = data.mode;
        if (this.mode === 'edit' && data.item) {
            this.type = data.item.type;
            this.name = data.item.name;
            this.typeDbit = data.item.typeDbit ?? '';
            this.typeCrdt = data.item.typeCrdt ?? '';
            this.accountCategoryCrdt = data.item.accountCategoryCrdt;
            this.accountCategoryDbit = data.item.accountCategoryDbit;
        }
        this.LedgerTypesEntityInterface = Entity.get('ledger_types');
        this.ledgerEntityInterface = Entity.get('ledger');
        if (this.type === 'default') {
            this.ledgerTypesOptions = [{ id: 'default', name: this.Filter.translate('default')}];
            this.type = this.ledgerTypesOptions[0];
        } else {
            this.ledgerEntityInterface.getLedgerTypesList().then((response) => {
                Object.values(response).forEach((type: any) =>
                  this.ledgerTypesOptions.push({ id: type, name: this.Filter.translate(type) }));
                this.type = findById(this.ledgerTypesOptions, this.type);
            })
        }
    }

    confirm = () => {
        this.LedgerTypesEntityInterface.create({
            name: this.name,
            typeCrdt: this.typeCrdt,
            typeDbit: this.typeDbit,
            accountCategoryDbit: this.accountCategoryDbit?.id,
            accountCategoryCrdt: this.accountCategoryCrdt?.id,
            type: this.type.id,
        }).then((response: any) => this.dialogRef.close(response.data));
    };

    save = () => {
        this.LedgerTypesEntityInterface.update({
            id: this.data.item.id,
            name: this.name,
            typeCrdt: this.typeCrdt,
            typeDbit: this.typeDbit,
            accountCategoryDbit: this.accountCategoryDbit?.id,
            accountCategoryCrdt: this.accountCategoryCrdt?.id,
            type: this.type.id,
        }).then((response: any) => this.dialogRef.close(response.data));
    };

    set = (property: string, value: any) => this[property] = value

    getCategoryName = (cat: AccountCategory) => cat ? `${cat.keyName} - ${cat.name}` : '';

}
