import { Component, Input } from '@angular/core';

@Component({
    selector: 'pm-content',
    template: `
    <div class="Content OverflowAuto" [ngStyle]="{ 'max-height': height + 'px' }">
        <ng-content></ng-content>
    </div>
    `,
    styles: ['.Content { overflow: auto; max-height: 100%; }']
})

export class ContentComponent {
    @Input() height: number = 400;

    constructor() {}

}
