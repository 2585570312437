import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { findByProperty, findById } from '@proman/utils';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { ToastService } from '@proman/services/toast.service';
import { Store } from '@ngrx/store';
import { getSystemOptions } from '@proman/store/system-options';
import { SystemOptions } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-parameter-workgroup',
    template: `
        <pro-select fxFlex="250px"
                [value]="parameter?.value"
                [config]="config"
                [options]="parameter?.options"
                [disabled]="disabled"
                (onChange)="handleChange($event)"></pro-select>
    `
})
export class ParameterWorkgroupComponent implements OnInit {
    @Input() config: any;
    @Input() value: any;
    @Input() disabled: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    parameter: any;
    operationId: number;
    systemOptions: SystemOptions;

    constructor(
        private Entity: Entity,
        private store: Store,
        private ParametersOptions: ParametersOptionsService,
        private cd: ChangeDetectorRef,
        private Toast: ToastService,
    ) {
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
    }

    ngOnInit() {
        if (this.config.isDefinition) {
            this.fooBoo(this.value);

        } else {

            if (!this.value || this.value === '-') {
                this.value = this.config.parameter?.parameter?.value;
                if (!this.value) {
                    this.Toast.pop('error', this.config.parameter.parameter.name + 'parameter_error');
                    return;
                }
            }

            this.booFoo();

        }

    }

    fooBoo(value: any) {
        let data: any;

        if (value) {

            try {
                data = JSON.parse(value);

            } catch (e) {
                data = {};

            }

        }

        this.Entity.get('operation').search().then((response: any) => {
            let value;

            if (data) {
                value = findById(response, { id: data.operationId });

            }

            this.parameter = { options: response, value };

            this.cd.markForCheck();
        });
    }

    handleChange($event: any) {
        let data;

        if (this.config.isDefinition) {
            data = { operationId: $event ? $event.id : null, workplaceId: null };

        } else {
            data = { operationId: this.operationId, workplaceId: $event && $event.id || null };

        }

        this.onChange.emit(JSON.stringify(data));
    }

    async booFoo() {
        let data = this.config;
        let parsedData: any;
        let params: any;
        let articleId: number;

        function aliasDisplay() {

        }

        function nameDisplay(workplace: any) {
            workplace.name = workplace.name;
        }

        function fullDisplay(workplace: any) {
            workplace.name = workplace.alias + ' ' + workplace.name;
        }

        function selectDisplayFn(value: any) {

            switch (value) {

                case 'name':
                    return nameDisplay;

                case 'full':
                    return fullDisplay;

                default:
                    return aliasDisplay;
            }
        }

        if (!data) {
            return;
        }

        if (data.production && data.production.article) {
            articleId = data.production.article.id;

        } else if (data.article) {
            articleId = data.article.id;

        } else if (data.orderProposalProduct && data.orderProposalProduct.article) {
            articleId = data.orderProposalProduct.article.id;

        } else {
            articleId = null;

        }
        try {
            parsedData = JSON.parse(this.value);
        } catch (exception) {
            parsedData = {};
        }

        if (articleId && parsedData.operationId) {
            this.operationId = parsedData.operationId;
            params = { 'operation.id': this.operationId };
            params['article.id'] = articleId;
        } else return;

        await this.ParametersOptions
            .get({ entity: 'article_operation', entityParams: params })
            .then((response: any) => {
                if (!response) return;

                const articleOperationId = response.id;
                const systemOptions = this.systemOptions;
                let displayFn;

                this.ParametersOptions
                    .search({ entity: 'article_operation_workplace', entityParams: { 'articleOperation.id' : articleOperationId, 'join': ['workplace', 'workplace.workgroup'], translate: true } })
                    .then((data: any) => {
                        const workplaces = data.map((articleOperationWorkplace: any) => articleOperationWorkplace.workplace);

                        for (let iter = 0; iter < workplaces.length; iter++) {
                            if (workplaces[iter].workgroup) workplaces[iter].workgroupName = workplaces[iter].workgroup.name;
                        }

                        displayFn = selectDisplayFn(systemOptions.workgroupParameterDisplay);

                        workplaces.forEach(displayFn);

                        this.parameter = {
                            value: findByProperty(workplaces, 'id', parseInt(parsedData.workplaceId)),
                            options: workplaces
                        };

                        this.cd.markForCheck();
                    });
            });
    }
}
