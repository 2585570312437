import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ProductsService } from '../../products/services/products.service';

@Component({
    selector: 'pm-product-storage-info-dialog',
    template: `
        <pro-dialog-title [title]="data.product.name"></pro-dialog-title>
        <div mat-dialog-content>
            <div fxLayout="column">
                <table class="Table stripped">
                    <thead>
                    <tr>
                        <th>{{ 'order' | translate }}</th>
                        <th>{{ 'customer' | translate }}</th>
                        <th>{{ 'date' | translate }}</th>
                        <th>{{ 'quantity' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let record of info">
                        <td><a [routerLink]="'Order' | pmSref: record.orderId ">{{ record.orderNumber }} {{ record.orderName }}</a></td>
                        <td>{{ record.customerName }}</td>
                        <td>{{ record.date | proDateTime }}</td>
                        <td>{{ record.quantity }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pro-dialog-actions></pro-dialog-actions>

    `
})

export class ProductStorageInfoDialogComponent {
    info: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        productService: ProductsService,
        public dialogRef: MatLegacyDialogRef<ProductStorageInfoDialogComponent>
    ) {
        productService
            .getStorageInfo(this.data.product.id)
            .then((response: any) => this.info = response);
    }

}
