import { distinctUntilChanged, takeUntil, tap } from '@proman/rxjs-common';
import {
    Component, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy,
    OnInit, OnDestroy
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { FilterService } from '@proman/services/filter.service';
import { RequestService } from '@proman/services/request.service';
import { DocsIdValues } from '@proman/store/docs-id';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { IframeDialogComponent } from '@frontend/shared-dialogs/components/iframe-dialog.component';

@Component({
    selector: 'pm-docs',
    template: `
    <div #el class="Docs2"
         [ngClass]="{ 'DisplayNone': (isValue) }"
         (mouseenter)="enterDocs()"
         (mouseleave)="leaveDocs()">
        <pm-frame *ngIf="value$ | async as value">
            <div class="Docs-Title">
                {{ value.title }}
            </div>
            <div>
                <div [innerHTML]="value.firstParagraph" class="Padding Docs-Paragraph"></div>
                <div class="Padding TextAlignCenter">
                    <a class="Docs-Intro--Link " [href]="docsHref + value.path" (click)="handleDialog($event, docsHref + value.path)"> {{ readMore }} </a>
                </div>
            </div>
        </pm-frame>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class DocsOverlayComponent implements OnInit, OnDestroy {
    @ViewChild('el') el: ElementRef;

    cache: any = {};

    type: string;
    value$: Observable<DocsIdValues>;
    destroyed$: Subject<void> = new Subject<void>();
    readMore: string;
    dialogText: string;
    hideTimeout: any;
    isValue: boolean;

    docsHref: string = 'https://doc.proman.app/p/';

    constructor(
        private Request: RequestService,
        private Overlay: OverlayService,
        private Render2: Renderer2,
        private cd: ChangeDetectorRef,
        private Filter: FilterService,
        private Dialog: Dialog,
    ) {
        if (window.location.host.includes('prochef.app')) {
            this.docsHref =  'https://doc.proman.app/p/';
        }
        if (window.location.host.includes('smarton.app')) {
            this.docsHref =  'https://doc.proman.app/p/';
        }
        if (window.location.host.includes('proman.app')) {
            this.docsHref =  'https://doc.proman.app/p/';
        }
    }

    ngOnInit() {
        this.Overlay.docShow.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged())
            .subscribe((value: any) => value ? this.show() : this.hide());

        this.value$ = this.Overlay.docValues.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            tap((value) => {
                this.isValue = !!value;
            })
        );

    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    async show() {
        this.readMore = this.Filter.translate('read_more');
        this.dialogText = this.Filter.translate('open_in_dialog');

        await this.Render2.removeClass(this.el.nativeElement, 'DisplayNone');

        this.setPosition();

        this.cd.markForCheck();
    }

    setPosition = () => {
        if (!this.el) return;

        setTimeout(() => { // timeout to load image before repositioning
            this.Render2.setStyle(this.el.nativeElement, 'left', this.Overlay.getPos(this.el, 0));
            this.Render2.setStyle(this.el.nativeElement, 'top', this.Overlay.getPos(this.el, 1));

            this.cd.markForCheck();
        });
    };

    hide() {
        if (!this.el) return;

        this.Render2.addClass(this.el.nativeElement, 'DisplayNone');

        this.Render2.setStyle(this.el.nativeElement, 'top', '-999999px');
        this.Render2.setStyle(this.el.nativeElement, 'left', '-999999px');

        this.cd.markForCheck();
    }

    enterDocs() {
        this.Overlay.docsSetActive(true);

        clearTimeout(this.hideTimeout);
    }

    leaveDocs() {
        this.Overlay.docsSetActive(false);
        this.Overlay.docValues.next(null);

        this.hideTimeout = setTimeout(() => {
            this.Overlay.docsHide();
            this.hide();
        }, 250);

    }

    handleDialog(event: MouseEvent, url: string) {
        event.preventDefault();
        this.Dialog.open(IframeDialogComponent, { url }, { width: '80%' })

    }

}
