import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PromanStateService } from '../services/proman-state.service';
import { EntityNameType } from '@proman/services/entity.service';
import { TagType } from '@proman/resources/tag';

@Component({
    selector: 'pm-navigation-header',
    template: `
        <div class="NavigationHeader"
             fxLayout="row">
            <div class="NavigationHeader-wrapper"
                 fxLayout="column"
                 fxFlex>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center">
                    <pro-btn
                            *ngIf="backButton"
                            (onClick)="handleBack()"
                            icon="list"
                            theme="primary"
                            [tooltip]="'go_back' | translate"
                    ></pro-btn>
                    <div class="NavigationHeader-Title"
                         fxLayout="column">
                        <h5 [title]="headerText">
                            <span>{{ headerText }}</span>
                            <span *ngIf="headerBrackets">({{ headerBrackets }})</span>
                        </h5>
                        <div fxLayout="row"
                             *ngIf="headerTextSub || dynamicStatus">
                            <div class="NavigationHeader-Subheader"
                                 *ngIf="headerTextSub">{{ headerTextSub }}
                            </div>
                        </div>
                    </div>
                    <div fxFlex></div>
                    <ng-content select=".navigation-header-right-buttons"></ng-content>
                </div>
                <div class="NavigationHeader-Toolbar"
                     fxLayout="row">
                    <pro-tags *ngIf="tagItem && entityName && hasTags"
                             [item]="tagItem"
                             [config]="{ entity: entityName, tagType: tagType }"></pro-tags>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" pmPadding>
                <ng-content select=".navigation-header-menu-buttons" ></ng-content>
            </div>
        </div>


    `,
    styles: [`
        .NavigationHeader { background: rgb(224, 224, 224); }
        h5 { font-size: 24px; font-weight: 300; line-height: 32px; margin: 4px 0; }
    `]
})

export class NavigationHeaderComponent {
    @Input() headerText: string;
    @Input() headerTextSub: string;
    @Input() headerBrackets: string;
    @Input() dynamicStatus: string;
    @Input() backButton: string;
    @Input() tagItem: any;
    @Input() entityName: EntityNameType;
    @Input() tagType: TagType;
    @Input() hasTags: boolean;
    @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private promanState: PromanStateService
    ) {

    }

    handleBack() {
        this.promanState.to(this.backButton);
    }

}
