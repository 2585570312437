import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolbarService } from '../services/toolbar.service';
import { Action, TabState } from '@proman/interfaces/object-interfaces';
import { isDefinedNotNull } from "@proman/utils";
import { TagType } from '@proman/resources/tag';

/**
 * `pmToolbarDriver` component
 *
 * Attributes:
 * title
 * subtitle
 * entityName
 * actions
 * entity
 * tabs
 * parentState
 * parentStateFilter
 * hideBacklink
 * hasTags
 * hasColor
 *
 */

@Component({
    selector: 'pm-toolbar-driver',
    template: ``
})

export class ToolbarDriverComponent implements OnInit, OnChanges{
    @Input() item: any;
    @Input() entity: any;
    @Input() actions: Action[];
    @Input() actionsEditable: boolean;
    @Input() title: any;
    @Input() subtitle: any;
    @Input() subtitleIcon: any;
    @Input() subtitleWarn: boolean;
    @Input() entityName: any;
    @Input() tagType: TagType;
    @Input() parentState: any;
    @Input() parentStateFilter: (state: TabState) => boolean;
    @Input() additionalState: any;
    @Input() hideBacklink: any;
    @Input() timeStamp: number;
    @Input() hasColor: boolean;
    @Input() hasTags: boolean;
    @Input() backlink: { name: string; state: string };

    constructor(private Toolbar: ToolbarService) {

    }

    ngOnInit() {
        const props = [
            'item',
            'entity',
            'actions',
            'actionsEditable',
            'title',
            'subtitle',
            'subtitleIcon',
            'subtitleWarn',
            'entityName',
            'tagType',
            'parentState',
            'parentStateFilter',
            'additionalState',
            'hideBacklink',
            'hasColor',
            'hasTags',
            'backlink',
        ];

        this.Toolbar.reset();

        for (const prop of props) {
          if (isDefinedNotNull(prop)) {
            this.Toolbar[prop] = this[prop];
          }
        }

        this.Toolbar.update();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const key in changes) {

            if (!changes[key].isFirstChange()) {
                this.setThing(key, changes[key]);

            }

        }

        this.Toolbar.update();
    }

    setThing(prop: any, thing: any) {
        this.Toolbar[prop] = thing.currentValue;

    }
}
