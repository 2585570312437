import { Injectable } from '@angular/core';
import { Article, Material } from '@proman/interfaces/entity-interfaces';
import { BehaviorSubject } from 'rxjs';
import { pushArrays } from '@proman/utils';
import { ACL } from '@proman/services/acl.service';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { TableDialogComponent } from '@proman/table';

@Injectable({
    providedIn: 'root'
})
export class ArticlesService {
    currentMaterialSummary: BehaviorSubject<Material[]> = new BehaviorSubject<Material[]>([]);

    constructor(
      private Entity: Entity,
      private Dialog: Dialog,
      private ACL: ACL
    ) {

    }

    initMaterials() {
        this.currentMaterialSummary.next([]);
    }

    addMaterials(materials: Material[]) {
        let item = this.currentMaterialSummary;
        item.next(pushArrays(item.value, materials));
    }

    isChild(article: Article) {
        return this.Entity.get('article_child').search({ 'child.id': article.id, 'parent.id': `!${article.id}`, join: ['parent', 'child'] }).then((response) => {
            return response.length > 0;
        })
    }

    displayParents = (article: Article) => {
        this.Dialog.open(TableDialogComponent, {
            header: `article_${article.id}_parents`,
            tableId: `article_${article.id}_parents`,
            config: {
                entity: 'article_child',
                aclRoot: 'article',
                addButton: null,
                hideSettings: true,
                fields: [
                    {
                        name: 'name',
                        key: 'parent.name',
                        state: {
                            name: 'Article',
                            key: 'articleId',
                            id: 'parent.id',
                        }
                    }
                ],
                extraParameters: {
                    join: ['parent', 'child'],
                    'child.id': article.id,
                    'parent.id': `!${article.id}`,
                }
            }
        })
    }

    public canEditArticle(artcle: Article): boolean {
        return !artcle.confirmed || this.ACL.check('article.master');
    }

}
