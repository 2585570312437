import { Component, Input } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { ArticleSummaryDialogComponent } from './article-summary-dialog.component';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-article-summary-btn',
    template: `
    <pro-btn  *ngIf="ACL.check('article.edit')"
            (click)="showArticleSummary()"
            icon="question-circle"
            theme="green"
            [tooltip]="'show_article_summary' | translate"
    ></pro-btn>
`
})

export class ArticleSummaryBtnComponent {
    @Input() article: any;
    articleEntity: any;

    constructor(private Entity: Entity,
                private Dialog: Dialog,
                public ACL: ACL) {
        this.articleEntity = Entity.get('article');
    }

    showDialog() {
        this.Dialog.open2(ArticleSummaryDialogComponent, { item: this.article });
    }

    showArticleSummary = () => {
        let article = this.article;

        article && article.type && article.photo ? this.showDialog() : this.loadArticleData();
    };

    loadArticleData() {
        if(this.article) {
            this.articleEntity
                .get({id: this.article.id, join: ['technology', 'photo']})
                .then((response: any) => {
                    this.article = response;
                    this.showDialog()
                });
        }
    }
}
