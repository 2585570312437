import { forkJoin as observableForkJoin } from 'rxjs';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Template } from '@proman/interfaces/entity-interfaces';
import { arraymove, debounce, deepCopy, isArray } from '@proman/utils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'pm-elements-builder',
    template: `
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="rawElements">
            <div fxLayout="column" class="ElementsList"  fxFlex>
                <ng-container *ngIf="preview">
                    <pro-label class="ElementsBox">{{ 'preview' | translate }}</pro-label>
                    <div [innerHTML]="preview | safeHTML"></div>
                </ng-container>
            </div>
<!--            droppable (onDrop)="handleDrop($event)"-->
            <div fxLayout="column"
                 class="ElementsList"
                 id="result"
                 fxFlex
                 *ngIf="isView"
                 cdkDropList
                 cdkDropD
                 (cdkDropListDropped)="handleCdkDrop($event)"
            >
                <pro-label class="ElementItem" [attr.data-index]="0">{{ 'elements' | translate }}</pro-label>
                <div *ngFor="let item of elementValues; let $index = index;"
                     class="ElementItem"
                     draggable
                     [dragData]="item"
                     cdkDrag
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     fxFlex
                     [attr.data-index]="$index">

                    <ng-container *ngFor="let value of item[1] | proKeys"
                                  [ngSwitch]="getType(item[0], value)">

                        <div fxLayout="column" class="Width100" *ngSwitchCase="'string'">
                            <div #boxes
                                 contenteditable="true"
                                 class="DivContentEditable BottomMargin"
                                 (click)="setActiveIndex($index)"
                                 [innerHTML]="getFormattedHTML(item[1])"> </div>

                            <div class="Padding" *ngIf="activeInput === $index">
                                <pm-txt [value]="value.value"
                                        [config]="{ label: value.key, type: 'textarea', autofocus: true }"
                                        (onChange)="setElementValue(item, value.key, $event)"
                                        fxFlex></pm-txt>
                            </div>

                        </div>

                        <pro-select *ngSwitchCase="'select'"
                                [value]="value.value"
                                [config]="{ label: value.key, key: 'id' }"
                                [options]="getOptions(item[0], value)"
                                (onChange)="setElementValue(item, value.key, $event)" fxFlex></pro-select>

                    </ng-container>

                    <div fxLayout="column">
                        <pro-move-handle cdkDragHandle></pro-move-handle>
                        <pro-btn icon="times" (onClick)="removeElement($index)" theme="warn"></pro-btn>

                    </div>

                </div>

            </div>

            <div fxLayout="column" class="ElementsList" pmNoPadding  fxFlex="200px"
                 cdkDropList
                 cdkDropListConnectedTo="result"
                 >
                <div *ngFor="let element of elements"
                     class="ElementsBox"
                     draggable
                     cdkDrag

                     [cdkDragData]="element"
                >{{ element.className }}</div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        :host {
            width: 100%;
        }
        .ElementsList {
            /*width: 500px;*/
            max-width: 100%;
            border: solid 1px #ccc;
            display: block;
            background: white;
            border-radius: 4px;
        }

        .ElementsBox {
            padding: 20px 10px;
            border-bottom: solid 1px #ccc;
            color: rgba(0, 0, 0, 0.87);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            background: white;
            font-size: 14px;
        }
        .ElementItem:not(:last-child) {
            border-bottom: solid 1px #ccc;
            margin-bottom: 1.5rem;
        }

        .DivContentEditable {
            width: 100%;
        }

    `]
})

export class ElementsBuilderComponent implements OnInit {
    @Input('elements') inputElements: any[];
    @Input() template: Template;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    elementValues: any[];
    elements: any[];
    rawElements: any[];
    elementEntity: any;
    preview: any;
    updateDebounce:  () => void;
    activeInput: number;
    isView: boolean = true;

    constructor(
        private Entity: Entity,
        private cd: ChangeDetectorRef,
    ) {
        this.elementEntity = this.Entity.get('element');
    }

    ngOnInit() {

        this.getElements()
            .then(() => {
                this.elementValues = this.inputElements || [];

                if (this.elementValues.length) this.generateElementsPreview();
            });

        this.updateDebounce = debounce(() => this.updateElements(), 500);

        this.cd.markForCheck();

    }

    getElements = () => {

        return this.elementEntity.list()
            .then((response: any) => {

                let elements: any[] = [];

                for (let className in response) {
                    let item = response[className];

                    item.options = {};

                    let keys = Object.keys(item.configuration);

                    item.keys = keys;

                    elements.push({ class: className, keys, config: item, className: className.substring(className.lastIndexOf('\\') + 1) });

                    for (let configKey in item.configuration) {

                        if (item.configuration[configKey].type === 'select') {
                            let data = item.configuration[configKey].data;
                            item.options[configKey] = [];

                            for (let optionsKey in data) {
                                item.options[configKey].push({ id: optionsKey, name: data[optionsKey] });
                            }

                        }
                    }

                    this.elements = elements;

                    this.cd.markForCheck();

                }

                this.rawElements = response;

                return Promise.resolve();

            })
    };

    // old implementation
   /* handleDrop(data: any) {

        if (isArray(data.dragData)) {
            let target = data.nativeEvent.target as HTMLElement;
            let dropOnTop = false;

            let foo = target;
            let index;

            while (!(foo.classList.contains('ElementsList') || dropOnTop)) {

                if (foo.classList.contains('ElementItem')) {
                    dropOnTop = true;
                    index = +foo.getAttribute('data-index');

                } else {
                    foo = foo.parentNode as HTMLElement;

                }

            }

            let currentIndex = this.elementValues.indexOf(data.dragData);

            if (currentIndex > -1) {
                let item = this.elementValues.splice(currentIndex, 1)[0];

                this.elementValues.splice(index, 0, item);

            }

        } else {
            let item = data.dragData;
            let element = [item.class, { }];

            item.keys.forEach((key: string) => element[1][key] = this.rawElements[item.class].configuration[key].default);

            this.elementValues.push(element);

        }

        this.updateElements();

        this.generateElementsPreview();

    }*/

    getType(className: string, item: any) {
        return this.rawElements[className]?.configuration[item.key].type;
    }

    getOptions(className: string, item: any) {
        return this.rawElements[className].options[item.key];
    }

    setElementValue(item: any, key: string, value: any) {
        item[1][key] = value;

        this.updateDebounce();
        this.updateElements();
        this.updateView();
    }

    removeElement(index: number) {
        this.elementValues.splice(index, 1);

        this.updateElements();
    }

    updateElements() {
        let request: any = [];

        this.elementValues.forEach((item: any) => {
            request.push({ 0: item[0], 1: item[1] });
        });

        this.onChange.emit(this.elementValues);

        // this.model.update('elements', request);

        this.generateElementsPreview();
    }

    generateElementsPreview = async () => {

        if (this.template) {
            (this.Entity.get('template') as any)
                .render({id: this.template.id})
                .then((response: any) => this.preview = response);
            await this.updateView();
        } else {
            let requests: any[] = [];
            this.elementValues.forEach((item: any) => requests.push({
                output: 'raw',
                configuration: item[1],
                class: item[0]
            }));
            observableForkJoin(requests.map((r: any) => this.elementEntity.render(r)))
                .toPromise()
                .then((values: any[]) => this.preview = values.join('<br>'));
            await this.updateView();
        }

    };

    formatStyle(text: string) {
        if (!text) return '';

        const words = text.split(' ');
        let newHTML = '';

        words.forEach((value) => {
            switch (value.toUpperCase()){
                case 'SELECT':
                case 'LIKE':
                case 'BETWEEN':
                case 'NOT LIKE':
                case 'FALSE':
                case 'IS':
                case 'NULL':
                case 'TRUE':
                case 'NOT IN':
                case 'AND':
                case 'ON':
                case 'FORMAT':
                case 'AS':
                    newHTML += `<span class="ColorPurple FontBold">${value}</span> `;
                    break;

                case 'FROM':
                case 'GROUP BY':
                case 'WHERE':
                case 'HAVING':
                    newHTML += `<br/></bt><span class="ColorPurple FontBold">${value}</span> `;
                    break;

                default:
                    newHTML += `${value} `;
            }
        });

        const funcs = [
            'GROUP BY',
            'DATE_FORMAT',
            'DATE_FORMAT',
            'IFNULL',
        ];

        const funcsNewLine = [
            'LEFT JOIN',
            'RIGHT JOIN',
            'INNER JOIN',
            'GROUP BY',
        ];

        funcs.forEach((f) => newHTML = newHTML.split(f).join(`<span class="ColorPurple FontBold">${f}</span> `));

        funcsNewLine.forEach((f) => newHTML = newHTML.split(f).join(`<br><span class="ColorPurple FontBold">${f}</span> `));

        function replaceFn(match: string, offset: number, string: string) {
            return `<span class="ColorOrange">${match}</span>`;
        }

        newHTML = newHTML.replace(/'.*?'/g, replaceFn);

        return newHTML;
    }

    getFormattedHTML(item: any) {
        let key = Object.keys(item)[0];

        return this.formatStyle(item[key]);
    }

    updateView = () => {
        this.cd.markForCheck();
    }

    setActiveIndex(index: number) {
        this.isView = false;

        setTimeout(() => { // this is used to reload info from proKeys pipe
            this.isView = true;
            this.activeInput = index;

            this.updateView();
        });

    }

    handleCdkDrop(data: CdkDragDrop<any>) {
        console.log('handleCdkDrop', data);

        if (data.container.id === 'result') {
            // reorder
            if (data.container === data.previousContainer) {
                arraymove(this.elementValues, data.previousIndex, data.currentIndex);

            } else {
                console.log('new item', data);
                const item = deepCopy(this.elements[data.previousIndex]);
                console.log(' item', item);

                // let item = data.dragData;
                const element = [item.class, { }];

                item.keys.forEach((key: string) => element[1][key] = this.rawElements[item.class].configuration[key].default);

                this.elementValues.push(element);
            }

            this.updateElements();

            this.generateElementsPreview();


        }



    }

}
