import { NgModule } from '@angular/core';
import { RadioGroupComponent } from '@frontend/shared/components/radio-group.component';
import { RadioInputDialogComponent } from '@frontend/shared/components/radio-input-dialog.component';
import { SimpleFormFieldComponent } from '@frontend/shared/components/simple-form-field.component';
import { PromanDurationDropdownComponent } from '@proman/datepicker/proman-duration-dropdown.component';
import { ElementsBuilderComponent } from '@frontend/shared/components/elements-builder.component';
import { PromanFilesManagerComponent } from '@proman/files-manager/proman-files-manager.component';
import { UploadComponent } from '@frontend/shared/components/upload.component';
import { UploadEntityPhotoComponent } from '@frontend/shared/components/upload-entity-photo.component';
import { PhoneComponent } from '@frontend/shared/components/phone.component';
import { ClickToCallComponent } from '@frontend/shared/components/click-to-call.component';
import { ProgressBarComponent } from '@frontend/shared/components/progress-bar.component';
import { ToolbarDriverComponent } from '@frontend/shared/components/toolbar-driver.component';
import { NavigationHeaderComponent } from '@frontend/shared/components/navigation-header.component';
import { LanguageSwitchComponent } from '@frontend/shared/components/language-switch.component';
import { UserThumbComponent } from '@frontend/shared/components/user-thumb.component';
import { TimelineChatComponent } from '@frontend/shared/components/timeline-chat.component';
import { ProgressComponent } from '@frontend/shared/components/progress.component';
import { TagsComponent } from '@proman/tags/tags.component';
import { ImageComponent } from '@frontend/shared/components/image.component';
import { ContactsManagerComponent } from '@frontend/shared/components/contacts-manager.component';
import { UserContactsCardComponent } from '@frontend/shared/components/user-contacts-card.component';
import { UpdateDataComponent } from '@frontend/shared/components/update-data.component';
import { BottomToolbarComponent } from '@frontend/shared/components/bottom-toolbar.component';
import { ActionsComponent } from '@frontend/shared/components/actions.component';
import { FrameComponent } from '@frontend/shared/components/frame.component';
import { ContentComponent } from '@frontend/shared/components/content.component';
import { BarcodeComponent } from '../printers/components/barcode.component';
import { UpdateComponent } from '@frontend/shared/components/update.component';
import { DynamicFieldsComponent } from '@frontend/shared/components/dynamic-fields.component';
import { LooseRelationComponent } from '../system/components/loose-relation.component';
import { IconSelectComponent } from '@frontend/shared/components/icon-select.component';
import { ErrorsComponent } from '@frontend/shared/components/errors.component';
import { BugTrackerDialogComponent } from '@frontend/shared/components/bug-tracker-dialog.component';
import { UsersSwitcherDialogComponent } from '../employees/components/users-switcher-dialog.component';
import { CountryRegionsComponent } from '@frontend/shared/components/country-regions.component';
import { TreeSelectComponent } from '@frontend/shared/components/tree-select.component';
import { EntityParentCategoryRecursiveComponent } from '@frontend/shared/components/entity-parent-category-recursive.component';
import { EntityParentCategoriesComponent } from '@frontend/shared/components/entity-parent-categories.component';
import { CategoryRecursiveComponent } from '@frontend/shared/components/category-recursive.component';
import { CreateCategoryDialogComponent } from '@frontend/shared/components/create-category-dialog.component';
import { CreateTaxTypeDialogComponent } from '@frontend/shared/components/create-tax-type-dialog.component';
import { ProductsTableComponent } from '../products/components/products-table.component';
import { EntityValidationErrorsComponent } from '@frontend/shared/components/entity-validation-errors.component';
import { EventParametersComponent } from '../events/components/event-parameters.component';
import { TabsComponent } from '@frontend/shared/components/tabs.component';
import { PermissionsComponent } from '../roles/components/permissions.component';
import { PromanQrReaderComponent } from '@proman/qr-reader/proman-qr-reader.component';
import { EmployeeDocumentsReadComponent } from '../employees/components/employee-documents-read.component';
import { EntityActivityLogComponent } from '@frontend/shared/components/entity-activity-log.component';
import { CreditMaterialsComponent } from '@frontend/shared/components/credit-materials.component';
import { InlineCommentsComponent } from '@frontend/shared/components/inline-comments.component';
import { EventOrderProductsTableComponent } from '../events/components/event-order-products-table.component';
import { ArticleSummaryBtnComponent } from '../articles/components/article-summary-btn.component';
import { UserMenuTreeComponent } from '../employees/components/user-menu-tree.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { InputsModule } from '../inputs/inputs.module';
import { RouterModule } from '@angular/router';
import { OverlayComponent } from '@frontend/shared/components/overlay.component';
import { DocsOverlayComponent } from '@frontend/shared/components/docs-overlay.component';
import { CalendarOverlayFilesComponent } from '../events/components/calendar-overlay-files.component';
import { ArticleCommentStatusComponent } from '../articles/components/article-comment-status.component';
import { OrderProductShipmentCellComponent } from '../orders/components/order-product-shipment-cell.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { WorkshiftsColorsComponent } from '../employees/components/workshifts-colors.component';
import { ClipboardCopyBtnComponent } from '@frontend/shared/components/clipboard-copy-btn.component';
import { ClipboardDirective } from '@frontend/shared/directives/clipboard.directive';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { AccountCategoryTypeBtnComponent } from '../accounting/components/account-category-type-btn.component';
import { ConfigFormFieldComponent } from '@frontend/shared/components/config-form-field.component';
import { ChangeUsernameFormComponent } from '@frontend/shared/components/change-username-form.component';
import { CorporatePanelComponent } from '@frontend/shared/components/corporate-panel.component';
import { FrontendPipesModule } from '@frontend/shared/pipes/frontend-pipes.module';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { Fa6Module } from '@proman/fa/fa6.module';
import { FrontendTableModule } from '../table/frontend-table.module';
import { ParameterViewComponentModule } from '../parameters/parameter-view-component.module';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { AccountingPanelComponent } from "@frontend/shared/components/accounting-panel.component";
import { CreateLedgerTypeDialogComponent } from '@frontend/shared/components/create-ledger-type-dialog.component';
import { FrontendParametersModule } from '@frontend/frontend-parameters/frontend-parameters.module';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { CropperDialogComponent } from "@proman/cropper/cropper-dialog.component";
import { PromanThumbnailComponent } from '@proman/common-components/components/proman-thumbnail.component';
import { PromanWarningComponent } from '@proman/common-components/components/warning.component';
import { FrontendDirectivesModule } from '@frontend/shared/frontend-directives.module';

const COMPONENTS = [
    RadioGroupComponent,
    RadioInputDialogComponent,
    SimpleFormFieldComponent,
    ConfigFormFieldComponent,
    ElementsBuilderComponent,
    UploadComponent,
    UploadEntityPhotoComponent,

    PhoneComponent,
    ClickToCallComponent, // phone component

    ProgressBarComponent,
    ToolbarDriverComponent,
    NavigationHeaderComponent,
    LanguageSwitchComponent,
    UserThumbComponent,
    TimelineChatComponent,
    ProgressComponent,

    ImageComponent,
    ContactsManagerComponent,
    UserContactsCardComponent, // used in contacts component
    UpdateDataComponent,
    BottomToolbarComponent,
    ActionsComponent,
    FrameComponent,
    ContentComponent,
    BarcodeComponent,

    UpdateComponent,

    DynamicFieldsComponent,
    LooseRelationComponent,
    IconSelectComponent,
    ErrorsComponent,

    // DefaultRouteComponent, // to app
    BugTrackerDialogComponent, // to app / account component
    UsersSwitcherDialogComponent, // too app / account

    CountryRegionsComponent, // table cell ??

    TreeSelectComponent, // categories module
    EntityParentCategoryRecursiveComponent, // categories module
    EntityParentCategoriesComponent, // categories module
    CategoryRecursiveComponent,  // categories module
    CreateCategoryDialogComponent,
    CreateTaxTypeDialogComponent,
    CreateLedgerTypeDialogComponent,
    ProductsTableComponent, // production create / event dialog / event.overlay

    EntityValidationErrorsComponent,
    EventParametersComponent,
    TabsComponent,
    PermissionsComponent,

    EmployeeDocumentsReadComponent,

    EntityActivityLogComponent,
    CreditMaterialsComponent,

    InlineCommentsComponent, // event dialog
    EventOrderProductsTableComponent, // event dialog
    ArticleSummaryBtnComponent, // event dialog / production create

    UserMenuTreeComponent, // resources ? / system settings

    OverlayComponent, // to app
    DocsOverlayComponent, // to app
    CalendarOverlayFilesComponent, // overlay

    ArticleCommentStatusComponent,
    OrderProductShipmentCellComponent,
    WorkshiftsColorsComponent, // employee schedule / workshift colors dialog

    ClipboardCopyBtnComponent,
    AccountCategoryTypeBtnComponent,
    ChangeUsernameFormComponent,
    CorporatePanelComponent,
    AccountingPanelComponent,
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        RouterModule,
        InputsModule,
        SharedDirectivesModule,
        PromanCommonComponentsModule,
        FrontendTableModule,
        MatRadioModule,
        MatLegacyButtonModule,
        MatLegacyListModule,
        MatLegacyProgressBarModule,
        MatLegacyMenuModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
        Fa6Module,
        FrontendParametersModule,
        FrontendDirectivesModule,
        PromanAutocompleteComponent,
        ParameterViewComponentModule,
        FrontendPipesModule,
        PromanColorComponent,
        CdkDrag,
        CdkDropList,
        CdkDragPlaceholder,
        PromanDurationDropdownComponent,
        CropperDialogComponent,
        PromanFilesManagerComponent,
        TagsComponent,
        PromanThumbnailComponent,
        PromanWarningComponent,
        PromanQrReaderComponent,

    ],
    providers: [],
    declarations: [...COMPONENTS, ClipboardDirective],
    exports: [
        COMPONENTS,
        ClipboardDirective,
        PromanDurationDropdownComponent,
        PromanFilesManagerComponent,
        TagsComponent,
        PromanQrReaderComponent
    ]
})

export class SharedComponentsModule {}
