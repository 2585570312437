import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CONFIG } from '@proman/config';
import { RequestService } from '@proman/services/request.service';

@Component({
    selector: 'pm-update',
    template: `
        <div fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner [color]="'accent'"></mat-spinner>
<!--            <h3>{{ 'system_update' | translate }}</h3>-->
        </div>
    `,
    styles: [
        ':host { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; z-index: 9999; background-color: rgba(255, 255, 255, 0.95); }'
    ]
})

export class UpdateComponent implements OnInit {

    constructor(
        private Request: RequestService,
        private cd: ChangeDetectorRef,
    ) {
        this.cd.markForCheck();
    }

    ngOnInit() {
        this.refresh();

        this.cd.markForCheck();
    }

    refresh = () => {
        const reload = () => setTimeout(() => {
            this.refresh();

        }, 5000);

        this.Request.get(`${CONFIG.root}ping`)
            .then((response: any) => {

                response.deploy ?
                    reload() :
                    window.location.reload();

            })
            .catch(() => reload());
    };

}
