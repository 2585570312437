import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { mapOptionTranslate, prepareRequest } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';
import { MATERIAL_STOCK } from '@proman/resources/material';
import {AccountCategory} from "@proman/interfaces/entity-interfaces";
import { ToastService } from '@proman/services/toast.service';

@Component({
    selector: 'pm-category-create-dialog',
    template: `
        <pro-dialog-title [title]="header"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-txt [value]="category.name"
                    [config]="{ label: 'name', required: true }"
                    (onChange)="set('name', $event)"></pm-txt>
            <pm-tree-select
                    [value]="category.parent"
                    [config]="treeSelectConfig"
                    [options]="parents"
                    (onChange)="set('parent', $event)"></pm-tree-select>
            <div *ngIf="entityName === 'account_category'">
                <pm-txt [value]="category.keyName"
                        [config]="{ label: 'key', debounce: 0, required: true, validators: { unique: { resource: entityName, field: 'keyName' } } }"
                        (onChange)="set('keyName', $event)"></pm-txt>
                <pm-txt [value]="category.description"
                        [config]="{ label: 'description' }"
                        (onChange)="set('description', $event)"></pm-txt>
                <pro-select [value]="category.type"
                        [config]="{ label: 'type', key: 'id' }"
                        [options]="typeOptions"
                        (onChange)="set('type', $event)"></pro-select>
            </div>

        </div>
        <pro-dialog-actions (callback)="confirm()" variant="confirm" [isCallbackDisabled]="!category.name"></pro-dialog-actions>
    `
})

export class CreateCategoryDialogComponent {
    entityName: EntityNameType;
    entity: any;
    treeSelectConfig: any;
    parents: any;
    category: any = {};
    header: string;
    typeOptions: any[];
    metadataOptions: any[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Toast: ToastService,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<CreateCategoryDialogComponent>
    ) {
        this.entityName = data.entity;
        this.entity = Entity.get({ name: this.entityName as EntityNameType });
        this.treeSelectConfig = { label: 'parent_category', disabled: () => false, isNone: true };

        if (this.entityName === 'account_category') {
            this.typeOptions = ['D', 'C'].map((type: string) => mapOptionTranslate(type, this.Filter.translate));
            this.category.parent = data.parent || null;
            if (this.category.entity && this.category.entityField) {
                this.getMetadataFields();
            }
        }

        this.loadCategories();

        this.setHeader();
    }

    set = (property: string, value: any) => {
        this.category[property] = value;
    };

    setEntity = (entity: string) => {
        this.category.entity = null;
        setTimeout(() => {
            this.set('entity', entity);
            this.getMetadataFields();
        });

    };

    confirm = () => {
        let category: any = this.category;
        let data: any = prepareRequest(category);

        if (this.data.entity === 'account_category') {
            data.rootParent = category.parent ? (category.parent.root || category.parent.id) : null;

            if (!category.keyName) {
                this.Toast.pop('error', 'error.illegal_argument');
                return;
            }

        }

        this.handleMaterialCategoryParams(data);
        this.entity.create(data).then((response: any) => this.dialogRef.close(response.data));
    };

    loadCategories = () => {
        let params: any = { _treeSort: true };
        this.handleMaterialCategoryParams(params);
        this.entity.search(params).then((data: any) => {
            this.parents = data;
            this.parents.map((category: AccountCategory) => category.name = this.getOptionName(category));
        });
    };

    getOptionName = (option: AccountCategory) => option.keyName ? `${option.keyName}  -  ${option.name}` : option.name;

    handleMaterialCategoryParams(params: any) {
        if (this.data.entity === 'material_category') {
            params.stock = this.data.inventory ? MATERIAL_STOCK.INVENTORY : MATERIAL_STOCK.MATERIAL;

        }
    }

    setHeader() {

        switch (this.data.entity) {

            case 'article_category':
                this.header = 'new_article_category';
                break;

            case 'material_category':
                this.header = 'new_material_category';
                break;

            case 'account_category':
                this.header = 'new_account_category';
                break;

            default:
                this.header = 'create'

        }

    }

    getMetadataFields = () => {
        this.Entity.get('public/metadata')
            .customizedRequest(this.category.entity)
            .then((response: { api: any; entity: { [key: string]: any } }) => {
                this.metadataOptions = [];
                for (let id of Object.keys(response.entity)) {
                    const name = this.Filter.translate(id);
                    this.metadataOptions.push({ id, name });
                }
            })
    };

}
