import { Component } from '@angular/core';
import { upperFirst } from 'lodash';
import { Entity } from '@proman/services/entity.service';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { ACL } from '@proman/services/acl.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';

@Component({
    selector: 'pm-barcode',
    template: `
        <div fxLayout="column" fxFlex="" *ngIf="canView">
            <pro-select *ngIf="printers.length"
                    [value]="printers.length===1?printers[0]:printer"
                    [config]="{ label: 'printer' }"
                    [options]="printers"
                    (onChange)="set('printer', $event)"></pro-select>
            <pro-select *ngIf="templates.length"
                    [value]="templates.length===1?templates[0]:template"
                    [config]="{ label: 'barcode_template' }"
                    [options]="templates"
                    (onChange)="set('template', $event)"></pro-select>
        </div>
    `,
    styles: [`
        div { width: 100%; }
        pro-select { min-width: 140px; }
    `]
})

export class BarcodeComponent {
    printerEntity: any;
    templateEntity: any;

    printers: any = [];
    templates: any = [];

    printer: any;
    printerId: any;
    template: any;
    templateId: any;

    canView: boolean;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
        private LocalStorage: LocalStorageService,
        private QueryExpression: QueryExpressionService,
        private ParametersOptions: ParametersOptionsService,
    ) {
        this.printerEntity = this.Entity.get('printer');
        this.templateEntity = this.Entity.get('template');

        this.canView = this.ACL.check('printer.view');

        if (this.canView) {
            this.ParametersOptions
                .search({ entity: 'device', entityParams: { type: 'ZplPrinter' } })
                .then((response: any) => {
                    response.forEach((item: any) => item.name = item.configuration && item.configuration.name);

                    this.handleData('printer', response);
                });

            this.ParametersOptions
                .search({ entity: 'template', entityParams: { type: this.QueryExpression.in(['zpl', 'zpl2']) } })
                .then((response: any) => this.handleData('template', response));

        }

    }

    handleData = (prop: string, data: any[]) => {
        let savedId = this.LocalStorage.get('barcode' + upperFirst(prop));
        let found = false;
console.log('printers',this.printers)
        this[prop + 's'] = data;

        if (savedId) {
            data.forEach((item: any) => {
                if (item.id === parseInt(savedId)) {
                    this.setData(prop, item);
                    found = true;

                }

            });

        }

        if (!found && data.length) this.setData(prop, data);

    };

    setData(prop: string, item: any) {
        this[prop] = item;
        this[prop + 'Id'] = item.id;
    }

    set(prop: any, value: any) {
        this.setData(prop, value);
        this.LocalStorage.set('barcode' + upperFirst(prop), value.id);
    }
}
