import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { deepCopy, findById } from '@proman/utils';
import { ParameterDropdownOption } from '@proman/interfaces/entity-interfaces';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';

@Component({
    selector: 'pm-parameter-dropdown-definition',
    template: `
       <pro-list-manager [items]="items"
                         [config]="{ silentRemove: true }"
                         (onAdd)="onAdd()"
                         (onEdit)="handleEdit($event.item)"
                         (onDrop)="handleDrop($event)"
                         (onRemove)="handleRemove($event)"
        ></pro-list-manager>

    `
})

export class ParameterDropdownDefinitionComponent implements OnInit {
    @Input() config: any;
    @Input() value: any;
    @Input() disabled: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    openEdit: number|null;

    items: any = [];
    parameterDropdownOptionEntity: any;

    constructor(
        Entity: Entity,
        private Dialog: Dialog,
        private cd: ChangeDetectorRef,
    ) {
        this.parameterDropdownOptionEntity = Entity.get('parameter_dropdown_option');
    }

    ngOnInit() {
        this.getItems();
    }

    getItems = () => {
        this.parameterDropdownOptionEntity
            .search({ 'parameter.id': this.config.parameter.id, 'translate': true, 'sort': { position: 'asc' } })
            .then((items: any[]) => {

                if (items) {
                    this.items = items.map((item: any) => {
                        item.value = item.name;
                        item.name = `#${item.id} - ${item.name}`;
                        return item;
                    });

                }

                if (this.openEdit) {
                    this.handleEdit(findById(this.items, this.openEdit));
                    this.openEdit = null;

                }

                this.cd.markForCheck();

            });

    };

    onAdd() {
        this.Dialog.open(InputDialogComponent, {
            variant: 'create',
            header: 'create',
        }).then((data: { name: string }) => {
                this.parameterDropdownOptionEntity
                    .create({
                        parameter: this.config.parameter.id,
                        name: data.name,
                        position: this.items.length
                    })
                    .then(() => this.getItems());
            });

    }

    onRemove($event: any) {
        this.items = $event.items;

        this.parameterDropdownOptionEntity
            .remove({ id: $event.item.id });
    }

    onUpdate($event: any) {
        this.items = $event.items;

        this.parameterDropdownOptionEntity
            .update({
                id: $event.item.id,
                name: $event.item.value,
                translate: true
            })
            .then(() => this.getItems());
    }

    handleEdit(item: ParameterDropdownOption) {
        item = deepCopy(item);
        item.name = item.value;

        this.Dialog.entityEdit('parameter_dropdown_option', item, {
            mainField: {
                key: 'name',
                name: 'name',
                config: {
                    required: true,
                    // validators: { unique : { resource: 'parameter_dropdown_option', field: 'name', data: { parameter: [this.config.parameter.id] } } }
                }
            }
        })
            .afterClosed()
            .subscribe(() => this.getItems());
    }

    handleRemove(data: any) {
        this.parameterDropdownOptionEntity
            .remove({ id: data.item.id })
            .then(() => this.getItems());
    }

    async handleDrop(data: any) {
        await setTimeout(() => {});

        this.parameterDropdownOptionEntity
            .reposition({
                'positionId': data.item.id,
                'positionAt': data.position,
                'parameter.id': this.config.parameter.id
            })
            .then(() => this.getItems());
    }

}
