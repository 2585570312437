import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AccountCategory } from "@proman/interfaces/entity-interfaces";

@Component({
  selector: 'pm-accounting-panel',
  template: `
      <div fxLayout="row" fxFlex>
          <pro-autoc [value]="value[config.keyCat1]"
                     [config]="{ label: config.label1, isNone: true, required: config.required, emptyCollections: ['childrens'], entity: 'account_category', searchFields: ['name', 'keyName'], getOptionName: this.getOptionName }"
                     (onChange)="set(config.keyCat1, $event)" fxFlex="70"></pro-autoc>
          <pm-account-category-type-btn [value]="value[config.keyType1]" (onChange)="set(config.keyType1, $event)"></pm-account-category-type-btn>
      </div>
    <div fxLayout="row" fxFlex>
        <pro-autoc [value]="value[config.keyCat2]"
                   [config]="{ label: config.label2, isNone: true, required: config.required, emptyCollections: ['childrens'], entity: 'account_category', searchFields: ['name', 'keyName'], getOptionName: this.getOptionName }"
                   (onChange)="set(config.keyCat2, $event)" fxFlex="70"></pro-autoc>
      <pm-account-category-type-btn [value]="value[config.keyType2]" (onChange)="set(config.keyType2, $event)"></pm-account-category-type-btn>
    </div>
  `
})

export class AccountingPanelComponent {
  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() config: { label1?: string; label2?: string; keyCat1?: string; keyCat2?: string; keyType1?: string; keyType2?: string; groupInput: true, required?: boolean } = { groupInput: true };
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  getOptionName = (option: AccountCategory) => `${option.keyName}  -  ${option.name}`;

  set = (property: string, value: any) => {
    this.value[property] = value;
    this.onChange.emit(this.value);
  }
}
