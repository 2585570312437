import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import {Entity, EntityInterface, EntityNameType} from '@proman/services/entity.service';
import {AccountCategory, Role} from "@proman/interfaces/entity-interfaces";
import {TaxEntityInterface} from "@proman/resources/tax";
import {FilterService} from "@proman/services/filter.service";
import { findById } from '@proman/utils';

@Component({
    selector: 'pm-tax-type-create-dialog',
    template: `
        <pro-dialog-title [title]="mode + ' Tax Type'"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-txt [value]="name"
                    [config]="{ label: 'name', required: true, validators: { unique: { resource: 'tax_types' } }  }"
                    (onChange)="name = $event"></pm-txt>
            <br>
            <pro-select [value]="type"
                        [config]="{ label: 'type', displayKey: 'name', isNone: true  }"
                        [options]="taxesTypesOptions"
                        (onChange)="type = $event">
            </pro-select>
            <pro-autoc *ngIf="type?.id === 'salary'"
                       [value]="role?.name"
                       (onChange)="role = $event"
                       [config]="{ label: 'department', entity: 'role', searchFields: ['id', 'name'], isNone: true }">
            </pro-autoc>
            <div fxLayout="row" fxFlex>
                <pro-autoc
                        [value]="accCatCrdt?.name"
                        (onChange)="accCatCrdt = $event"
                        [config]="{ label: 'account_category_credit', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }">
                </pro-autoc>
                <pm-account-category-type-btn (onChange)="typeCrdt = $event" [value]="typeCrdt"> </pm-account-category-type-btn>
                <pro-autoc
                        [value]="accCatDbit?.name"
                        (onChange)="accCatDbit = $event"
                        [config]="{ label: 'account_category_debit', entity: 'account_category', searchFields: ['keyName', 'name'], getOptionName: getCategoryName, isNone: true }">
                </pro-autoc>
                <pm-account-category-type-btn (onChange)="typeDbit = $event" [value]="typeDbit"> </pm-account-category-type-btn>
            </div>

            <div fxLayout="column" *ngIf="ready && type?.id === 'salary'">
                <pro-select fxFlex="90"
                            [value]="parts[0] ?? ''"
                            [config]="{ label: 'type', key: 'id', isNone: true  }"
                            [options]="salaryParts"
                            (onChange)="parts[0] = $event">
                </pro-select>
                <pro-select fxFlex="90"
                            [value]="parts[1] ?? ''"
                            [config]="{ label: 'type', key: 'id', isNone: true  }"
                            [options]="salaryParts"
                            (onChange)="parts[1] = $event">
                </pro-select>
                <pro-select fxFlex="90"
                            [value]="parts[2] ?? ''"
                            [config]="{ label: 'type', key: 'id', isNone: true  }"
                            [options]="salaryParts"
                            (onChange)="parts[2] = $event">
                </pro-select>
                <pro-select fxFlex="90"
                            [value]="parts[3] ?? ''"
                            [config]="{ label: 'type', key: 'id', isNone: true  }"
                            [options]="salaryParts"
                            (onChange)="parts[3] = $event">
                </pro-select>
            </div>
        </div>

        <pro-dialog-actions
                (callback)="mode === 'create' ? confirm() : save()"
                [variant]="mode === 'create' ? 'create' : 'update'"
                [isCallbackDisabled]="!name">
        </pro-dialog-actions>
    `
})

export class CreateTaxTypeDialogComponent {
    entityName: EntityNameType;
    taxEntityInterface: TaxEntityInterface
    taxTypesEntityInterface: EntityInterface
    typeDbit: string = 'D';
    typeCrdt: string = 'C';
    taxType: any;
    salaryParts: any[] = [];
    salaryPartsOptions: any[];
    taxesTypesOptions: any[] = [];
    parts: any[] = [];
    role: Role;
    type: any;
    accCatCrdt: any;
    accCatDbit: any;
    name: string = '';
    mode: string = '';
    ready: boolean = false;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<CreateTaxTypeDialogComponent>
    ) {
        this.mode = data.mode;

        if (this.mode === 'edit' && data.item) {
            this.role = data.item.role;
            this.type = data.item.type;
            this.typeDbit = data.item.typeDbit ?? '';
            this.typeCrdt = data.item.typeCrdt ?? '';
            for (let i = 0; i < 4; i++) this.parts[i] = data.item.parts ? (data.item.parts[i] ?? '') : '';
            this.accCatCrdt = data.item.accountCategoryCrdt;
            if (this.accCatCrdt) this.accCatCrdt.name = this.accCatCrdt ? this.getCategoryName(this.accCatCrdt) : '';
            this.accCatDbit = data.item.accountCategoryDbit;
            if (this.accCatDbit) this.accCatDbit.name = this.accCatDbit ? this.getCategoryName(this.accCatDbit) : '';
            this.name = data.item.name;
        }
        this.taxTypesEntityInterface = Entity.get('tax_types');
        this.taxEntityInterface = Entity.get('tax');
        this.taxEntityInterface.getTaxTypesList().then((response) => {
              Object.values(response).forEach((type: any) =>
                this.taxesTypesOptions.push({ id: type, name: this.Filter.translate(type) }));
          }).then(() => {
            console.log(this.type, this.taxesTypesOptions, findById(this.taxesTypesOptions, { id: this.type }));
            if (this.mode === 'edit' && data.item) this.type = findById(this.taxesTypesOptions, { id: this.type });
        });

        this.Entity.get('employee').getSalaryParts().then((response) => {
            this.salaryPartsOptions = response;
            Object.keys(response).forEach((part: any) =>
                this.salaryParts.push({ id: part, name: this.salaryPartsOptions[part] }));
            this.ready = true;
        });
    }

    confirm = () => {
        this.taxTypesEntityInterface.create({
            name: this.name,
            typeCrdt: this.typeCrdt,
            typeDbit: this.typeDbit,
            accountCategoryDbit: this.accCatDbit?.id,
            accountCategoryCrdt: this.accCatCrdt?.id,
            parts: this.parts,
            type: this.type.id,
            role: this.role?.id,
        }).then((response: any) => this.dialogRef.close(response.data));
    };

    save = () => {
        this.taxTypesEntityInterface.update({
            id: this.data.item.id,
            name: this.name,
            typeCrdt: this.typeCrdt,
            typeDbit: this.typeDbit,
            accountCategoryDbit: this.accCatDbit?.id,
            accountCategoryCrdt: this.accCatCrdt?.id,
            parts: this.parts,
            type: this.type.id,
            role: this.role?.id,
        }).then((response: any) => this.dialogRef.close(response.data));
    };

    getCategoryName = (cat: AccountCategory) => cat ? `${cat.keyName} - ${cat.name}` : '';

}
