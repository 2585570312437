import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { deepCopy } from '@proman/utils';

@Component({
    selector: 'pm-upload-entity-photo',
    template: `
        <pm-upload [file]="value[key]"
                   [config]="{ resizeImageRatio: config?.resizeImageRatio, resizeImage: config.resizeImage }"
                   (onUpload)="add($event)"
                   (onRemove)="remove()"></pm-upload>
    `
})

export class UploadEntityPhotoComponent implements OnInit {
    @Input() value: any;
    @Input() config: { resource: EntityNameType; key?: string, resizeImageRatio?: number; resizeImage?: boolean };
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    entityInstance: any;
    key: string;

    constructor(
        private Entity: Entity,
    ) {

    }

    ngOnInit() {
        this.key = this.config.key || 'photo';
        this.entityInstance = this.Entity.get(this.config.resource);
    }

    add(response: any) {
        let data: any = { id: this.value.id };

        try {
            // response contains array of uploaded file names
            data[this.key] = response[0].id;

        } catch (e) {
            return;
        }

        this.entityInstance
            .update(data)
            .then(() => {
                const tempValue = deepCopy(this.value);
                tempValue[this.key] = response[0];
                this.value = tempValue;
                this.onChange.emit();
            });
    }

    remove() {
        this.entityInstance
            .update({ id: this.value.id, [this.key]: null })
            .then((): any => {
                const tempValue = deepCopy(this.value);
                tempValue[this.key] = null;
                this.value = tempValue;
                this.onChange.emit();
            });
    }
}
