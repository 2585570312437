import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pm-entity-category-recursive',
    template: `
        <div class="box-one"
             fxLayout="column"
             *ngIf="(category.isActiveParent || category.isEnabled) || isEditMode">
            <div fxLayout="column">
                <div fxLayout="column"
                     *ngIf="!isEditMode">
                    {{ category.id }} - {{ category.name }}
                </div>
                <div fxLayout="column"
                     *ngIf="isEditMode">
                    <pro-checkbox fxFlex
                                 [config]="{ label: category.name }"
                                 [value]="category.isEnabled"
                                 (onChange)="handleToggle('categories', category, !$event)"
                                 [disabled]="!isEditMode"></pro-checkbox>
                </div>
            </div>
            <div fxLayout="column"
                 *ngIf="category.__children?.length"
                 class="_category-children">
                <div *ngFor="let _category of category.__children">
                    <pm-entity-category-recursive [category]="_category" [isEditMode]="isEditMode" (toggle)="handleToggle($event.field, $event.category, $event.value)"></pm-entity-category-recursive>
                </div>
            </div>
        </div>

    `,
    styles: [`
        ._category-children {
            margin: 8px 0;
            padding: 0px 16px;
            border-left: 2px dashed #BFBFBF;
        }
    `]
})

export class EntityParentCategoryRecursiveComponent implements OnInit {
    @Input() category: any;
    @Input() isEditMode: boolean;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

    constructor(

    ) {

    }

    ngOnInit() {

    }

    handleToggle = (field: string, category: any, value: boolean) => {
        this.toggle.emit({ field, category, value });
    };

}
