import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { mapOption, mapOptionTranslate, parseTimeUtc, prepareRequest, twoDigit } from '@proman/utils';
import moment from 'moment';
import { FilterService } from '@proman/services/filter.service';
import { DynamicFieldsService } from '@proman/services/dynamic-fields.service';
import { ModelItemInterface, ModelService } from '@proman/services/model.service';
import { ConsumerBookingEntityInterface } from '@proman/resources/consumer_booking';
import { OrderConsumerBooking } from '@proman/interfaces/entity-interfaces';
import { SelectOption } from '@proman/interfaces/object-interfaces';

const STATUSES = ['created', 'confirmed'];

@Component({
    selector: 'pm-consumer-booking-dialog',
    template: `
        <pro-dialog-title [title]="item.id ? 'edit' : 'create'"></pro-dialog-title>
        <div mat-dialog-content>

            <pm-update-data [item]="item" [key]="'created'"></pm-update-data>
            <pm-update-data [item]="item"></pm-update-data>

            <pro-autoc [value]="item.orderConsumer"
                       [config]="{ label: 'consumer', entity: 'order_consumer', hasColors: true }"
                       (onChange)="set('orderConsumer', $event)"></pro-autoc>

            <pro-select [value]="item.status"
                    [config]="{ label: 'status', key: 'id' }"
                    [options]="statusOptions"
                    (onChange)="set('status', $event)"></pro-select>

            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <pro-datepicker [value]="item.start"
                               [config]="{ label: 'date', hideTime: true }"
                               (onChange)="setStartTimeData('date', $event)"></pro-datepicker>

                <div fxFlex="25px"></div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <pro-select [value]="startTimeData.hours"
                            [config]="{ label: 'start', key: 'id', disableSearch: true }"
                            [options]="hoursOptions"
                            (onChange)="setStartTimeData('hours', $event)"
                            fxFlex="40px"
                    ></pro-select>
                    <span>h</span>
                    <div fxFlex="15px"></div>
                    <pro-select [value]="startTimeData.minutes"
                            [config]="{ label: '', key: 'id', disableSearch: true }"
                            [options]="minutesOptions"
                            (onChange)="setStartTimeData('minutes', $event)"
                            fxFlex="40px"
                    ></pro-select>
                    <span>m</span>
                </div>

                <div fxFlex="25px"></div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <pro-select [value]="durationData.days"
                            [config]="{ label: 'duration', key: 'id', disableSearch: true }"
                            [options]="daysOptions"
                            (onChange)="setDurationData('days', $event)"
                            fxFlex="40px"
                    ></pro-select>
                    <span>{{ 'day_shorthand' | translate }}</span>
                    <pro-select [value]="durationData.hours"
                            [config]="{ label: '', key: 'id', disableSearch: true }"
                            [options]="hoursOptions"
                            (onChange)="setDurationData('hours', $event)"
                            fxFlex="40px"
                    ></pro-select>
                    <span>{{ 'hour_shorthand' | translate }}</span>
                    <div fxFlex="15px"></div>
                    <pro-select [value]="durationData.minutes"
                            [config]="{ label: '', key: 'id', disableSearch: true }"
                            [options]="minutesOptions"
                            (onChange)="setDurationData('minutes', $event)"
                            fxFlex="40px"
                    ></pro-select>
                    <span>{{ 'minute_shorthand' | translate }}</span>
                </div>

            </div>

            <div fxLayout="row">
                <pm-txt [value]="item.email"
                        [config]="{ label: 'email', validators: { email: true } }"
                        (onChange)="set('email', $event)" fxFlex></pm-txt>

                <pm-txt [value]="item.phone"
                        [config]="{ label: 'phone' }"
                        (onChange)="set('phone', $event)" fxFlex></pm-txt>
            </div>

            <div fxLayout="row">
                <pm-txt [value]="item.sum"
                        [config]="{ label: 'estimated_sum', parseNumber: true }"
                        (onChange)="set('sum', $event)" fxFlex></pm-txt>

                <pm-txt [value]="item.numberOfVisitors"
                        [config]="{ label: 'visitors', parseNumber: true }"
                        (onChange)="set('numberOfVisitors', $event)" fxFlex></pm-txt>
            </div>

            <pm-txt [value]="item.comments"
                    [config]="{ label: 'comments', type: 'textarea' }"
                    (onChange)="set('comments', $event)"></pm-txt>

            <pm-dynamic-fields [entity]="item"  [entityName]="'consumer_booking'" [isCreate]="!item.id" (createFieldsEmit)="handleDynamicFields($event)"></pm-dynamic-fields>

            <pro-files-manager *ngIf="item.id"
                              (onChange)="model.addFile($event)"
                              [config]="{ multiple: true }"
                              [value]="item.files"
                              (onRemove)="model.removeFile($event)"></pro-files-manager>

            <pm-loose-relation *ngIf="item.id"
                               [item]="item"
                               [entityName]="'consumer_booking'"></pm-loose-relation>

        </div>
        <pro-dialog-actions (callback)="create()"
                           [isCallbackDisabled]="!dynamicFieldsValid"
                           [isCallbackHidden]="!!item.id"
                           [variant]="(!item.id) ? 'create' : 'confirm'"></pro-dialog-actions>
    `
})

export class ConsumerBookingDialogComponent {
    consumerBookingEntity: ConsumerBookingEntityInterface;
    item: OrderConsumerBooking;
    statusOptions: SelectOption[];
    dynamicFieldsData: any;
    dynamicFieldsValid: any;
    model: ModelItemInterface;

    duration: number = 2 * 60 * 60;
    durationData: { days: string; hours: string; minutes: string } = { days: '00', hours: '02', minutes: '00' };
    startTimeData: { hours: string; minutes: string } = { hours: '02', minutes: '00' };
    dayValues: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25' , '26', '27', '28', '29', '30']
    hoursValues: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    minutesValues: string[] = ['00', '15', '30', '45'];
    hoursOptions: SelectOption[];
    minutesOptions: SelectOption[];
    daysOptions: SelectOption[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Filter: FilterService,
        private Model: ModelService,
        private DynamicFields: DynamicFieldsService,
        private dialogRef: MatLegacyDialogRef<ConsumerBookingDialogComponent>,
    ) {
        this.consumerBookingEntity = this.Entity.get('consumer_booking');
        this.statusOptions = STATUSES.map((item: string) => mapOptionTranslate(item, this.Filter.translate));
        this.hoursOptions = this.hoursValues.map(mapOption);
        this.daysOptions = this.dayValues.map(mapOption);
        this.minutesOptions = this.minutesValues.map(mapOption);

        this.item = this.data.item && this.data.item.id ? this.data.item : {};

        if (!this.item.id) {
            const startTime = moment().startOf('hour').add(1, 'h').format();

            this.item = {
                start: parseTimeUtc(moment().startOf('hour').add(1, 'h').format()),
                end: parseTimeUtc(moment(startTime).add(this.duration, 's').format()),
                status: STATUSES[0],
            } as OrderConsumerBooking;

        } else {
            const start = moment(this.item.start);
            const end = moment(this.item.end);

            this.duration = moment.duration(end.diff(start)).asSeconds();
        }

        this.model = this.Model.get(this.item, 'consumer_booking');

        this.calculateTimeOptions();
        this.calculateDurationOptions();

    }

    set(property: string, value: any) {
        const item = this.item;

        this.item[property] = value;

        if (item.id) {
            this.consumerBookingEntity
                .update(prepareRequest({
                    id: item.id,
                    [property]: value
                }));
        }

        if (property === 'start') return this.setEnd();

    }

    calculateTimeOptions() {
        const start = this.item.start;

        this.startTimeData.hours = twoDigit(moment(start).hours());
        this.startTimeData.minutes = twoDigit(moment(start).minutes());
    }

    calculateDurationOptions() {
        const durationInSeconds = this.duration;

        const day = Math.floor(durationInSeconds / (24 * 60 * 60));
        const hour = Math.floor((durationInSeconds - day * 24 * 60 * 60) / 60 / 60);
        const seconds = Math.floor((durationInSeconds - (day * 24 + hour) * 60 * 60) / 60);

        this.durationData.days = twoDigit(day);
        this.durationData.hours = twoDigit(hour);
        this.durationData.minutes = twoDigit(seconds);
    }

    setDuration(value: number) {
        this.duration = value;

        this.setEnd();
    }

    setEnd = () => {
        this.set('end', parseTimeUtc(moment(this.item.start).add(this.duration, 's').subtract(moment(this.item.start).utcOffset(), 'm').format()));
    };

    create() {
        this.consumerBookingEntity
            .create(prepareRequest(this.item))
            .then(async (response: any) => {
                const result = response.data || response;
                let requests: any = [];

                this.dynamicFieldsData.forEach((item: any) => {
                    requests.push(this.DynamicFields.createFieldValue(Object.assign({ entityId: result }, item)));
                });

                return await Promise.all(requests)
                    .then(() => this.dialogRef.close(result))
                    .catch(() => this.dialogRef.close(result));
            });
    }

    handleDynamicFields(data: any) {
        this.dynamicFieldsValid = data.isValid;
        this.dynamicFieldsData = data.values;
    }

    setStartTimeData(property: string, value: string) {
        let date = property === 'date' ? value : this.item.start;

        const startTimeData = this.startTimeData;

        startTimeData[property] = value;

        const startValue = moment(date).hours(+startTimeData.hours).minutes(+startTimeData.minutes).utc().format();

        this.set('start', startValue);

    }

    setDurationData(property: string, value: string) {
        const durationData = this.durationData;

        durationData[property] = value;

        this.setDuration(((+durationData.days * 24 + +durationData.hours) * 60 + +durationData.minutes) * 60);
    }

}
