import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Fa6Module } from '@proman/fa/fa6.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanButtonComponent } from '@proman/button';

/**
 * `proWarning` component
 *
 */
@Component({
    selector: 'pro-warning',
    standalone: true,
    imports: [
        CommonModule,
        Fa6Module,
        FlexLayoutModule,
        PromanButtonComponent,
    ],
    styleUrls: [
      '../scss/_warning.scss',
    ],
    template: `
        <div #element
             fxLayout="row"
             fxLayoutAlign="start center"
             class="Warning">
            <fa name="exclamation-triangle"></fa>
            <div class="Warning-content">
                <ng-content></ng-content>
            </div>
            <div fxFlex></div>
            @if (!preventClose) {
                <pro-btn icon="times"
                         theme="white"
                         tooltip="close"
                         (onClick)="hide($event)"
                ></pro-btn>
            }
        </div>
    `
})

export class PromanWarningComponent {
    label: string;
    @Input() preventClose: boolean;
    @ViewChild('element', { static: true }) element: ElementRef;

    hide($event: MouseEvent) {
        $event.stopPropagation();
        this.element.nativeElement.remove();
    }
}
