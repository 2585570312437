import { Component, Input, OnInit } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { ModelService } from '@proman/services/model.service';
import { flatten } from '@proman/utils';

@Component({
    selector: 'pm-entity-parent-categories',
    template: `
        <div fxLayout="column" class="Padding">
            <pro-btn (onClick)="toggleEditMode()"
                    icon="plus"
                    theme="accent"
                    *ngIf="!disabled"
                    [tooltip]="'add' | translate"></pro-btn>

            <div *ngFor="let category of categories">
                <pm-entity-category-recursive [category]="category" [isEditMode]="isEditMode" (toggle)="toggle($event.field, $event.category, $event.value)"></pm-entity-category-recursive>
            </div>
        </div>
    `
})

export class EntityParentCategoriesComponent implements OnInit {
    @Input() entity: any;
    @Input() entityName: EntityNameType;
    @Input() entityParams: any;
    @Input() disabled: boolean;

    isEditMode: boolean = false;
    model: any;
    entityCategoryEntity: any;
    categories: any;

    constructor(
        private Entity: Entity,
        private Model: ModelService,
    ) {

    }

    ngOnInit() {
        const entity = this.Entity.get({ name: this.entityName });

        this.entityCategoryEntity = this.Entity.get({ name: (this.entityName + '_category' as EntityNameType) });

        this.model = this.Model.get(this.entity, entity);

        let treeParams = Object.assign({ tree: true }, this.entityParams || {});
        let categoryParams = Object.assign({ }, this.entityParams || {});

        categoryParams[`${this.entityName}s.id`] = this.entity.id;

        Promise.all([
                this.entityCategoryEntity.search(treeParams),
                this.entityCategoryEntity.search(categoryParams)
            ])
            .then((response: any) => {
                let categoriesTree = response[0];
                let selectedCategories = response[1];
                let selectedCategoriesIds = flatten(selectedCategories, 'id');

                this.getEnabled(categoriesTree, selectedCategoriesIds);

                this.categories = categoriesTree;
            });
    }

    getEnabled = (categories: any, selectedCategoriesIds: any, parent?: any, root?: any): any => {
        let t;

        for (let category of categories) {

            if (category.__children?.length) {

                if (selectedCategoriesIds.indexOf(category.id) > -1) {
                    category.isEnabled = true;
                    if (parent) {
                        t = true;

                        parent.isActiveParent = true;
                    }

                }

                t = this.getEnabled(category.__children, selectedCategoriesIds, category, root || category);

                if (parent && t) {
                    parent.isActiveParent = true;
                    category.isActiveParent = true;
                }

            } else {

                if (selectedCategoriesIds.indexOf(category.id) > -1) {
                    category.isEnabled = true;

                    if (parent) {
                        t = true;

                        if (!parent.isActiveParent) {
                            parent.isActiveParent = true;

                            this.enableAllParents(parent, root || category)

                        }

                    }
                }
            }
        }

        return t;
    };

    private enableAllParents(parent: any, root: any) {
        if (parent.__children?.length) {

            if (parent.root === root.id) {
                root.isActiveParent = true;
            }

        }

    }

    toggleEditMode = () => this.isEditMode = !this.isEditMode;

    toggle = (field: string, value: any, added: boolean) => {
        return this.model[(added ? 'removeAssociation' : 'addAssociation')](field, value);
    };

}
