import { Component, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { findById, flatten, getIndexByProperty, isDefined, mapId } from '@proman/utils';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { Dialog } from '../services/dialog.service';
import { DragulaService } from 'ng2-dragula';
import { ToastService } from '@proman/services/toast.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { PromanStateService } from '../services/proman-state.service';
import { Action } from '@proman/interfaces/object-interfaces';
import $ from 'jquery';
import { ACL } from '@proman/services/acl.service';
import { Parameter } from '@proman/interfaces/entity-interfaces';
import { InlineListService } from '@proman/inline-list/inline-list.service';
import { ParametersService } from '@proman/parameters/services/parameters.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import {
    ParameterMaterialFilterSetupDialogComponent
} from '@proman/shared-dialogs/dialogs/article-material-filter-setup-dialog.component';
import {
    ParameterMaterialCategoryFilterSetupDialogComponent
} from '@proman/shared-dialogs/dialogs/article-material-category-filter-setup-dialog.component';

@Component({
    selector: 'pm-parameters',
    template: `
        <div fxLayout="column">
            <div fxLayout="row">
                <pro-btn  *ngIf="config.isAdd || config.addCallback"
                         (onClick)="add($event)"
                         icon="plus"
                         theme="accent"
                         [tooltip]="'add_parameter' | translate"
                         [tooltipPosition]="'right'"
                         [disabled]="disabled"
                ></pro-btn>
                <ng-content select=".parameters-top-buttons" ></ng-content>
            </div>
            <div *ngIf="parameters.length"
                 [dragula]="dragulaId"
                 [dragulaModel]="parameters">
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     *proFor="let parameter of parameters; offset: 5;renderInterval: 200; renderIterator: 5;">
                    <ng-container *ngIf="!parameter._isRerendering && parameter._isVisible">
                        <pro-move-handle [class]="'Parameters-moveHandle'" *ngIf="config.isMove && !disabled"></pro-move-handle>
                        <pro-parameter *ngIf="!parameter._isRerendering"
                                       fxFlex
                                       [form]="form"
                                       [parameter]="parameter"
                                       [entity]="config.entity"
                                       [config]="parametersConfigBind[parameter.id]"
                                       (onChange)="update($event, parameter)"
                                       [disabled]="disabled || reloading || parameter.isLocked"></pro-parameter>
                        <pro-expression-expression *ngIf="!isCustomer && (parameter.parameter.type === 'material' || parameter.parameter.type === 'number' || parameter.parameter.type === 'string')"
                                                  [config]="{ label: 'expression' }"
                                                  [value]="parameter.expression"
                                                  [function]="parameter.parameterFunction"
                                                  [fallbackValue]="parameter.parameter.expression"
                                                  (onChange)="updateExpression(parameter, $event)"
                                                  [hidden]="config.hideExpression"
                                                  [disabled]="disabled || parameter.isLocked || config.disableExpression"></pro-expression-expression>
                        <pro-btn *ngIf="!disabled && (parameter.parameter.type === 'material_category' || parameter.parameter.type === 'material') && !config.hideFilter"
                                (onClick)="setupMaterialFilter(parameter, parameter.parameter.type)"
                                icon="filter"
                                [tooltip]="'filter' | translate"
                                size="1x"
                                theme="accent"></pro-btn>
                        <pro-btn *ngIf="(parameter.type === 'number') || (parameter.parameter.type === 'number') && ACL.check('parameter.master')"
                                [tabIndex]="-1"
                                (onClick)="setProperty(parameter, 'isActive', !parameter.isActive)"
                                [icon]="'ban'"
                                [tooltip]="'active_/_inactive' | translate"
                                size="1x"
                                [theme]="parameter.isActive ? 'accent' : 'grey'"></pro-btn>
                        <pro-btn *ngIf="(config.entity === 'article_product_parameter' || config.entity === 'article_order_parameter') && !disabled"
                                [tabIndex]="-1"
                                (onClick)="handleLock(parameter)"
                                [icon]="parameter.isLocked ? 'lock' : 'unlock'"
                                [tooltip]="'lock_or_unlock_parameter' | translate"
                                size="1x"
                                theme="accent"></pro-btn>
                        <pro-btn *ngIf="config.isVisibility"
                                [tabIndex]="-1"
                                (onClick)="setProperty(parameter, 'isVisible', !parameter.isVisible)"
                                [icon]="'eye'"
                                [tooltip]="'visible_or_invisible_parameter' | translate"
                                size="1x"
                                [theme]="parameter.isVisible ? 'accent' : 'grey'"></pro-btn>
                        <pro-btn *ngIf="config.autoCreated && parameter.type !== 'parameter_group'"
                                [tabIndex]="-1"
                                (onClick)="setProperty(parameter, 'autoCreated', !parameter.autoCreated)"
                                [icon]="'plus-octagon'"
                                [tooltip]="'parameter_auto_created' | translate"
                                size="1x"
                                [theme]="parameter.autoCreated ? 'accent' : 'grey'"></pro-btn>
                        <pro-btn *ngIf="config.entity === 'article_operation_parameter' && !disabled && parameter._isForEachOperation"
                                [theme]="parameter.forEachBooking ? 'accent' : 'grey'"
                                [tooltip]="'for_each_booking' | translate"
                                [icon]="'user-check'"
                                size="1x"
                                (onClick)="handleForEachBooking(parameter)"></pro-btn>
                        <pro-btn [tabIndex]="-1"
                                *ngIf="config.isEraser && !disabled && !parameter.isLocked"
                                (click)="clearValue(parameter)"
                                icon="eraser"
                                [tooltip]="'clear' | translate"
                                size="1x"
                                theme="warn"
                                [disabled]="disabled"></pro-btn>
                        <pro-btn [tabIndex]="-1"
                                *ngIf="!disabled && !parameter.isLocked && ACL.check('article.edit') && config?.removeEnabled"
                                (click)="removeLayer(parameter)"
                                icon="times"
                                size="1x"
                                theme="warn"
                                [tooltip]="'delete' | translate"
                                [disabled]="disabled"></pro-btn>
                        <pro-btn [tabIndex]="-1"
                                *ngIf="config.isHyperlink && parameter.parameter"
                                (onClick)="goToParameter($event, parameter)"
                                icon="chevron-double-right"
                                [tooltip]="'go_to_parameter' | translate"
                                size="1x"
                                theme="accent"></pro-btn>
                    </ng-container>
                </div>
            </div>
            <pro-no-records *ngIf="!parameters.length && isLoaded"></pro-no-records>
        </div>
    `
})

export class ParametersComponent implements OnInit, OnChanges, OnDestroy {
    @Input() model: any;
    @Input() config: {
        entity?: EntityNameType;
        extraJoin?: string[];
        context?: string;
        modelKey?: string;
        removeEnabled?: boolean;
        isMove?: boolean;
        isMoveGroup?: boolean;
        isAdd?: boolean;
        isHyperlink?: boolean;
        positionData?: any;
        groupActions?: any[];
        article?: any;
        images?: boolean;
        hideFilter?: boolean;
        preventExpressionCheck?: boolean;
        updateFiltered?: boolean;
        createKey?: string;
        isVisibility?: boolean;
        autoCreated?: boolean;
        hideExpression?: boolean;
        disableExpression?: boolean;
        showId?: boolean;
        isEraser?: boolean;
        addCallback?: ((data: any) => Promise<unknown>);
        fromArticlesTest?: boolean;
        isProductionParams?: boolean;
    };
    @Input() groupActions: Action[];
    @Input() disabled: any;
    @Input() evaluateParameters: boolean = true;
    @Input() timeStamp: number;
    @Input() form: UntypedFormGroup;
    @Output() onInit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() addLayerCallback: EventEmitter<any> = new EventEmitter<any>();
    entityInstance: any;
    childEntityInstance: any;
    parameters: any = [];
    isLoaded: any;
    reloading: any;
    parametersConfig: any;
    dragulaId: any;
    subscriberDrag: any;
    subscriberDrop: any;
    dragParameter: any;
    parametersList: any;
    isMovable: any;
    parameterEntity: any;
    parametersConfigBind: any = {};
    isCustomer: boolean;

    constructor(
        public ACL: ACL,
        private Entity: Entity,
        private Dialog: Dialog,
        private Toast: ToastService,
        private InlineListService: InlineListService,
        private Dragula: DragulaService,
        private QueryExpression: QueryExpressionService,
        private Parameter: ParametersService,
        private PromanState: PromanStateService,
        private store: Store<unknown>,
    ) {
        this.store.select(getCurrUser).subscribe((user) => {
            if (user) this.isCustomer = user.isCustomer
        })
        this.parameterEntity = this.Entity.get('parameter');
        this.dragulaId = 'parameters' + new Date().valueOf() + Math.random() * 11234;   // randomize for multiple pmParameters like in orderCreateForm
        Dragula.createGroup(this.dragulaId, {
            moves: (el: any, source: any, handle: any) => {
                const element = $(handle);
                const isMove = (element: any) => element.hasClass('Parameters-moveHandle');
                this.isMovable = isMove(element) || isMove(element.parent()) || isMove(element.parent().parent());
                return this.isMovable;
            },

            invalid: (el: any, handle: any) => {
                const element = $(handle);
                const isGroupParameter = (element: any) => element.hasClass('List-row');
                return isGroupParameter(element) || isGroupParameter(element.parent()) || isGroupParameter(element.parent().parent());
            },
        });

        this.subscriberDrag = Dragula.drag(this.dragulaId).subscribe(({ name, el, source }) => {
            if (this.isMovable) {
                const index = [].slice.call(el.parentElement.children).indexOf(el);
                this.dragParameter = Object.assign({}, this.parameters[index]);
            }

        });

        this.subscriberDrop = Dragula.drop(this.dragulaId).subscribe(({ name, el, source }) => {
            if (this.isMovable) {
                const index = [].slice.call(el.parentElement.children).indexOf(el);
                const swapParameter = this.parameters[index];
                this.setPosition(this.dragParameter, swapParameter);
                this.dragParameter = null;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.timeStamp && !changes.timeStamp.isFirstChange() && changes.timeStamp.currentValue !== changes.timeStamp.previousValue) {
            this.init();
        }

        if (changes.model && changes.model.currentValue && !changes.model.isFirstChange()) {
            this.init();
        }

    }

    ngOnDestroy() {
        this.Dragula.destroy(this.dragulaId);
        this.subscriberDrag.unsubscribe();
        this.subscriberDrop.unsubscribe();
    }

    aggregateGroupParameters(parameters: any, currParam: any, offset: any) {
        const aggregated = {
            id: currParam.id,
            type: 'parameter_group',
            parameter: currParam.parameter,
            parameters: [currParam],
            isVisible: currParam.isVisible
        };
        let parameter;
        let iter;

        for (iter = offset + 1; iter < parameters.length; iter++) {
            parameter = parameters[iter];

            if (parameter.parameter.type === 'parameter_group' && currParam.parameter.id === parameter.parameter.id) {
                aggregated.parameters.push(parameter);

            }

        }

        return aggregated;
    }

    handleParameters(items: any) {
        const parameters = [];
        const tmpIds = [];

        // join group parameters
        for (let iter = 0, count = items?.length; iter < count; iter++) {
            const item = items[iter];

            if (isDefined(item.forEachBooking)) item._isForEachOperation = true;

            if (tmpIds.indexOf(item.parameter.id) === -1 && item.parameter.type === 'parameter_group') {
                const boo = this.aggregateGroupParameters(items, item, iter);

                tmpIds.push(item.parameter.id);
                parameters.push(boo);

            } else if (item.parameter.type !== 'parameter_group') {
                parameters.push(item);
            }
        }

        return parameters;
    }

    init = () => {
        let params;
        if (this.config.fromArticlesTest) {
            params = {
                join: ['parameter', 'children', 'articleTestParameter', 'children.parameter'],
                sort: { 'articleTestParameter.position': 'asc' }
            };
        } else if (this.config.isProductionParams) {
            params = {
                join: ['parameter', 'children', 'articleProductionParameter', 'children.parameter'],
                sort: { 'position': 'asc' }
            };
        } else {
            params = {
                join: ['parameter', 'children', 'children.parameter'],
                sort: { position: 'asc' },
                translate: true
            }
        }

        if (this.config.extraJoin) {
            const extraJoin = this.config.extraJoin;

            for (const join of extraJoin) {
                params.join.push(join);

            }

        }
        params.join.push('parameterFunction')

        this.parametersConfig = {
            addLayer: this.addLayer,
            removeLayer: this.removeLayer,
            update: this.update,
            updateChild: this.updateChild,
            setPosition: this.setPosition,
            isMove: this.config.isMove || this.config.isMoveGroup,
            groupActions: this.groupActions,
            article: this.config.article,
            modelKey: this.config.modelKey,
            images: this.config.images,
            hideFilter: this.config.hideFilter,
            showId: this.config.showId
        };

        this.parametersConfig[this.config.modelKey] = this.model;

        params[this.config.modelKey + '.id'] = this.model.id;

        return this.entityInstance
            .search(params)
            .then((response: any) => {
                this.parameters = this.handleParameters(response);
                this.setParametersConfig();

                this.setVisibleParameters(this.parameters);

                this.setModelParams();

                this.getParametersList();
                this.isLoaded = true;
                this.reloading = false;
                this.onInit.emit();

            });
    };

    createParameter(data: any, key: any) {
        data[key] = this.model.id;

        this.entityInstance.create(data).then(this.init);
    }

    createListParameter(data: any, key: any) {
        let defaultValues = '';

        data[key] = this.model.id;

        this.entityInstance
            .create(data)
            .then((response: any) => {
                this.Entity.get('parameter_dropdown_option')
                    .search({ 'parameter.id': data.parameter, 'translate': true })
                    .then((values: any) => {

                        if (values.length) {
                            defaultValues = '["' + flatten(values, 'name').join('","') + '"]';

                            this
                                .update(defaultValues, { id: response.data })
                                .then(this.init);

                        } else {
                            this.init();

                        }

                    });
            });
    }

    handleUpdate = () => {
        this.onUpdate.emit();
        this.setModelParams();
        if (!this.config.preventExpressionCheck) this.checkExpressions();

        if (this.config.updateFiltered && this.parameters.some((p: any) => !!p.filter)) this.updateFiltered();
    };

    ngOnInit() {
        this.entityInstance = this.Entity.get(this.config.entity);
        this.childEntityInstance = this.Entity.get(this.config.entity.replace('parameter', 'child_parameter') as EntityNameType);

        this.init();
    }

    addLayer = ($event: any, parameter: any, parameters: any) => {

        if (this.addLayerCallback.observers.length) {
            this.addLayerCallback.emit({ $event, parameter, parameters });
        } else {
            const params = { parameter: parameter.parameter.id };

            params[this.config.modelKey] = this.model.id;
            this.Dialog.entityCreate(this.config.entity, params).then(() => this.init());
        }

    };

    removeLayer = (layer: any) => {
        let ids;
        let promise;

        if (layer.type === 'parameter_group') {
            ids = flatten(layer.parameters, 'id');

            promise = this.entityInstance.remove({ id: ids });

        } else {
            promise = this.entityInstance.remove({ id: layer.id });

        }

        promise.then(() => this.init());
    };

    getWorkgroupParameters(parameters: any) {
        const output: any = [];

        parameters.forEach((item: any) => {

            item.children.forEach((parameter: any) => {

                if (parameter.parameter.type === this.parameterEntity.WORKGROUP) {
                    output.push(parameter);

                }

            });
        });

        return output;
    }

    setPosition = (parameter: any, swapParameter: any) => {
        const position = getIndexByProperty(this.parametersList, 'id', swapParameter.id);

        if (typeof position === 'undefined') return;

        this.reloading = true;

        const data = Object.assign(this.config.positionData || {}, { positionId: parameter.id, positionAt: position });

        this.entityInstance.reposition(data).then(this.init);

    };

    setProperty = (parameter: any, property: string, value: any) => {
        this.entityInstance
            .update({ id: parameter.id, [property]: value })
            .then(() => {
                parameter[property] = value;
                this.init();
            });
    };

    updateChild = (parameter: any, value: any, parameters: any) => {
        this.childEntityInstance.update({ id: parameter.id, value, evaluateParameters: this.evaluateParameters })
            .then(() => {
                if (!this.evaluateParameters) return;
                let allWorkgroupParameters: any;
                let allEmpty = true;
                let ids;

                // If we edit one workplace in the group and all others are empty, set same workplace value for all empty ones
                if (parameter.parameter.type === this.parameterEntity.WORKGROUP) {

                    allWorkgroupParameters = this.getWorkgroupParameters(parameters)
                        .filter((workgroupParameter: any) => workgroupParameter.id !== parameter.id);

                    for (const item of allWorkgroupParameters) {

                        if (this.Parameter.empty(item)) continue;

                        allEmpty = false;
                        break;
                    }

                    if (allEmpty && allWorkgroupParameters.length > 0) {
                        ids = allWorkgroupParameters.map(mapId);

                        return this.childEntityInstance.update({ id: ids, value })
                            .then(() => allWorkgroupParameters.forEach((parameter: any) => parameter.value = value));
                    }
                }
            }).then(() => {
                if (this.evaluateParameters) {
                    this.handleUpdate();
                    this.init();
                }
            });
    };

    update(value: any, parameter: any, rerender: boolean = false) {
        return this.entityInstance.update({ id: parameter.id, value, evaluateParameters: this.evaluateParameters })
            .then(() => {
                parameter.value = value;

                if (rerender) {
                    parameter._isRerendering = true;
                    setTimeout(() => parameter._isRerendering = false);
                }

                this.handleUpdate();
            });
    }

    updateExpression(parameter: any, value: any) {
        if (value?.id) {
            this.entityInstance.update({ id: parameter.id, 'ParameterFunction': value.id, 'expression': 'null' });
            parameter.parameterFunction = value;
            parameter.expression = null;
        } else {
            this.entityInstance.update({ id: parameter.id, 'ParameterFunction': 'null', 'expression': value });
            parameter.parameterFunction = null;
            parameter.expression = value;
        }
    }

    async add($event: MouseEvent) {
        const context = this.config.context;
        const data = { context: this.QueryExpression.eqStrict(context) };
        let singularParameterPresent: boolean = false;

        if (this.config.context === 'production' && this.config.article?.id) {
            const ids = await this.Entity.get('article')
                .get({
                    id: this.config.article?.id || '',
                    join: [
                        'productionParameters',
                        'productionParameters.parameter',
                    ], })
                .then((response) => {
                    return response.productionParameters.length ? response.productionParameters.map((pp) => pp.parameter.id) : []
                });

            data['id'] = this.QueryExpression.in(ids);
        }

        this.parameterEntity.search(data).then((response: Parameter[]) => {
            this.InlineListService.show({
                data: response.map((param) => ({ ...param, name: `#${param.id} - ${param.name}` })),
                event: $event,
                closeOnSelect: true,
                onSelect: (parameter: Parameter) => {
                    const data: { parameter?: number } = { parameter: parameter.id };
                    const key = this.config.createKey ? this.config.createKey : this.config.modelKey;
                    let tmpVal: any;
                    let i = 0;

                    while (!singularParameterPresent && i < this.parameters.length - 1) {
                        if (this.parameters[i]?.parameter?.id === parameter.id && !parameter.multiple) {
                            singularParameterPresent = true;
                            this.Toast.pop('error', 'Parameter already present and is not multiple usage.')
                        }
                        i++;
                    }

                    if (!singularParameterPresent) {
                        if (this.config.addCallback) {
                            this.config.addCallback(parameter)
                                .then(this.init);
                        } else if (parameter.type === this.parameterEntity.PARAMETER_GROUP) {
                            data[key] = this.model.id;

                            this.Dialog
                                .entityCreate(this.config.entity, data, {
                                    mainField: {
                                        key: 'name',
                                        name: 'name',
                                        config: {required: true}
                                    }
                                })
                                .then(this.init);

                        } else {

                            if (parameter.type === 'workgroup' && context === 'production') {
                                tmpVal = JSON.parse(parameter.value);

                                if (tmpVal === null || tmpVal.operationId === null) {
                                    // Require parameter default value to be assigned
                                    this.Toast.pop('warning', 'parameter_has_no_default_value', {parameter: parameter.name});

                                } else {
                                    // Require parameter operation to be assigned for article
                                    this.Entity.get('article_operation')
                                        .get({
                                            'article.id': this.model.id,
                                            'operation.id': tmpVal.operationId
                                        })
                                        .then(
                                            () => this.createParameter(data, key),
                                            () => this.Entity.get('operation').get({id: tmpVal.operationId})
                                                    .then((response: any) => this.Toast.pop('error', 'article_has_no_operation', {operation: response.name}))
                                        );
                                }

                            } else if (parameter.type === 'list') {
                                this.createListParameter(data, key);

                            } else {
                                this.createParameter(data, key);

                            }

                        }
                    }
                }
            });
        });
    }

    getParameterConfig(parameter: any) {
        const _parameter = parameter.parameter || parameter;

        return Object.assign({}, this.parametersConfig, { required: _parameter.required })
    }

    getParametersList() {
        const result: any = [];

        this.parameters.forEach((item: any) => {

            if (item.type === 'parameter_group') {
                item.parameters.forEach((parameter: any) => result.push(parameter));

            } else {
                result.push(item);

            }
        });

        this.parametersList = result;
    }

    handleLock = (parameter: any) => {
          const value = !parameter.isLocked;

          this.Entity.get(this.config.entity)
              .update({ id: parameter.id, isLocked: value })
              .then(() => parameter.isLocked = value);

    };

    handleForEachBooking = (parameter: any) => {
        const value = !parameter.forEachBooking;

        this.Entity.get('article_operation_parameter')
            .update({ id: parameter.id, forEachBooking: value })
            .then(() => parameter.forEachBooking = value);

    };

    setModelParams = () => {
        this.model._parameters = this.parameters;
    };

    setupMaterialFilter = (parameter: any, type: string) => {
        let promise;
        if (type === 'material') {
            promise = this.Dialog
                .open2(ParameterMaterialFilterSetupDialogComponent, { material: parameter }, { width: '450px' });
        } else {
            promise = this.Dialog
                .open2(ParameterMaterialCategoryFilterSetupDialogComponent, { materialCategory: parameter }, { width: '450px' });
        }

        promise.afterClosed()
            .subscribe((result) => {
                if (!result) return;

                const stringResult = JSON.stringify(result);

                this.entityInstance
                    .update({ id: parameter.id, filter: stringResult })
                    .then(() => parameter.filter = stringResult);
            });
    };

    setVisibleParameters = (parameters: any[]) => {
        parameters.forEach((p: any) => {
            p._isVisible = this.config.isVisibility ? true : (!isDefined(p.isVisible) || isDefined(p.isVisible) && p.isVisible);
        });

    };

    goToParameter = (event: MouseEvent, parameter: any) => {
        const isNewTab = (event.ctrlKey || event.metaKey);

        this.PromanState.to('Parameter', parameter.parameter.id, parameter.parameter.deleted ?  { deleted: true } : null, isNewTab);
    };

    checkExpressions = () => {
        this.parameters.forEach((parameter: any) => {
            if (parameter.expression) {
                this.entityInstance
                    .get({ id: parameter.id })
                    .then((response: any) => {
                        parameter = Object.assign(parameter, response);

                        parameter._isRerendering = true;
                        setTimeout(() => parameter._isRerendering = false);
                    });
            }
        });

    };

    updateFiltered = () => {
        const filtered = this.parameters.filter((p: any) => !!p.filter);
        const ids = filtered.map(mapId);
        this.entityInstance
            .search(Object.assign({ id: this.QueryExpression.in(ids) }))
            .then((response: any) => {
                response.forEach((p: any) => {
                    const par = findById(this.parameters, p);

                    if (par && par.value != p.value) {
                        Object.assign(par, p);

                        par._isRerendering = true;
                        setTimeout(() => par._isRerendering = false);
                    }
                });
            });

    };

    setParametersConfig() {
        this.parametersConfigBind = {};

        this.parameters.forEach((p: any) => {
           this.parametersConfigBind[p.id] = this.getParameterConfig(p);
        });
    }

    clearValue(parameter: any) {
        this.update(null, parameter, true);
        if (parameter.type === 'parameter_group') {
            parameter.parameters.forEach((parameterChild: any) => {
                this.update(null, parameterChild, true);
                parameterChild.value = null;
                parameterChild.children.forEach((parameterGrandchild: any) => {
                    parameterGrandchild.value = null;
                    this.childEntityInstance.update({ id: parameterGrandchild.id, value: null });
                })
            })
        }
    }
}
