import { Inject, Injectable, Injector } from '@angular/core';
import { Entity, EntityConfigInterface, EntityInterface } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { statuses } from '@proman/resources/event';
import { resourcesConfig } from '@proman/resources';
import { Employee, Person } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { EmployeeEntityInterface } from '@proman/resources/employee';
import { getCurrUser } from '@proman/store/curr-user';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { isArray, safeMap } from '@proman/utils';

export interface TabelSortOption {
    id: string;
    name: string;
    field: string;
}

@Injectable()
export class EmployeesService {
    employeeEntity: EmployeeEntityInterface;
    employeeBookingEntity: EntityInterface;
    currUser: CurrUser;

    constructor(
        @Inject(Entity) private Entity: Entity,

        private Injector: Injector,
        private QueryExpression: QueryExpressionService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
        const entityConfig = resourcesConfig.employee.params.entityConfig;
        const QueryBuilder = {
            managers: () => ({
                'specialisations.type': 'manager',
                'join': ['specialisations']
            }),
            specialisations: (specialisations: any) => ({
                'specialisations.type': this.QueryExpression.orStrict(isArray(specialisations) ? specialisations : [specialisations]),
                'join': ['specialisations']
            }),
            roles: (roles: any) => ({
                'specialisations.role.type': this.QueryExpression.orStrict(isArray(roles) ? roles : [roles]),
                'join': ['specialisations', 'specialisations.role']
            }),
            aggregate: () => ({
                join: ['specialisations', 'specialisations.role', 'photo', 'cards', 'files', 'orderConsumers', 'role', 'mainSpecialization']
            })
        };

        this.employeeEntity = Entity.get(Object.assign(entityConfig, { QueryBuilder }) as EntityConfigInterface) as EmployeeEntityInterface;
        this.employeeBookingEntity = this.Entity.get('employee_booking');
    }

    getParameters = (item: any) => {
        let ids: number[];

        if (Array.isArray(item)) {
            ids = item.map((employee) => employee.id);

        } else {
            ids = [item.id];

        }

        return this.employeeBookingEntity
            .search({
                'employee.id': this.QueryExpression.in(ids),
                'status': this.QueryExpression.notIn([statuses.FINISHED]),
                'count': true
            })
            .then((response: any) => {
                return response.count ? [
                    {
                        key: 'replanEmployeeTasks',
                        name: 'replan_employee_tasks',
                        type: 'checkbox',
                        config: { }
                    }
                ] : [];

            });
    };

    getManagers = (customParams?: unknown) => {
        const currUser = this.currUser;

        let params: { [key: string]: unknown } = { join: [] };

        if (customParams) {
            params = Object.assign(params, customParams);

        } else {
            if (currUser.type === 'agent') params['agents.id'] = currUser.person.id;

        }

        return this.employeeEntity.QB.managers().search(params);
    };

    is = (employee: Employee|Person, type: string): Promise<boolean> => {
        let promise;

        if (!employee) return Promise.resolve(false);

        if (!employee.specialisations) {
            promise = this.employeeEntity.get({
                id: employee.id,
                join: ['specialisations', 'specialisations.role']
            }).then(
                (response: any) => employee.specialisations = response.specialisations,
                (): any => employee.specialisations = []
            );
        } else {

            promise = new Promise((resolve) => resolve(null));
        }

        return promise.then(() => {
            for (const spec of  employee.specialisations) {
                if (spec.type === type || spec.role?.type === type) {
                    return true;
                }
            }

            return false;
        });
    };

    isEmployeesDirector = (employee: Employee, director: Employee|Person): boolean => {
        if (!employee) return false;
        if (!director.specialisations) return false;
        if (!employee.specialisations) return false;

        const employeeDeps: string[] = safeMap(employee.specialisations.map((spec) => spec.role), 'name');
        const directorDeps: string[] = director.specialisations
            .filter((spec) => spec.type === 'director')
            .map((spec) => spec.role.name);

        const result = directorDeps.some((dir) => employeeDeps.some((dep) => dir === dep));

        return result;
    };

    isMe = (employee: Employee) => {
        return employee?.id === this.currUser.person.id;
    };

}
