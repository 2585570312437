import { Component, Input, OnChanges } from '@angular/core';
import { Action } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pm-actions',
    template: `
    <div class="Actions"
        *ngIf="actions?.length">
        <ng-container *ngFor="let action of actions">
            <pro-btn *ngIf="action.isVisible()"
                    (click)="action.callback($event)"
                    [icon]="action.icon"
                    [theme]="action.theme || 'primary'"></pro-btn>
        </ng-container>
    </div>
    `
})

export class ActionsComponent implements OnChanges {
    @Input() actions: Action[];

    constructor() {

    }

    ngOnChanges() {

        if (this.actions) {

            for (let action of this.actions) {

                if (typeof action.isVisible === 'undefined') action.isVisible = () => true;

            }

        }
    }

}
