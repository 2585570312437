import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pm-category-recursive',
    template: `
        <div fxLayout="column"
             *ngIf="(category.isActiveParent || category.isEnabled) || isEditMode">
            <div fxLayout="column">
                <!--<div fxLayout="column"
                     *ngIf="category.__children?.length">
                    {{ category.name }}
                </div>-->
<!--                     *ngIf="!category.__children.length"-->
                <div fxLayout="column"
                >
                    <pro-checkbox fxFlex
                                 [config]="{ label: category.name }"
                                 [value]="category.isEnabled"
                                 (onChange)="onToggle.emit({ type: 'categories', field: category, value: $event })"
                                 [disabled]="!isEditMode"></pro-checkbox>
                </div>
            </div>
            <div *ngIf="category.children?.length"
                 fxLayout="column"
                 class="CategoryRecursive--Children">
                <pm-category-recursive *ngFor="let _category of category.children"
                                       [category]="_category"
                                       [isEditMode]="isEditMode"
                                       (onToggle)="onToggle.emit($event);"></pm-category-recursive>
            </div>
        </div>

    `,
    styles: ['.CategoryRecursive--Children { margin: 8px 0; padding: 0 16px; border-left: 2px dashed #BFBFBF; }']
})

export class CategoryRecursiveComponent {
    @Input() category: any;
    @Input() isEditMode: boolean;
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

    constructor() {

    }

}
