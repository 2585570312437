import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { twoDigit } from '@proman/utils';
import moment from 'moment';

@Component({
    selector: 'pm-workshifts-colors',
    template: `
        <div fxLayout="row wrap">
            <div fxLayout="column"
                 fxFlex.gt-xs>
                <a [routerLink]=" 'WorkShifts' | pmSref">
                    <pro-label>{{ 'workshifts' | translate }}</pro-label>
                </a>
                <pro-color *ngFor="let workshift of workshifts; let $index = index;"
                          [value]="workshift.color"
                          [config]="{ label: workshift.displayName, position: (($index < 2) ? 'bottom' : 'top') }"
                          (onChange)="updateWorkshiftColor(workshift, $event)"></pro-color>
            </div>
            <div fxLayout="column"
                 fxFlex.gt-xs>
                <a [routerLink]=" 'WorkdayExceptionReasons' | pmSref">
                    <pro-label>{{ 'workday_exception_reasons' | translate }}</pro-label>
                </a>
                <pro-color *ngFor="let reason of workdayExceptionReasons"
                          [value]="reason.color"
                          [config]="{ label: reason.name }"
                          (onChange)="updateWorkdayExceptionReasonColor(reason, $event)"></pro-color>
            </div>
            <div fxLayout="column"
                 fxFlex.gt-xs
                 fxFlex.xs="100">
                <pro-label>{{ 'other' | translate }}</pro-label>
                <pro-color [value]="calendarOptions?.changedScheduleColor"
                          [config]="{ label: ('changed_schedule' | translate) } "
                          (onChange)="updateChangedScheduleColor($event)"></pro-color>
            </div>
        </div>
    `,
  styles: [`
    a {
        width: fit-content;
    }
  `]
})

export class WorkshiftsColorsComponent implements OnInit {
    @Output() onColorChange: EventEmitter<any> = new EventEmitter<any>();

    workshifts: any = [];
    workdayExceptionReasons: any = [];
    calendarOptions: any = [];
    calendarEntity: any;
    utcOffset: number;

    constructor(
        private Entity: Entity,
        private Filter: FilterService,
        private ParametersOptions: ParametersOptionsService,
    ) {
        this.calendarEntity = this.Entity.get({ name: 'calendar', get: ['getOptions'], post: ['updateOptions'] });
        this.utcOffset = moment().utcOffset() / 60;
    }

    ngOnInit() {
        this.calendarEntity
            .getOptions()
            .then((response: any) => this.calendarOptions = response);

        this.ParametersOptions
            .search({ entity: 'workshift' })
            .then((response: any) => {

              if (moment().isDST()) {
                response.forEach((shift: any) => {
                  shift.displayName = `${shift.name}
                    ${moment.utc(shift.start, [moment.ISO_8601, 'HH:mm:ss']).utcOffset(this.utcOffset).subtract(1, 'hour').format('HH:mm').toString()} -
                    ${moment.utc(shift.end, [moment.ISO_8601, 'HH:mm:ss']).utcOffset(this.utcOffset).subtract(1, 'hour').format('HH:mm').toString()}`;
                });
              } else {
                response.forEach((shift: any) => {
                  shift.displayName = `${shift.name}
                    ${moment.utc(shift.start, [moment.ISO_8601, 'HH:mm:ss']).utcOffset(this.utcOffset).format('HH:mm').toString()} -
                    ${moment.utc(shift.end, [moment.ISO_8601, 'HH:mm:ss']).utcOffset(this.utcOffset).format('HH:mm').toString()}`;
                });
              }

                this.workshifts = response;

            });

        this.ParametersOptions
            .search({ entity: 'workday_exception_reason' })
            .then((response: any) => this.workdayExceptionReasons = response);

    }

    updateWorkshiftColor = (workshift: any, value: any) => {
        this.Entity.get('workshift')
            .update({ id: workshift.id, color: value })
            .then(() => {
                workshift.color = value;
                this.emitUpdate();
            });
    };

    updateWorkdayExceptionReasonColor = (reason: any, value: any) => {
        this.Entity.get('workday_exception_reason')
            .update({ id: reason.id, color: value })
            .then(() => {
                reason.color = value;
                this.emitUpdate();
            });
    };

    updateChangedScheduleColor = (value: any) => {
        this.calendarEntity
            .updateOptions({ changedScheduleColor: value })
            .then(() => {
                this.calendarOptions.changedScheduleColor = value;
                this.emitUpdate();
            });
    };

    emitUpdate = () => {
        this.onColorChange.emit();
    };

}
