import { Injectable } from '@angular/core';
import { Entity, EntityInterface } from './entity.service';

export interface PermissionEntityInterface extends EntityInterface {
    allAvailablePermissions: () => Promise<any>;
    posAvailablePermissions: () => Promise<any>;
    employeesPermissions: () => Promise<any>;
    rolesPermissions: () => Promise<any>;
    specialisationsPermissions: () => Promise<any>;
    personsPermission: (data: { permission: string }) => Promise<any[]>;
    employeesPermission: (data: { permission: string }) => Promise<any[]>;
}

type PermissionActionType = 'create'|
    'display'|
    'edit'|
    'remove'|
    'confirm'|
    'update_status'|
    'view_all'|
    'show_price'|
    'update_price'|
    'master';

@Injectable()
export class PermissionsService {
    permissionEntity: PermissionEntityInterface;
    permissions: PermissionActionType[] = [
        'create',
        'display',
        'edit',
        'remove',
        'confirm',
        'update_status',
        'view_all',
        'show_price',
        'update_price',
        'master'
    ];

    constructor(
        Entity: Entity,
    ) {
        this.permissionEntity = Entity.get({
            name: 'permission',
            get: [
                'allAvailablePermissions',
                'posAvailablePermissions',
                'employeesPermissions',
                'rolesPermissions',
                'specialisationsPermissions',
                'personsPermission',
            ],
            post: [
                'employeesPermission'
            ]
        }) as PermissionEntityInterface;
    }

    getForEntity = (type: string, id: number) => {
        return this.permissionEntity.customizedRequest('get/' + type + '/' + id);
    };

    get = () => this.permissions;

    getEntity = () => this.permissionEntity;

    getPos = () => this.permissionEntity.posAvailablePermissions();

}
