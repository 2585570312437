import { Component, Input } from '@angular/core';

@Component({
    selector: 'pm-update-data',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="item[key + 'At']">
            <pro-editable-container [label]="key + '_at'" style="margin-right: 24px;">
                {{ item[key + 'At'] | proDateTime }}</pro-editable-container>
            <pro-editable-container *ngIf="item[key + 'By']" [label]="key + '_by'">
                {{ item[key + 'By'].name }}
            </pro-editable-container>
        </div>
    `
})

export class UpdateDataComponent {
    @Input() item: any;
    @Input() key: string = 'updated';

}
