import { Component, Input, OnInit } from '@angular/core';
import { PermissionsService } from '@proman/services/permissions.service';
import { Entity } from '@proman/services/entity.service';
import { isDefined } from '@proman/utils';
import { keys } from 'lodash';
import { ACL } from '@proman/services/acl.service';
import { ToastService } from '@proman/services/toast.service';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { ActivatedRoute } from '@angular/router';
import { getCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';

@Component({
    selector: 'pm-permissions',
    template: `
        <div fxLayout="column" class="Padding">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <pro-checkbox *ngIf="currUser.username === 'master'" [config]="{ label: 'select_all' }" (onChange)="sudoPermissionToggle($event)"></pro-checkbox>
            <pro-btn *ngIf="type === 'employee' && ACL.check('permission.edit')" [tooltip]="'remove_permissions' | translate" theme="warn" icon="ban" (onClick)="removePermissions()"></pro-btn>
          </div>
            <table class="Table stripped" *ngIf="_loaded" [proFixedHeader]="true">
                <thead>
                    <tr class="Table-header">
                        <th>{{ 'view' | translate }}</th>
                        <th *ngFor="let permission of allPermissions">
                            <span>
                                {{ permission | translate }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let resource of resources">
                    <td>
                        <div>
                            <pro-checkbox [config]="{ label: resource.name }"
                                       [value]="resource.allowed"
                                       (onChange)="toggleResource(resource, $event)"></pro-checkbox>
                            <pro-btn *ngIf="resource.override"
                                    (onClick)="clearResourceOverride(resource)"
                                    icon="undo"
                                    [tooltip]="'clear_resource_override' | translate"
                                    size="1x"
                                    theme="warn"></pro-btn>
                        </div>
                    </td>
                    <td *ngFor="let permission of allPermissions">
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                             *ngIf="resource.actions[permission]">
                            <pro-checkbox (onChange)="toggle(resource, permission, $event)"
                                         [value]="resource.actions[permission].status"
                                         [disabled]="!resource.allowed"></pro-checkbox>
                        </div>
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                             *ngIf="!resource.actions[permission]">
                            <div>-</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    `
})

export class PermissionsComponent implements OnInit {
    @Input() permissions: any;
    @Input() entity: any;
    @Input() type: string;

    permissionEntity: any;
    id: number;
    allPermissions: any;
    resources: any;
    currUser: CurrUser;

    _loaded: boolean = false;

    constructor(
        private promanState: PromanStateService,
        private Toast: ToastService,
        public ACL: ACL,
        private Entity: Entity,
        private store: Store,
        private Permissions: PermissionsService,
        private route: ActivatedRoute,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => {
                this.currUser = value;

            });
        this.entity = this.route.snapshot.data['entity'];
        this.type = this.route.snapshot.data['type'];

        this.permissionEntity = Entity.get('permission');
    }

    ngOnInit() {
        if (!this.ACL.check('permission.edit')) {
            this.Toast.pop('error', 'No permission for \'permission.edit\'');
            return this.promanState.go();
        }

        this.allPermissions = this.Permissions.get();

        this.id = this.entity ? this.entity.id : null;

        (this.permissions) ? this.setPermissions(this.permissions) : this.getPermissions();
    };

    setPermissions = (data: any) => {
        let resources: any = [];

        for (let name in data) {
            let actions = data[name];

            let resource = {
                name,
                // if resource does not have 'view' action, it is enabled by default
                allowed: !isDefined(actions.view) ? true : actions.view.status,
                override: isDefined(actions.view) ? actions.view.override : false,
                actions
            };

            delete actions.view;

            resources.push(resource);
        }


        this.resources = resources;

        this._loaded = true;
    };

    getPermissions = () => {
        this.Permissions
            .getForEntity(this.type, this.id)
            .then(this.setPermissions);
    };

    toggleResource = (resource: any, value: any) => {
        resource.allowed = value;
        let request: any = {
            entityId: this.id,
            entityType: this.type,
            allowed: value,
        };

        request.permissions = keys(resource.actions).map(action => resource.name + '.' + action);
        request.permissions.push(resource.name + '.view');

        return this.permissionEntity.update(request).then(() => {

            for (let action in resource.actions) {
                resource.actions[action].status = value;
                resource.override = (this.type === 'employee' || this.type === 'specialisation');
                resource.actions[action].override = (this.type === 'employee' || this.type === 'specialisation');

            }

        });
    };

    toggle = (resource: any, action: string, value: any) => {
        const type = this.type;
        let request = {
            entityId: this.id,
            entityType: type,
            permission: resource.name + '.' + action,
            allowed: value
        };

        return this.permissionEntity.update(request).then(() => {
            resource.override = (type === 'employee' || type === 'specialisation');
            resource.actions[action].status = value;
            resource.actions[action].override = (type === 'employee' || type === 'specialisation');
        });
    };

    clearOverride = (resource: any, action: string) => {
        let request = {
            entityId: this.id,
            entityType: this.type,
            permission: resource.name + '.' + action,
        };

        this.permissionEntity
            .remove(request)
            .then((response: any) => {
                resource.actions[action].override = false;
                resource.actions[action].status = response.data;
            });
    };

    clearResourceOverride = (resource: any) => {
        let request = {
            entityId: this.id,
            entityType: this.type,
            permission: resource.name + '.view',
        };

        return this.permissionEntity
            .remove(request)
            .then((response: any) => {
                resource.override = false;
                resource.allowed = response.data;

                let requests = [];

                for (let action in resource.actions) {
                    requests.push(this.clearOverride(resource, action));
                }

                Promise.all(requests)
                    .then(() => {
                        this.ngOnInit();
                    });

                return Promise.resolve();

            });

    };

    sudoPermissionToggle(value: any) {
        this.resources.forEach((resource: any) => {
            resource.allowed = value;
            let request: any = {
                entityId: this.id,
                entityType: this.type,
                allowed: value,
            };

            request.permissions = keys(resource.actions).map(action => resource.name + '.' + action);
            request.permissions.push(resource.name + '.view');

            return this.permissionEntity.update(request).then(() => {

                for (let action in resource.actions) {
                    resource.actions[action].status = value;
                    resource.override = (this.type === 'employee' || this.type === 'specialisation');
                    resource.actions[action].override = (this.type === 'employee' || this.type === 'specialisation');

                }

            });
        })
    }

  removePermissions() {
    this.Entity.get('employee').removePermissions({ id: this.entity.id }).then(() => this.getPermissions());
  }
}
