import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { MaterialQuant } from '@proman/interfaces/entity-interfaces';
import { FilterService } from '@proman/services/filter.service';

@Component({
    selector: 'pm-material-quant-search-dialog',
    template: `
        <pro-dialog-title title="search"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pro-qr-reader (onCodeRead)="handleSearch($event)"></pro-qr-reader>

            <pm-txt [value]="query"
                    [config]="{ label: 'search' }"
                    (onChange)="handleSearch($event)"
            ></pm-txt>

            <mat-list>
                <mat-list-item *ngFor="let item of quants" class="Clickable" (click)="select(item)">
                    <h4 mat-line>{{ item.name }}</h4>
                </mat-list-item>
                <hr>
            </mat-list>

        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class MaterialQuantSearchDialogComponent {
    query: string = '';
    quants: MaterialQuant[] = [];
    materialId: number;
    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: {
            materialId?: number;
            params: { [key: string]: unknown };
        },
        private Entity: Entity,
        private Filter: FilterService,
        private dialogRef: MatLegacyDialogRef<MaterialQuantSearchDialogComponent>,
    ) {
        this.materialId = data.materialId;
    }

    handleSearch(query: string) {
        if (query) {
            this.Entity.get('material_quant')
                .search(Object.assign({
                    'barcode.value': query,
                    'material.id': this.materialId || [],
                    'join': ['material', 'material.materialFormats', 'quantFormats', 'barcode']
                }, this.data.params || {}))
                .then((response: MaterialQuant[]) => {
                    response.forEach((quant) => quant.name = `${quant.material.name} - ${this.Filter.quantName(quant)} - ${quant.barcode.value}`);

                    this.quants = response;
                });

        } else {
            this.quants = [];

        }

    }

    select(quant: MaterialQuant) {
        this.dialogRef.close(quant);
    }

}
