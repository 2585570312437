import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import * as _Cropper from 'cropperjs';
import { fileFromBase64 } from '@proman/utils';
import { CommonModule } from "@angular/common";
import { PromanButtonComponent } from "@proman/button";
import { Fa6Module } from "@proman/fa/fa6.module";
import { FlexModule } from "ngx-flexible-layout";
import { TranslatePipe } from "@proman/shared/pipes/pipes/translate.pipe";

const Cropper: any = _Cropper;

const CROPPER_CSS_ID = 'cropper-styles';

@Component({
    selector: 'pro-cropper-image-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        PromanButtonComponent,
        Fa6Module,
        FlexModule,
        TranslatePipe,
    ],
    template: `
        <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ 'crop_image' | translate }}</span>
            <div fxLayout="row" fxLayoutAlign="end center" class="ActionsContainer">
                <button class="dialog-close" [mat-dialog-close]="0" tabindex="-1"></button>
            </div>
        </h1>
        <div mat-dialog-content fxLayout="column">
            <div #cropperWorkContainer>
                <input type="file" class="default" multiple (change)="handleChange($event)"
                       [ngClass]="{ 'DisplayNone': !!data.file }"
                >

                <img id="img" height="150">

                <canvas id="originalCanvas" class="DisplayNone"></canvas>
                <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 8px;">
                    <canvas id="previewCanvas" [ngClass]="{ 'DisplayNone': !isPreview }"></canvas>
                </div>
            </div>

        </div>
        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
           <div>
               <pro-btn label="cancel"
                        (onClick)="close()"
               ></pro-btn>
           </div>
            <div>
<!--                <pro-btn label="preview"-->
<!--                         [theme]="isPreview ? '' : 'primary'"-->
<!--                         (onClick)="togglePreview()"-->
<!--                ></pro-btn>-->
                <pro-btn label="confirm"
                         theme="accent"
                         (onClick)="confirm()"
                ></pro-btn>
            </div>
        </div>
    `,
    styles: [
       `
           h1 {
               margin: -24px -24px 40px;
               background-color: #448aff;
               color: #ffffff;
               padding: 12px;
               overflow: auto;
           }


           .dialog-close {
               -moz-appearance: none;
               -webkit-appearance: none;
               background: none;
               border: none;
               border-radius: 290486px;
               cursor: pointer;
               pointer-events: auto;
               display: inline-block;
               flex-grow: 0;
               flex-shrink: 0;
               font-size: 0;
               height: 40px;
               max-height: 40px;
               max-width: 40px;
               min-height: 40px;
               min-width: 40px;
               outline: none;
               position: relative;
               vertical-align: top;
               width: 40px;
           }

           .dialog-close::before, .dialog-close::after {
               background-color: white;
               content: "";
               display: block;
               left: 50%;
               position: absolute;
               top: 50%;
               -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
               transform: translateX(-50%) translateY(-50%) rotate(45deg);
               -webkit-transform-origin: center center;
               transform-origin: center center;
           }

           .dialog-close::before {
               height: 2px;
               width: 75%;
               background-color: white;
           }

           .dialog-close::after {
               height: 75%;
               background-color: white;
               width: 2px;
           }


            img {
              display: block;


              max-width: 100%;
            }


        `
    ]
})

export class CropperDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('cropperWorkContainer', { static: true }) container: ElementRef;
    isPreview = true;
    fileName: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { aspectRatio?: number, file?: File },
        private dialogRef: MatLegacyDialogRef<CropperDialogComponent>,

    ) {
        if (!document.querySelector(`#${CROPPER_CSS_ID}`)) {
            import('./css/cropper.css')
                .then((styles) => {
                    const style = document.createElement('style');
                    style.innerText = styles.cropperCss;
                    document.head.appendChild(style);
                })
        }

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.container.nativeElement.style.height = `${window.innerHeight - 300}px`;

        if (this.data.file) {
            this.handleChange({ target: { files: [this.data.file] } } as unknown as InputEvent)
        }

    }

    handleChange(_event: any) {
        const event = _event as InputEvent;
        const FR = new FileReader();
        const input = <HTMLInputElement>event.target;
        this.fileName = input.files[0].name;

        FR.addEventListener("load", (evt) => {
            const image = (<HTMLImageElement>document.querySelector("#img"));
            const canvas = <HTMLCanvasElement>document.getElementById("originalCanvas");
            const ctx = canvas.getContext("2d");


            image.onload = function() {
                canvas.height = image.naturalHeight;
                canvas.width = image.naturalWidth;
                ctx.drawImage(image, 0, 0);
            };
            image.src = evt.target.result as any;

            this.initCropper()
        });

        FR.readAsDataURL(input.files[0]);

    }

    initCropper() {
        const options: _Cropper.default.Options = {
            crop: (event: any) => {
                if (this.isPreview) {
                    this.cropCanvas(
                        <HTMLCanvasElement>document.getElementById("originalCanvas"),
                        event.detail.x,
                        event.detail.y,
                        event.detail.width,
                        event.detail.height,
                    );
                }
            },
            viewMode: 1,
            aspectRatio: (this.data.aspectRatio || NaN),
            initialAspectRatio: (this.data.aspectRatio || NaN),
        };
        const cropper = new Cropper(document.querySelector('#img'), options);
    }

    cropCanvas = (sourceCanvas: HTMLCanvasElement,left:  number,top: number,width : number,height: number) => {
        const destCanvas: HTMLCanvasElement = document.querySelector('#previewCanvas');
        destCanvas.width = width;
        destCanvas.height = height;
        destCanvas.style.height = `${height}px`;
        destCanvas.style.width = `${width}px`;
        destCanvas.getContext("2d").drawImage(
            sourceCanvas,
            left,top,width,height,  // source rect with content to crop
            0,0,width,height);      // newCanvas, same size as source rect
    }

    togglePreview() {
        this.isPreview = !this.isPreview;

        if (this.isPreview) {
            setTimeout(() => {
                this.container.nativeElement.querySelector('#previewCanvas').scrollIntoView({ behavior: 'smooth' });
            })
        }
    }

    close() {
        this.dialogRef.close(0)
    }

    confirm() {
        const base64Canvas = (<HTMLCanvasElement>document.querySelector('#previewCanvas'))
            .toDataURL("image/jpeg");
        const file = fileFromBase64(base64Canvas, this.fileName);
        this.dialogRef.close(file);
    }
}
