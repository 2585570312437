import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'pm-account-category-type-btn',
    template: `
        <pro-btn [label]="label"
                [theme]="theme"
                (onClick)="handleClick($event)"
                [disabled]="disabled"
                 [delayMultiClick]="true"
        >
        </pro-btn>
        <br *ngIf="config?.newLineAfter">
    `,
    styles: [`


    `]
})

export class AccountCategoryTypeBtnComponent implements OnInit, OnChanges {
    @Input() value: string;
    @Input() disabled: boolean = false;
    @Input() config: { label?: string; newLineAfter?: boolean } = {};
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    label: string;
    theme: 'accent'|'warn'|'primary';

    ngOnInit(): void {
        this.setData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setData();
    }

    setData() {
        this.label = this.value || 'C';
        this.theme = (this.value) === 'C' ? 'warn' : ((this.value) === 'D') ? 'accent' : 'primary';
    }

    handleClick(event: MouseEvent) {
        this.value = this.value === 'D' ? 'C' : 'D';
        this.onChange.emit(this.value);

        this.setData();
    }

}
