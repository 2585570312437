import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { contains } from '@proman/utils';
import { ProductionOperation, PromanFile } from '@proman/interfaces/entity-interfaces';
import { OverlayService } from '@frontend/shared/services/overlay.service';
import { Subject } from 'rxjs';
import { takeUntil } from '@proman/rxjs-common';

@Component({
    selector: 'pm-calendar-overlay-files',
    template: `
        <div class="CalendarOverlayFiles">
            <div *ngFor="let file of files"
                 class="CalendarOverlayFiles-Item">
                <pro-thumbnail [file]="file"></pro-thumbnail>
            </div>
            <div class="CalendarOverlayFiles-Clear"></div>
        </div>

    `
})

export class CalendarOverlayFilesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() event: ProductionOperation;
    files: PromanFile[] = [];
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private Overlay: OverlayService,
    ) {
        this.Overlay.data
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                if (this.event && value?.data) {
                    this.handleFiles();
                }
            });
    }

    ngOnInit() {
        this.handleFiles();
    }

    ngOnChanges() {
        this.handleFiles();
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    handleFiles() {
        const event = this.event;

        if (!(event && event.order && event.order.files)) return;

        if (event.production) {
            this.files = event.order.files.filter((file) => !contains(event.production.hiddenOrderFiles, file.id));
        } else if (event.order) {
            this.files = event.order.files;
        }
    }

}
