import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'pm-change-username-form',
    template: `
        <form #changeUsernameForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="handleSubmit(changeUsernameForm)">
            <pm-txt [value]="username"
                    [config]="nameConfig"
                    (onChange)="onChange('current', $event)"></pm-txt>
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <pro-btn label="{{ 'confirm' | translate }}"
                        [theme]="username ? 'accent' : 'primary'"
                        type="submit"></pro-btn>
            </div>
        </form>
    `
})

export class ChangeUsernameFormComponent implements OnInit {
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

    nameConfig = { label: 'username', required: true, type: 'text' };
    username: string;
    controls: any;
    form: UntypedFormGroup;

    ngOnInit() {
        this.controls = {
            current: new UntypedFormControl('', Validators.required),
        };
        this.form = new UntypedFormGroup(this.controls);
    }

    onChange(property: string, value: string) {
        this.username = value;
    }

    handleSubmit(form: any) {
        this.onSubmit.emit({ new_username: this.username });
    }
}
