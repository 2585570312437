import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { menuEmployee } from '../../settings/menu/employee';
import { menuAgent } from '../../settings/menu/agent';
import { menuCustomer } from '../../settings/menu/customer';
import { menuBookkeepingUser } from '../../settings/menu/bookkeepingUser';

import { cloneDeep, debounce, EMPTY_VALUE, isArray, isDefined } from '@proman/utils';
import { ActivatedRoute } from '@angular/router';
import { Employee, Specialisation, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { Entity } from '@proman/services/entity.service';
import { getSystemOptions, loadSystemOptions } from '@proman/store/system-options';
import { Store } from '@ngrx/store';

const SYSTEM_OPTION_KEYS = {
    employee: 'defaultEmployeeMenuSettings',
    agent: 'defaultAgentMenuSettings',
    customer: 'defaultCustomerMenuSettings',
};

@Component({
    selector: 'pm-user-menu-tree',
    template: `
        <div fxLayout="column" class="Padding-16">
            @if (!!employee) {
                <div fxLayout="row">
                    <pro-checkbox [value]="employee.menuLimitations"
                                  [config]="{ label: 'menu_limitations' }"
                                  (onChange)="updateMenuLimitations($event)"></pro-checkbox>
                </div>
            }

            @for (menuItem of menu; track menuItem) {
                <div class="List"
                     fxFlex>
                    <div class="List-header"
                         fxLayout="row"
                         fxLayoutAlign="start center">

                        @if (!!menuItem.tabs) {
                            <pro-label>
                                {{ menuItem.name | translate }}
                            </pro-label>
                        } @else {
                            <div fxLayout="row">
                                <pro-checkbox [value]="menuItem.class === classEnabled"
                                              [config]="{ label: menuItem.name }"
                                              (onChange)="handleMenuClick(menuItem)"></pro-checkbox>

                                @if (menuItem.isOverride) {
                                    <pro-btn icon="undo"
                                             [tooltip]="'revert_menu' | translate"
                                             theme="warn"
                                             (onClick)="revert(menuItem)"
                                             proClickStopPropagation></pro-btn>
                                }
                            </div>
                        }

                    </div>

                    @for (tab of menuItem.tabs; track tab) {
                        <div class="List-row Clickable"
                             fxLayout="row">
                            <pro-checkbox [value]="tab.class === classEnabled"
                                          [config]="{ label: tab.name }"
                                          (onChange)="handleTabClick(menuItem, tab, $event)"></pro-checkbox>

                            @if (tab.isOverride) {
                                <pro-btn icon="undo"
                                         [tooltip]="'revert_tab' | translate"
                                         theme="warn"
                                         (onClick)="revert(menuItem, tab)"
                                         proClickStopPropagation></pro-btn>
                            }
                        </div>
                    }

                </div>
            }

        </div>

    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UserMenuTreeComponent implements OnInit {
    type: 'employee'|'agent'|'customer'|'bookkeepingUser';
    specialisation: Specialisation;
    agent: Specialisation;
    employee: Employee;
    menu: any;
    data: any;
    startData: any;
    defaultData: any = {};

    currentData: any;
    isEntity: boolean;

    keys: string[];
    systemOptions: SystemOptions;
    debounceLoadSystemOptions: any;

    classEnabled: string = 'ColorGreen';
    classDisabled: string = 'ColorRed';
    inited: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private store: Store,
        private Entity: Entity,
    ) {
        this.type = route.snapshot.data['type'];
        this.specialisation = route.parent.snapshot.data['specialisation'];
        this.agent = route.parent.snapshot.data['agent'];
        this.employee = route.parent.snapshot.data['employee'] || (route.parent.snapshot.data['isAccountSettings'] ? route.parent.snapshot.data['user'] : null);
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
    }

    ngOnInit() {
        this.initData();

        this.setData();
        this.setMenuClasses();
    }

    initData() {
        if (this.inited) return;
        this.isEntity = !!(this.specialisation || this.agent || this.employee);
        this.type = this.route.snapshot.data['type'];
        this.specialisation = this.route.parent.snapshot.data['specialisation'];
        this.agent = this.route.parent.snapshot.data['agent'];
        this.employee = this.route.parent.snapshot.data['employee'] || (this.route.parent.snapshot.data['isAccountSettings'] ? this.route.parent.snapshot.data['user'] : null);

        if (this.specialisation) {
            this.type = 'employee';
            this.currentData = Object.assign(this.specialisation.menuSettings || {});

        } else if (this.agent) {
            this.type = 'agent';
            this.currentData = Object.assign(this.agent.menuSettings || {});

        } else if (this.employee) {
            this.type = 'employee';
            this.currentData = Object.assign(this.employee.menuSettings || {});

        } else {
            this.currentData = {};
        }

        if (this.employee.users && this.employee.users[0] && this.employee.users[0].bookkeeping) {
            this.type = 'bookkeepingUser';
            this.currentData = Object.assign(this.employee.menuSettings || {});
        }


        if (isArray(this.currentData) && isDefined(this.currentData.length) && this.currentData.length === 0) {
            this.currentData = {};
        }

        for (const key in this.currentData) {
            if (this.currentData[key] === EMPTY_VALUE) {
                delete this.currentData[key];
            }
        }

        this.defaultData = Object.assign({}, this.systemOptions[SYSTEM_OPTION_KEYS[this.type]]);

        if (this.employee?.specialisations) {
            this.employee.specialisations.forEach((spec) => {
                Object.assign(this.defaultData, spec.menuSettings);
            });
        }

        for (const key in this.defaultData) {
            this.defaultData[key] = this.defaultData[key] === 'true';
            if (this.isEntity) {
                if (isDefined(this.currentData[key]) && (this.currentData[key] !== EMPTY_VALUE && this.currentData[key] !== undefined)) {
                    this.currentData[key] = this.currentData[key] === 'true';
                }

            }

        }

        this.loadMenu();

        this.debounceLoadSystemOptions = debounce(() => this.store.dispatch(loadSystemOptions()), 3000);
    }

    setData() {
        this.startData = Object.assign({}, cloneDeep(this.defaultData));
        this.data = Object.assign({}, cloneDeep(this.startData), this.currentData);

        this.cd.markForCheck();
    }

    loadMenu() {

        switch (this.type) {
            case 'employee':
                this.menu = menuEmployee;
                break;

            case 'agent':
                this.menu = menuAgent;
                break;

            case 'customer':
                this.menu = menuCustomer;
                break;

            case 'bookkeepingUser':
                this.menu = menuBookkeepingUser;
                break;
        }

    }

    updateView = () => this.cd.markForCheck();

    handleTabClick(menuItem: any, tab: any, value: boolean) {
        const menuName = menuItem.name;
        const tabName = tab.name;
        this.currentData[`${menuName}.${tabName}`] = !this.data[`${menuName}.${tabName}`];
        tab.class = value ? this.classEnabled : this.classDisabled;

        this.save();
    }

    handleMenuClick(menuItem: any) {
        const menuName = menuItem.name;
        this.currentData[menuName] = !this.data[menuName];

        this.save();
    }

    save() {
        this.setData();
        this.setMenuClasses();

        this.saveChanges();
        this.updateView();
    }

    setMenuClasses() {
        const isOverride = (key: string) => this.isEntity && isDefined(this.currentData[key]) && (this.currentData[key] !== EMPTY_VALUE );
        const isTruthy = (key: string) => this.data[key] && (this.data[key] !== EMPTY_VALUE || this.data[key] === true);
        this.keys = [];

        this.menu.forEach((menuItem: any) => {
            const menuName = menuItem.name;

            if (menuItem.tabs) {
                menuItem.tabs.forEach((tab: any) => {
                    const tabName = tab.name;
                    const key = `${menuName}.${tabName}`;
                    const tabClass = isTruthy(key) ? this.classEnabled : this.classDisabled;
                    this.keys.push(key);
                    tab.class = tabClass;
                    tab.isOverride = isOverride(key);

                });
            } else {
                menuItem.class = isTruthy(menuName) ? this.classEnabled : this.classDisabled;
                menuItem.isOverride = isOverride(menuName);
                this.keys.push(menuName);
            }

        });

        this.cd.markForCheck();
    }

    revert(menuItem: any, tab?: any) {
        const menuName = menuItem.name;
        const tabName = tab && tab.name;
        const key = tab ? `${menuName}.${tabName}` : menuName;

        this.currentData[key] = EMPTY_VALUE;

        this.saveChanges();

        delete this.currentData[key];

        this.setData();
        this.setMenuClasses();
        this.ngOnInit();

    }

    saveChanges = () => {
        if (!this.isEntity) {
            const data = {};

            for (const key of this.keys) {
                data[key] = false;
            }

            Object.assign(data, this.data);

            this.Entity.get('system_options')
                .update({ id: 1, [SYSTEM_OPTION_KEYS[this.type]]: data  });

        } else {
            if (this.specialisation) {
                this.Entity.get('specialisation')
                    .update({ id: this.specialisation.id, menuSettings: this.currentData })
                    .then(() => { this.specialisation.menuSettings = this.currentData });

            }

            if (this.agent) {
                this.Entity.get('agent')
                    .update({ id: this.agent.id, menuSettings: this.currentData })
                    .then(() => { this.agent.menuSettings = this.currentData });

            }

            if (this.employee) {
                this.Entity.get('employee')
                    .update({ id: this.employee.id, menuSettings: this.currentData })
                    .then(() => { this.employee.menuSettings = this.currentData });

            }

        }

        this.debounceLoadSystemOptions();

    }

    updateMenuLimitations(menuLimitations: boolean) {
        this.Entity.get('employee')
            .update({ id: this.employee.id, menuLimitations })
            .then(() => this.employee.menuLimitations = menuLimitations).then(() => window.location.reload());
    }

}
