import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Action } from '@proman/interfaces/object-interfaces';
import { TagType } from '@proman/resources/tag';

@Injectable({ providedIn: 'root' })
export class ToolbarService {
    item: any;
    title: string;
    subtitle: string;
    subtitleIcon: any;
    subtitleWarn: boolean;
    actions: Action[];
    actionsEditable: boolean;
    entity: any;
    newsFeedEntityType: any;
    entityName: string;
    tagType: TagType;
    tabs: any;
    parentState: any;
    parentStateFilter: any;
    hideBacklink: boolean;
    hasColor: boolean;
    hasTags: boolean;
    additionalState: boolean;
    backlink: { name: string; state: string };
    timeStampSubject: Subject<number> = new Subject<number>();

    constructor(

    ) {

    }

    reset() {
        this.item = null;
        this.title = null;
        this.subtitle = null;
        this.subtitleIcon = null;
        this.actions = null;
        this.actionsEditable = null;
        this.entity = null;
        this.newsFeedEntityType = null;
        this.entityName = '';
        this.tagType = null;
        this.tabs = null;
        this.parentState = null;
        this.parentStateFilter = null;
        this.additionalState = null;
        this.backlink = null;
        this.hideBacklink = false;
        this.hasColor = false;
        this.hasTags = false;
    }

    update() {
        this.timeStampSubject.next(new Date().getTime());
    }

}
