import { Component, Input } from '@angular/core';

/**
 * `pmProgressBar` component
 *
 * Attributes:
 * color
 * mode
 * value
 * buffer-value
 *
 */
@Component({
    selector: 'pm-progress-bar',
    template: `
        <mat-progress-bar [color]="color"
                          [mode]="mode"
                          [value]="value"
                          [bufferValue]="bufferValue">
        </mat-progress-bar>
    `
})
export class ProgressBarComponent {
    @Input() color: string = 'accent';
    @Input() mode: string = 'indeterminate';
    @Input() value: number = 50;
    @Input() bufferValue: number = 75;
}
