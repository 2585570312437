import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { findById } from '@proman/utils';

@Component({
    selector: 'pm-tree-select',
    template: `
        <div class="Editable"
             fxLayout="row"
             fxLayoutAlign="start center">
            <div class="Editable-Label RightMargin"
                 style="margin-top: 8px;"
            >
                {{ config.label | translate }}</div>
            <pro-select [value]="tmpVal"
                    [config]="{ label: ' ', isNone: true }"
                    [options]="options"
                    (onChange)="onChange.emit($event)"></pro-select>
        </div>
    `
})

export class TreeSelectComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any = {};
    @Input() options: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    tmpVal: any;

    ngOnInit() {
        this.tmpVal = this.getTmpValue(this.value, this.options);

        if (!this.config.disabled) this.config.disabled = () => false;
    }

    ngOnChanges(changes: SimpleChanges) {
        let value = changes.value;
        let options = changes.options;

        if (value && !value.isFirstChange()) {
            this.tmpVal = this.getTmpValue(value.currentValue, null);

        }

        if (options && !options.isFirstChange()) {
            this.tmpVal = this.getTmpValue(null, options.currentValue);

        }

    }

    getTmpValue(value: any, options: any) {
        let tmpOptions = options || this.options;
        let tmpValue = value || this.value;

        if (tmpValue && tmpOptions) {
            return findById(tmpOptions, tmpValue);

        }

    }
}
