import { Component } from '@angular/core';

@Component({
    selector: 'pm-frame',
    template: `
    <div class="Frame" fxLayout="column">
        <ng-content></ng-content>
    </div>
    `,
    styles: [`
        .Frame {
            border-radius: inherit;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            width: 100%;
            position: relative;
        }
    `]
})

export class FrameComponent {

    constructor() {}

}
