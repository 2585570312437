import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pm-iframe-dialog',
    template: `
        <div mat-dialog-content fxLayout="column"
             style="height: 90vh;"
        >
            <iframe [src]="data.url | safeURL"
                    style="height: 100%;"
            ></iframe>
        </div>
    `
})

export class IframeDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: {
            url: string;
        },

        private dialogRef: MatLegacyDialogRef<IframeDialogComponent>
    ) {

    }

}
