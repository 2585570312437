import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { FilterService } from '@proman/services/filter.service';

@Component({
    selector: 'pm-canvas-sign-dialog',
    template: `
        <pro-dialog-title title="please_sign"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <div fxLayout="row" fxFlex>
                <pro-select [value]="position"
                        [config]="{ label: 'position', key: 'id' }"
                        [options]="positionOptions"
                        (onChange)="setPosition($event)"></pro-select>
            </div>
            <pm-canvas-sign (onSave)="handleCanvasSave($event)"></pm-canvas-sign>
        </div>
    `
})

export class CanvasSignDialogComponent {
    position: string = 'bottom-right';
    positionOptions: SelectOption[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private store: Store,
        public dialogRef: MatLegacyDialogRef<CanvasSignDialogComponent>,
        private Filter: FilterService,
    ) {
        this.positionOptions = [
            { id: 'top-left', name: this.Filter.translate('top_left') },
            { id: 'top-right', name: this.Filter.translate('top_right') },
            { id: 'bottom-left', name: this.Filter.translate('bottom_left') },
            { id: 'bottom-right', name: this.Filter.translate('bottom_right') },
        ];
    }

    handleCanvasSave(data: string) {
        this.dialogRef.close({
            position: this.position,
            data: data
        })
    }

    setPosition(position: string) {
        this.position = position;
    }

}
