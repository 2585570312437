import {
    Component,
    Input,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Output,
    EventEmitter
} from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { isDefined, roundNumber } from '@proman/utils';
import { ActivatedRoute } from '@angular/router';
import { EntityLogObject, TableConfig } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pm-entity-activity-log',
    template: `
        <table class="Table stripped" proFixedHeader>
            <thead>
            <tr>
                <th>{{ 'user' | translate }}</th>
                <th>{{ 'date' | translate }}</th>
                <th>{{ 'changes' | translate }}</th>

            </tr>
            </thead>
            <tbody>
            @for (row of data?.history; track $index) {
                <tr>
                    <td>{{ row.person.firstName }} {{ row.person.lastName }}</td>
                    <td>{{ row.datetime | proDateTime }}</td>
                    <td>
                        <div class="DiffContainer"
                             [pmOverlay]="{ type: 'log_diff', data: row.diffData}"
                             [innerHTML]="row.diffData.toString()">
                        </div>
                    </td>
                    @if (entityName === 'template') {
                        <pro-btn [icon]="'disc-drive'" [theme]="'accent'"
                                 [tooltip]="'load_from_history' | translate" (onClick)="loadFromHistory(row)"></pro-btn>
                    }
                </tr>
            }
            </tbody>
        </table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        /*.DiffContainer {*/
            /*text-overflow: ellipsis;*/
            /*overflow: hidden;*/
            /*word-break: break-all;*/
            /*max-height: 38px;*/
        /*}*/
    `]
})

export class EntityActivityLogComponent implements OnInit {
    @Input() entityName: EntityNameType;
    @Input() entityId: number;
    @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();

    tableConfig: TableConfig;

    data: EntityLogObject;

    constructor(
        private cd: ChangeDetectorRef,
        private Entity: Entity,
        private Filter: FilterService,
        private route: ActivatedRoute,
    ) {
        this.entityName = this.route.snapshot.data['entityName'];
        this.entityId = this.route.snapshot.data['entityId'];
    }

    ngOnInit() {

        this.Entity
            .get(this.entityName)
            .log({ entityId: this.entityId })
            .then((response) => {
                this.prepareData(response);
                this.cd.detectChanges();
            });

    }

    prepareData(response: EntityLogObject) {
        response.history.forEach((item: any) => {

            const setDiffData = (item: any) => {
                item.diffData = '';

                if (item.diff && Object.keys(item.diff).length) {
                    for (const key in item.diff) {
                        const value = item.diff[key];
                        let customValue = null;

                        if (value && value.name) customValue = value.name;
                        if (value && isDefined(value.amount) && value.currency) customValue = this.Filter.price(value);
                        if (value && typeof value === 'string' && value.endsWith('Z')) customValue = this.Filter.dateTime(value);
                        if (value && typeof value === 'number') customValue = roundNumber(value);
                        if (value && typeof value === 'object') customValue = JSON.stringify(value).slice(1, -1);

                        item.diffData += `${this.Filter.translate(key)}: ${customValue ?? value}<br/>`;
                    }
                }
            };

            setDiffData(item);
        });

        response.history = response.history.filter((item: any) => !item.diff.metadata);

        this.data = response;
    }

    loadFromHistory = (value: any) => {
        this.onLoad.emit(value.diff);
    }
}
