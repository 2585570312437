import { Component, SimpleChanges, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { ProductStorageInfoDialogComponent } from '../../events/components/product-storage-info-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { TableButton, TableConfig } from '@proman/interfaces/object-interfaces';
import { ProductionContainerCreateDialogComponent } from '../../events/components/production-container-create-dialog.component';
import { ProductionOperation } from '@proman/interfaces/entity-interfaces';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-products-table',
    template: `
        <ng-container *ngIf="tableConfig && tablePayload">
            <pro-table [config]="tableConfig" [dataSource]="tablePayload" [loadWhen]="tablePayload" [timeStamp]="tableTimeStamp"></pro-table>
        </ng-container>
    `
})

export class ProductsTableComponent implements OnInit, OnChanges {
    @Input() products: any;
    @Input() state: any;
    @Input() topButtons: any;
    @Input() showPackagedQuantity: boolean;
    @Input() showStorageInfo: boolean;
    @Input() showRequiredQuantity: boolean;
    @Input() showParameters: boolean;
    @Input() editPackagedQuantity: boolean;
    @Input() showStoredQuantity: boolean;
    @Input() editRequiredQuantity: boolean;
    @Input() removeProducts: boolean;
    @Input() header: any = null;
    @Input() tableTimeStamp: number;
    @Input() event: ProductionOperation;
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditPackagedQuantity: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditRequiredPackaging: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditRequiredQuantity: EventEmitter<any> = new EventEmitter<any>();

    isProductionProduct: boolean = false;
    tableConfig: TableConfig;
    tablePayload: any = { data: [], total: 0 };

    constructor(
        private ACL: ACL,
        private Dialog: Dialog,
    ) {

    }

    ngOnInit() {
        this.tableConfig = {
            hideSettings: true,
            multiselect: true,
            header: this.header,
            headerState: {
                name: 'Products'
            },
            aclRoot: 'product',
            preventFixedHeader: true,
            transform: (data: any) => {
                let name;
                let parameters;
                let storedQuantity;
                let requiredQuantity;
                let packagedQuantity;
                let packagingQuantity;

                for (let product of data) {

                    if (product.parameters) {
                        // product
                        parameters = product.parameters;
                        name = product.name;
                        requiredQuantity = product.quantity;
                        storedQuantity = product.storedQuantity;

                    }

                    if (product.product) {
                        // order_product
                        parameters = product.product.parameters;
                        name = product.product.name;
                        requiredQuantity = product.quantity;
                        storedQuantity = product.product.storedQuantity;
                        packagedQuantity = product.packagedQuantity;

                    } else if (product.orderProduct) {
                        // production_product

                        parameters = product.orderProduct.product.parameters;
                        name = product.orderProduct.product.name;
                        requiredQuantity = product.productionQuantity;
                        storedQuantity = product.orderProduct.product.storedQuantity;
                        packagedQuantity = product.packagedQuantity;
                        packagingQuantity = product.orderProduct.packagingQuantity;

                        if (product.orderProduct.parentOrderProduct) name = '    ' + name;

                        this.isProductionProduct = true;

                    } else {
                        throw Error('Unknown product subclass');

                    }

                    product._name = name;
                    product.name = name;
                    product.parameters = parameters;
                    product._requiredQuantity = requiredQuantity;
                    product._storedQuantity = storedQuantity;
                    product._packagedQuantity = packagedQuantity;
                    product._packagingQuantity = packagingQuantity;
                }

                this.updateButtons();

                return data;
            }
        };

        this.updateButtons();
        this.initFields();
    };

    ngOnChanges(changes: SimpleChanges) {
        let products = changes.products;
        let state = changes.state;
        let topButtons = changes.topButtons;
        let tableTimeStamp = changes.tableTimeStamp;

        if (products && Array.isArray(products.currentValue) && products.currentValue !== products.previousValue) {
            this.handleProducts(products.currentValue);

        }

        if (state && (state.currentValue !== state.previousValue)) {
            if (!this.tableConfig) return;

            this.updateButtons();
            this.initFields();
            this.refresh();

        }

        if (topButtons && topButtons.currentValue !== topButtons.previousValue) {
            this.tableConfig.topButtons = topButtons.currentValue;

        }

        if (tableTimeStamp && tableTimeStamp.currentValue) this.refresh();

    };

    refresh = () => this.tableTimeStamp = new Date().getTime();

    onRemoveCallback = (product: any) => {
        this.onRemove.emit(product);
    };

    onEditRequiredQuantityCallback = (product: any, requiredQuantity: number|string) => {
        this.onEditRequiredQuantity.emit({ product, requiredQuantity });
    };

    onEditRequiredPackagingCallback = (product: any, requiredPackaging: number|string) => {
        this.onEditRequiredPackaging.emit({ product, requiredPackaging });
    };

    onEditPackagedQuantityCallback = (product: any, packagedQuantity: number|string) => {
        this.onEditPackagedQuantity.emit({ product, packagedQuantity });
    };

    showStorageInfoCallback = (product: any) => {

        if (product.product) {
            product = product.product;

        } else if (product.orderProduct) {
            product = product.orderProduct.product;

        }

        this.Dialog.open2(ProductStorageInfoDialogComponent, { product });
    };

    updateButtons = () => {
        let removeAction: TableButton;

        this.tableConfig.rowButtons = [];

        if (this.showStorageInfo) {
            this.tableConfig.rowButtons.push({
                icon: 'question-circle',
                tooltip: 'show_orders',
                callback: this.showStorageInfoCallback,
            });

        }

        if (this.state) {

            if (this.onRemove.observers.length) {
                removeAction = {
                    icon: 'trash',
                    tooltip: 'delete',
                    label: 'remove',
                    action: 'remove',
                    theme: 'warn',
                    callback: this.onRemoveCallback
                };

                this.tableConfig.rowButtons.push(removeAction);

                this.tableConfig.actionButtons = [removeAction];

            }

        }

        if (this.isProductionProduct && this.event) {
            this.tableConfig.rowButtons.push({
                icon: 'barcode',
                tooltip: 'barcode',
                acl: null,
                callback: (productionProduct: any) => {
                    this.Dialog.open(ProductionContainerCreateDialogComponent, {productionProduct, event: this.event})
                        .then(this.refresh)
                }
            });
        }

        this.tableConfig = { ...this.tableConfig };
    };

    initFields = () => {
        this.tableConfig.fields = [];

        this.tableConfig.fields.push({
            name: 'product',
            key: 'orderProduct.product.name',
            sortable: false,
            filter: null,
            state: this.ACL.check('product.display') ? {
                name: 'Product',
                key: 'productId',
                id: 'orderProduct.product.id'
            } : null
        });

        if (this.showParameters) {
            this.tableConfig.fields.push({
                name: 'parameters',
                key: 'parameters',
                filter: null,
                sortable: false,
                formatter: 'parameters'
            });

        }

        if (this.showRequiredQuantity) {
            this.tableConfig.fields.push({
                name: 'required_quantity',
                key: 'requiredQuantity',
                filter: null,
                sortable: false,
                stats: ['sum'],
            });

        } else if (this.editRequiredQuantity) {
            this.tableConfig.fields.push({
                name: 'required_quantity',
                key: 'requiredQuantity',
                filter: null,
                sortable: false,
                formatter: 'input',
                callback: this.onEditRequiredQuantityCallback,
                stats: ['sum'],
            },
            {
                name: 'required_packaging',
                key: 'packagingQuantity',
                filter: null,
                sortable: false,
                formatter: 'input',
                callback: this.onEditRequiredPackagingCallback,
                stats: ['sum'],
            });

        }

        if (this.showPackagedQuantity) {
            this.tableConfig.fields.push({
                name: 'packaged_quantity',
                key: 'packagedQuantity',
                filter: null,
                sortable: false,
                stats: ['sum'],
            });

        } else if (this.editPackagedQuantity) {
            this.tableConfig.fields.push({
                name: 'packaged_quantity',
                key: 'packagedQuantity',
                filter: null,
                sortable: false,
                formatter: 'input',
                callback: this.onEditPackagedQuantityCallback,
                stats: ['sum'],
            });

        }

        if (this.showStoredQuantity) {
            this.tableConfig.fields.push({
                name: 'stored_quantity',
                key: 'storedQuantity',
                filter: null,
                sortable: false,
                stats: ['sum'],
            });

        }

    };

    handleProducts = (products: any) => {
        this.tablePayload = { data: products, total: products.length };
        this.refresh();
    };

}
