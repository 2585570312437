import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CorporateNamespace, PublicSystemOptions } from '@proman/interfaces/entity-interfaces';
import { getNamespaces, getPublicSystemOptions } from '@proman/store/system-options';
import { Store } from '@ngrx/store';
import { CorporateNamespaceEntityInterface } from '@proman/resources/corporate_namespace';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { ACL } from '@proman/services/acl.service';
import { findById, findByProperty, isDefinedNotNull } from '@proman/utils';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pm-corporate-panel',
  template: `
    @if (systemOptions.corporate && ACL.check('permission.edit')) {
      <div fxLayout="column" fxFlex fxLayoutAlign="start start">
        <pro-label>{{ 'name_spaces' | translate }}</pro-label>
        @if (config.single) {
          @if (children) {
            <div>
              <pm-radio-group [value]="entity" [options]="this.children" [config]="{ displayKey: 'name'}" [disabled]="disabled"
                              (onChange)="setSingle($event)"></pm-radio-group>
            </div>
          }
        } @else {
          <pro-checkbox (onChange)="selectAllNamespace(entity, $event)"
                        [disabled]="disabled"
                        [config]="{ label: 'select_all' }"></pro-checkbox>
          @for (namespace of children; track $index) {
            <div>
              @if (namespace.name !== systemOptions.namespace) {
                <pro-checkbox [disabled]="disabled"
                              [config]="{ label: namespace.name }"
                              [value]="getValue(namespace)"
                              (onChange)="toggleConnect(namespace, entity, $event)"></pro-checkbox>
              }
            </div>
          }
        }
      </div>
    }
  `
})

export class CorporatePanelComponent implements OnInit {
  @Input() entity: any;
  @Input() disabled: boolean = false;
  @Input() class: EntityNameType;
  @Input() config: {
    single?: boolean;
    singleKey?: string;
    selectedChildrenArray?: any,
    class?: EntityNameType,
    entity?: any,
  };
  @Output() onSingleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  systemOptions: PublicSystemOptions;
  children: CorporateNamespace[];
  entityEntity: any;
  namespaceEntity: CorporateNamespaceEntityInterface;

  constructor(
    private Entity: Entity,
    private store: Store,
    public ACL: ACL
  ) {
  }

  ngOnInit() {
    this.store.select(getPublicSystemOptions).subscribe((value) => this.systemOptions = value);

    this.store.select(getNamespaces).pipe(filter((val) => !!val)).subscribe((value) => {
      this.children = value;
      if (this.config?.single) {
        this.entity = findByProperty(this.children, 'name', this.entity);
      }
    });

    this.namespaceEntity = this.Entity.get('corporate_namespace');

    // For corporate parameter component start
    if (this.config.class) this.class = this.config.class;
    if (this.config.entity) this.entity = this.config.entity;
    // For corporate parameter component end

    if (this.config.selectedChildrenArray) {
      this.children = this.config.selectedChildrenArray;
      this.setInitial();
    }
    this.entityEntity = this.class ? this.Entity.get(this.class) : null;
    if (!this.config.single) {
      this.loadEntity();
    } else {
      if (!this.config.singleKey) this.entity = findByProperty(this.children, 'name', this.entity);
    }
  }

  loadEntity() {
    if (isDefinedNotNull(this.entity.id)) this.entityEntity.get({ id: this.entity.id, join: ['namespaces'] }).then((value: any) => this.entity = value);
  }

  toggleConnect = (nameSpace: CorporateNamespace, entity: any, value: boolean) => {
    if (this.onChange.observers.length > 0) {
      if (value) this.onChange.emit([nameSpace]);
      if (!value) this.onChange.emit(nameSpace);
    } else {
      if (value) this.namespaceEntity.addAssociation({ id: nameSpace.id, [this.class]: entity.id });
      if (!value) this.namespaceEntity.removeAssociation({ id: nameSpace.id, [this.class]: entity.id });
    }
  };

  getValue = (namespace: CorporateNamespace) => {
    if (!isDefinedNotNull(this.entity)) return false;
    return this.entity.namespaces?.some((child: CorporateNamespace) => child.name === namespace.name);
  };

  setSingle = (value: CorporateNamespace) => {
    this.entity = value;
    if (this.config.singleKey) {
      this.onSingleChange.emit(value[this.config.singleKey]);
    } else {
      this.onSingleChange.emit(value);
    }
  };

  setInitial() {
    this.entity = findById(this.children, this.entity);
  }

  selectAllNamespace(entity: any, value: boolean) {
    this.children.forEach((namespace: CorporateNamespace) => this.toggleConnect(namespace, entity, value));
    setTimeout(() => {
      this.loadEntity();
    }, 1000);
  }
}
