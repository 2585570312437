import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';
import { CommonModule } from "@angular/common";
import { DialogTitleComponent } from "@proman/common-components/standalone/dialog-title.component";
import { DialogActionsComponent } from "@proman/common-components/standalone/dialog-actions.component";
import { PromanButtonComponent } from "@proman/button";
import { PromanTextSimpleComponent } from "@proman/text-simple";
import { PromanDateTimeModule } from "@proman/datepicker/proman-date-time.module";
import { PromanFile } from "@proman/interfaces/entity-interfaces";
import { FlexLayoutModule } from "ngx-flexible-layout";

@Component({
    selector: 'pro-files-manager-restrictions-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DialogTitleComponent,
        DialogActionsComponent,
        PromanButtonComponent,
        PromanTextSimpleComponent,
        PromanDateTimeModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
    ],
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="confirm(createForm)">
            <pro-dialog-title [title]="'restrictions'"></pro-dialog-title>
            <div mat-dialog-content fxLayout="column">
                <pro-text-simple [value]="file.accessibleTimes"
                                 [config]="{ label: 'times', required: false }"
                                 (onChange)="file.accessibleTimes = $event"></pro-text-simple>
                <pro-datepicker [value]="file.accessibleUntil"
                                [config]="{ label: 'date' }"
                                (onChange)="file.accessibleUntil = $event"></pro-datepicker>
            </div>
            <pro-dialog-actions [variant]="'confirm'" [isCallbackDisabled]="!form.valid">
            </pro-dialog-actions>
        </form>
    `
})

export class PromanFilesManagerRestrictionsDialogComponent {
    parameters: EntityItemFieldConfig[];
    form: UntypedFormGroup;

    file: PromanFile;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: PromanFile,
        public dialogRef: MatLegacyDialogRef<PromanFilesManagerRestrictionsDialogComponent>
    ) {
        this.file = { ...data };
    }

    confirm(createForm: any) {
        this.dialogRef.close({ times: this.file.accessibleTimes,  })
    }

}
