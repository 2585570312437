import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { findById, findByProperty } from '@proman/utils';

@Component({
    selector: 'pm-radio-group',
    template: `
        <pro-label *ngIf="config.label">{{ config.label | translate }}</pro-label>
        <mat-radio-group class="RadioGroup Padding" [(ngModel)]="tmpVal">
            <mat-radio-button *ngFor="let option of options"
                              [value]="option"
                              (click)="handleClick(option)"
                              [disabled]="disabled || isDisabled(option)"
                              class="BottomMargin">
                {{ option[config.displayKey] || option | translate }}</mat-radio-button>
        </mat-radio-group>
    `,
    styles: ['mat-radio-group {  display: inline-flex; flex-direction: column; }']
})

export class RadioGroupComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() options: any;
    @Input() config: any = {};
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    tmpVal: any;

    constructor() {}

    ngOnInit() {
      this.tmpVal = this.config.displayKey ? findById(this.options, this.value) : this.value;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.value) {
            this.tmpVal = this.config.displayKey ? findByProperty(this.options, 'id' , this.value) : changes.value.currentValue;

        }

    }

    handleClick(item: any) {

        if (!this.isDisabled(item)) {
            this.tmpVal = item;
            this.onChange.emit(item);

        }

    }

    isDisabled(item: any) {

        if (typeof this.config.disabledFilter === 'undefined') {
            return false;

        } else {
            return this.config.disabledFilter(item);

        }

    }

}
