import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { isDefinedNotNull, prepareRequest } from "@proman/utils";

@Component({
    selector: 'pm-radio-input-dialog',
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="create(createForm)">
            <pro-dialog-title [title]="data.header || ''"></pro-dialog-title>
            <div mat-dialog-content fxLayout="column">
                <pm-radio-group [value]="inputData['select']"
                                [options]="data.selections"
                                [config]="{ label: data.label, displayKey: 'name' }"
                                (onChange)="set('select', $event)"></pm-radio-group>
            </div>
            <pro-dialog-actions [variant]="data.variant || 'confirm'">
                <ng-container *ngIf="data.buttons">
                    <pro-btn *ngFor="let button of data.buttons"
                            [label]="button.label"
                            [theme]="button.theme"
                            (onClick)="getCallback(button)">

                    </pro-btn>
                </ng-container>
            </pro-dialog-actions>
        </form>
    `
})

export class RadioInputDialogComponent {
    parameters: any;
    form: UntypedFormGroup;
    controls: any = {};
    inputData: any = {};

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<RadioInputDialogComponent>
    ) {

        let validators: any = ['', Validators.required];

        let mainField: any = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: {
                required: true,
                control: new UntypedFormControl(validators),
            }
        };

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) {
            this.parameters = [].concat(this.parameters, this.data.parameters);

        }

        for (let parameter of this.parameters) {

            if (this.data.item) parameter.value = this.data.item[parameter.key];

            if (parameter.config.control) {
                this.controls[parameter.key] = parameter.config.control;

            }

            if (parameter.config.required && !this.controls[parameter.key]) {
                let control = new UntypedFormControl(parameter.value || '', Validators.required);

                this.controls[parameter.key] = control;
                parameter.config.control = control;

            }

            if (parameter.value) {
                this.set(parameter.key, parameter.value);

            }

            if (parameter.type === 'string') {
                parameter.config = Object.assign({ debounce: 0 }, parameter.config);

            }

        }

        this.form = new UntypedFormGroup(this.controls);
        if (isDefinedNotNull(this.data.defaultValue)) {
            this.set('select', this.data.selections[0]);
        }
    }

    set(property: string, value: any) {
        this.inputData[property] = value;
    }

    create(createForm: any) {
        if (createForm.valid) {
            let data = prepareRequest(this.inputData);
            this.dialogRef.close(data);
        }

    }

    getCallback(button: any) {

        if (this.form.valid) {
            let data = prepareRequest(this.inputData);
            this.dialogRef.close();
            button.callback(data);
        }

    }

}
