import { distinctUntilChanged, takeUntil, tap } from '@proman/rxjs-common';
import { Component, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, OnDestroy,  OnChanges,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { SALE_OPPORTUNITY_OVERLAY } from '../overlays/sale_opportunity.overlay';
import { SALE_EVENT_OVERLAY } from '../overlays/sale_event.overlay';
import { EVENT_OVERLAY } from '../overlays/event.overlay';
import { ACL } from '@proman/services/acl.service';
import { ProductionOperationService } from '../../products/services/production-operation.service';

@Component({
    selector: 'pm-overlay',
    template: `
        <div #el class="Overlay2" *ngIf="(data$ | async) as data" [ngClass]="{ NoMinWidth: (data?.type === 'button' || data?.type === 'tabel_month') }">
            <pm-frame fxLayout="column" *ngIf="data && data.data">
                <div class="Padding" [ngSwitch]="data.type">
                    <div *ngSwitchCase="'image'" class="Overlay-img">
                        <img [src]="data.previewSrc ? data.previewSrc : data.data" alt="image">
                    </div>
                    <div *ngSwitchCase="'saleOpportunity'" class="Overlay-saleOpportunity">
                        ` + SALE_OPPORTUNITY_OVERLAY + `
                    </div>
                    <div *ngSwitchCase="'saleEvent'" class="Overlay-saleOpportunity">
                        ` + SALE_EVENT_OVERLAY + `
                    </div>
                    <div *ngSwitchCase="'boardItem'" class="Overlay-saleOpportunity">
                        <div [innerHTML]="data.data | safeHTML"></div>
                    </div>
                    <div *ngSwitchCase="'button'">
                        {{ data.data | translate }}
                         <ng-container *ngIf="(data.status === true) || (data.status === false)">
                            : {{ (data.status ? 'on' : 'off') | translate }}
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="'tabel_month'">
                        <ng-container [ngSwitch]="!!data.data.isChangedTime">
                            <ng-container *ngSwitchCase="true">
                                <span>{{ 'changed_time' | translate }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <span *ngIf="data.data.workshift">
                                    <b>{{ 'workshift' | translate }}: {{ data.data.workshift.name }}</b>
                                </span>

                                <span *ngIf="data.data.reason">
                                    <b>{{ 'reason' | translate }}: {{ data.data.reason.name }}</b>
                                </span>
                            </ng-container>
                        </ng-container>

                        <span *ngIf="data.data.text">
                            <b>{{ data.data.text }}</b>
                        </span>

                    </div>

                    <div *ngSwitchCase="'log_diff'" class="DiffOverlay">
                        <span *ngIf="data.data" [innerHTML]="data.data"></span>
                    </div>

                    <div *ngSwitchCase="'text'">
                        <span *ngIf="data.data">
                            {{ data.data }}
                        </span>
                    </div>

                    <div *ngSwitchCase="'calendar-linked-event'">
                        <div>
                            <b>{{ 'operation' | translate }}: </b> {{ data.data.fullName }}
                            <br>
                        </div>
                        <div *ngIf="!data.data.realStart">
                            <b>{{ 'planned_start' | translate }}: </b> {{ data.data.plannedStart | proDateTime }}
                            <br>
                        </div>
                        <div *ngIf="!!data.data.realStart">
                            <b>{{ 'started' | translate }}: </b> {{ data.data.realStart | proDateTime }}
                            <br>
                        </div>
                        <div *ngIf="!data.data.realEnd">
                            <b>{{ 'planned_end' | translate }}: </b> {{ data.data.plannedEnd | proDateTime }}
                            <br>
                        </div>
                        <div *ngIf="!!data.data.realEnd">
                            <b>{{ 'ended' | translate }}: </b> {{ data.data.realEnd | proDateTime }}
                        </div>
                    </div>

                    <div *ngSwitchCase="'eventLogMetadata'">
                        <div>

                           <div *ngFor="let item of data.data.metadata | proKeys">
                                <b>{{ item.key }}</b>: {{ item.value }}
                            </div>
                        </div>
                    </div>

                    <div *ngSwitchCase="'calendar-event'" class="EventOverlay">
                         <div *ngIf="data.data">
                            ` + EVENT_OVERLAY  + `
                         </div>
                    </div>


                    <div *ngSwitchCase="'errors'">
                         <div *ngIf="data.data">
                            <div>
                                <div *ngFor="let item of data.data.validatorErrors | proKeys">
                                    {{ item.key | translate }}: {{ item.value }}
                                </div>
                        </div>
                         </div>
                    </div>

                </div>
            </pm-frame>
        </div>
    `,
    styles: [
        `
            .DiffOverlay, .EventOverlay {
                word-break: break-all;
            }

            .DiffOverlay {
                max-width: 400px;
            }

            .EventOverlay {
                max-width: 900px;
            }

            .Overlay-saleOpportunity {
                overflow: hidden;
            }

        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverlayComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('el') el: ElementRef;

    type: string;
    data: any = {};
    data$: Observable<{ type: string; data: any }>;
    _subShow: Subscription;
    destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private Overlay: OverlayService,
        private Render2: Renderer2,
        private cd: ChangeDetectorRef,
        // acl used in event overlay
        private ACL: ACL,
        // production operations used in event overlay
        private ProductionOperations: ProductionOperationService,
    ) {

    }

    ngOnInit() {
        this.Overlay.overlayShow
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged()
            )
            .subscribe((value: any) => value ? this.show() : this.hide());

        this.data$ = this.Overlay.data
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
            );
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    async show() {
        this.Render2.removeClass(this.el.nativeElement, 'DisplayNone');

        await this.setPosition();

        this.cd.markForCheck();
    }

    setPosition() {
        setTimeout(() => { // timeout to load image before repositioning
            this.Render2.setStyle(this.el.nativeElement, 'left', this.Overlay.getPos(this.el, 0));
            this.Render2.setStyle(this.el.nativeElement, 'top', this.Overlay.getPos(this.el, 1));

            this.cd.markForCheck();
        });
    }

    hide() {
        this.Render2.addClass(this.el.nativeElement, 'DisplayNone');

        this.Render2.setStyle(this.el.nativeElement, 'top', '-999999px');
        this.Render2.setStyle(this.el.nativeElement, 'left', '-999999px');

        this.cd.markForCheck();
    }

}
